import axios from "axios"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { errorMessage } from "../../errors"
import { useDataModelContext } from "../../hooks/DataModelContext"
import { useUserContext } from "../../hooks/UserContext"
import { API_URL, serverError } from "../../util"
import EmptyList from "../../util/EmptyList"
import LoadingSpinner from "../../util/LoadingSpinner"
import SuccessRateTag from "./SuccessRateTag"

const RegionList = () => {
  const { t } = useTranslation()
  const { regions, loading, refetch } = useDataModelContext()
  const {
    user: { userType },
  } = useUserContext()
  const [successRate, setSuccessRate] = useState(0.85)

  const reinitRegions = async () => {
    try {
      const resp = await axios.post(`${API_URL}/thesaurus/region/init`, {
        successRate: Number(successRate),
      })
      const { status, message } = resp.data

      if (status === "failed") return toast.error(errorMessage(message))

      toast.success(t("thesaurus.regionReinitSuccess"))
      refetch()
    } catch (error) {
      toast.error(serverError())
    }
  }

  if (loading) return <LoadingSpinner />

  return (
    <>
      {userType === "admin" ? (
        <div className="box has-text-centered">
          <div className="columns">
            <div className="column is-3">
              <p className="title is-6 pt-3">
                {t("thesaurus.basicSuccessRate")}
              </p>
            </div>

            <div className="column is-4">
              <div className="field">
                <p className="control">
                  <input
                    type="text"
                    className="input"
                    value={successRate}
                    onChange={e => setSuccessRate(e.target.value)}
                  />
                </p>
              </div>
            </div>

            <div className="column is-5">
              <div className="field">
                <p className="control">
                  <button
                    className="button is-link is-fullwidth"
                    onClick={reinitRegions}
                  >
                    {t("thesaurus.reinit")}
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {regions.length !== 0 ? (
        <div className="box table-container">
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th>{t("thesaurus.regionName")}</th>
                <th>{t("thesaurus.regionDistrict")}</th>
                <th>{t("thesaurus.successRate")}</th>
              </tr>
            </thead>

            <tbody>
              {regions.map(reg => (
                <tr key={reg.id}>
                  <td>{reg.title}</td>
                  <td>{reg.federalDistrict}</td>
                  <td>
                    <SuccessRateTag
                      entryID={reg.id}
                      entryType="region"
                      currentVal={reg.successRate}
                      forTable={true}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <EmptyList />
      )}
    </>
  )
}

export default RegionList
