import { useTranslation } from "react-i18next"

const PracticeStatus = ({ status }) => {
  const { t } = useTranslation()

  let style = ""
  switch (status) {
    case "awaiting":
      style = "has-text-warning"
      break
    case "active":
      style = "has-text-success"
      break
    case "declined":
      style = "has-text-danger"
      break
    default:
      style = "has-text-link"
  }

  return <span className={style}>{t(`practice.practiceStatus.${status}`)}</span>
}

export default PracticeStatus
