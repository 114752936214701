import { createContext, useContext } from "react"
import useListData from "./useListData"

export const DataModelContext = createContext()

const DataModelContextProvider = ({
  dataModelName,
  url,
  query = {},
  children,
}) => {
  const modelNamePlural =
    dataModelName === "speciality" ? "specialities" : `${dataModelName}s`
  const {
    fullData,
    refetch,
    loading,
    currentPage,
    setCurrentPage,
    pageCount,
    ...rest
  } = useListData(modelNamePlural, url, query)

  const value = {
    [modelNamePlural]: rest[modelNamePlural],
    refetch,
    loading,
    currentPage,
    setCurrentPage,
    pageCount,
    fullData,
  }

  return (
    <DataModelContext.Provider value={value}>
      {children}
    </DataModelContext.Provider>
  )
}

export const useDataModelContext = () => {
  const context = useContext(DataModelContext)

  return context
}

export default DataModelContextProvider
