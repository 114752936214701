import axios from "axios"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { errorMessage } from "../../errors"
import { useUserContext } from "../../hooks/UserContext"
import { API_URL, serverError } from "../../util"

const PracticeTaskMessageNew = ({ refetchTask, refetchMsg }) => {
  const { t } = useTranslation()
  const {
    user: { userType },
  } = useUserContext()
  const { taskID } = useParams()

  const [text, setText] = useState("")
  const [isFinish, setIsFinish] = useState(false)
  const [grade, setGrade] = useState(5)

  const [sub, setSub] = useState(false)

  const resetForm = () => {
    setText("")
    setIsFinish(false)
    setGrade(4)
  }

  const handleGradeChange = action => {
    if (action === "down") {
      if (grade === 0) return

      setGrade(grade - 1)
    } else {
      if (grade === 5) return

      setGrade(grade + 1)
    }
  }

  const addMessage = async () => {
    setSub(true)
    try {
      const data = {
        practiceTaskID: taskID,
        text,
        signal: isFinish ? "closeTask" : "",
        grade: Number(grade),
      }

      const resp = await axios.post(`${API_URL}/practiceTaskMessage/add`, data)
      const { status, message } = resp.data
      setSub(false)

      if (status === "failed") return toast.error(errorMessage(message))

      resetForm()

      if (isFinish) refetchTask()
      refetchMsg()
    } catch (err) {
      setSub(false)
      toast.error(serverError())
    }
  }

  return (
    <>
      <div className="field">
        <div className="control">
          <textarea
            rows="3"
            className="textarea"
            value={text}
            placeholder={
              isFinish ? t("practice.finishPlh") : t("messageTextPlh")
            }
            onChange={e => setText(e.target.value)}
          />
        </div>
      </div>

      {userType !== "prodSenior" || (
        <div className="columns">
          <div className="column is-half">
            <div className="field">
              <div className="control">
                <label className="checkbox">
                  <input
                    type="checkbox"
                    value={isFinish}
                    onChange={e => setIsFinish(e.target.checked)}
                  />
                  &nbsp;{t("practice.closeTask")}
                </label>
              </div>
            </div>
          </div>

          <div className="column is-half has-text-right">
            {!isFinish || (
              <>
                <span className="mt-2">
                  {t("practice.grade")}:&nbsp;
                  <strong>{grade}</strong>
                </span>
                &nbsp;
                <button
                  className="button is-small is-rounded is-link"
                  onClick={() => handleGradeChange("down")}
                >
                  -
                </button>
                &nbsp;
                <button
                  className="button is-small is-rounded is-link"
                  onClick={() => handleGradeChange("up")}
                >
                  +
                </button>
              </>
            )}
          </div>
        </div>
      )}

      <div className="field is-grouped is-grouped-right">
        <div className="control">
          <button
            className="button is-small is-link"
            onClick={addMessage}
            disabled={sub}
          >
            {isFinish ? t("finish") : t("add")}
          </button>
        </div>
      </div>
    </>
  )
}

export default PracticeTaskMessageNew
