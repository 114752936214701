import axios from "axios"
import { createContext, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { errorMessage } from "../errors"
import { API_URL, serverError } from "../util"
import usePlans from "./usePlans"

export const PlanContext = createContext()

const PlanContextProvider = ({ children }) => {
  const { t } = useTranslation()
  const { plans, loading, error, refetch } = usePlans()
  const [currentPlan, setCurrentPlan] = useState({})

  const create = async newPlan => {
    try {
      const resp = await axios.post(`${API_URL}/plan/add`, newPlan)
      const { status, message } = resp.data

      if (status === "failed") return toast.error(errorMessage(message))

      refetch()
      toast.success(t("plan.addNewSuccess"))
    } catch (error) {
      toast.error(serverError())
    }
  }

  const switchCurrentPlan = planID => {
    setCurrentPlan(plans.find(plan => plan.id === planID))
  }

  const value = {
    plans,
    loading,
    error,
    refetch,
    create,
    currentPlan,
    switchCurrentPlan,
  }

  return <PlanContext.Provider value={value}>{children}</PlanContext.Provider>
}

export const usePlanContext = () => {
  const context = useContext(PlanContext)

  return context
}

export default PlanContextProvider
