import axios from "axios"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import Select from "react-select"
import { toast } from "react-toastify"
import { errorMessage } from "../../errors"
import { useCommonDataContext } from "../../hooks/CommonDataContext"
import { API_URL, serverError } from "../../util"

const GroupNew = ({ task }) => {
  const { t } = useTranslation()
  const { eduLeaders } = useCommonDataContext()

  const [selectedEduLeader, setSelectedEduLeader] = useState(null)
  const [numOfStudents, setNumOfStudents] = useState(0)

  const eduLeadersOptions = eduLeaders.map(el => ({
    value: el?.id,
    label: `${el?.fullName} (${el?.organization})`,
  }))

  const resetForm = () => {
    setSelectedEduLeader(null)
    setNumOfStudents(0)
  }

  const createGroup = async () => {
    try {
      const data = {
        taskID: task?.id,
        eduLeaderID: selectedEduLeader?.value,
        numOfStudents: Number(numOfStudents),
        specialityID: task?.specialityID,
        workplaceID: task?.workplaceID,
      }

      const resp = await axios.post(`${API_URL}/group/add`, data)
      const { status, message } = resp.data

      if (status === "failed") return toast.error(errorMessage(message))

      resetForm()
      toast.success(t("group.addSuccess"))
    } catch (error) {
      toast.error(serverError())
    }
  }

  return (
    <div className="box">
      <p className="title is-5">{t("nav.addGroup")}</p>

      <hr />

      <div className="columns">
        <div className="column is-half">
          <div className="field">
            <label className="label">{t("group.eduLeader")}</label>
            <p className="control">
              <Select
                styles="select"
                options={eduLeadersOptions}
                value={selectedEduLeader}
                onChange={setSelectedEduLeader}
                placeholder={t("group.selectEduLeaderPlh")}
                isClearable
              />
            </p>
          </div>
        </div>

        <div className="column is-half">
          <div className="field">
            <label className="label">{t("group.numOfStudents")}</label>
            <p className="control">
              <input
                type="number"
                className="input"
                value={numOfStudents}
                onChange={e => setNumOfStudents(e.target.value)}
              />
            </p>
          </div>
        </div>
      </div>

      <div className="field is-grouped is-grouped-right">
        <p className="control">
          <button className="button is-link is-outlined" onClick={resetForm}>
            {t("cancel")}
          </button>
        </p>

        <p className="control">
          <button className="button is-link" onClick={createGroup}>
            {t("add")}
          </button>
        </p>
      </div>
    </div>
  )
}

export default GroupNew
