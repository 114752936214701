import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import useGetData from "../../hooks/useGetData"
import { useUserContext } from "../../hooks/UserContext"
import LoadingSpinner from "../../util/LoadingSpinner"
import PageTitle from "../../util/PageTitle"
import ExtraData from "./ExtraData"
import ResetPassword from "./ResetPassword"

const UserProfile = ({ userID }) => {
  const { t } = useTranslation()
  const { user } = useUserContext()
  const { user: profile, loading: pLoad } = useGetData("user", "/user", {
    userID,
  })

  const isMyProfile = userID === user?.userID

  if (pLoad) return <LoadingSpinner />

  return (
    <>
      <PageTitle>{profile?.fullName}</PageTitle>

      <div className="columns">
        <div className="column is-8">
          <div className="box">
            <p className="title is-5">{t("auth.profile")}</p>

            <hr />

            <p>
              <strong>{t("auth.fullName")}: </strong>
              {profile?.fullName}
            </p>

            <hr />

            <p>
              <strong>Email: </strong>
              {profile?.email}
            </p>

            <hr />

            <p>
              <strong>{t("auth.creationDate")}: </strong>
              {dayjs(profile?.creationDate).format("DD/MM/YYYY")}
            </p>

            <ExtraData profile={profile} />
          </div>
        </div>

        <div className="column is-4">
          {!isMyProfile || <ResetPassword profile={profile} />}
        </div>
      </div>
    </>
  )
}

export default UserProfile
