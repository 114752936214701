import axios from "axios"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { errorMessage } from "../../errors"
import { API_URL } from "../../util"

const ResetPassword = ({ profile }) => {
  const { t } = useTranslation()

  const [sub, setSub] = useState(false)

  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [newPasswordRep, setNewPasswordRep] = useState("")

  const resetFrom = () => {
    setOldPassword("")
    setNewPassword("")
    setNewPasswordRep("")
  }

  const resetPassword = async () => {
    setSub(true)

    try {
      const data = {
        email: profile?.email,
        userType: profile?.userType,
        oldPassword,
        newPassword,
        newPasswordRep,
      }

      const resp = await axios.post(`${API_URL}/user/resetPassword`, data)
      const { status, message } = resp.data

      setSub(false)

      if (status === "failed") return toast.error(errorMessage(message))

      resetFrom()
      toast.success(t("auth.passwordResetSuccess"))
    } catch (error) {
      setSub(false)
      toast.error("Server error: " + error)
    }
  }

  return (
    <div className="box">
      <p className="title is-5">{t("auth.resetPassword")}</p>

      <div className="field">
        <div className="control">
          <label className="label">{t("auth.oldPassword")}</label>
          <input
            type="password"
            className="input"
            placeholder="********"
            value={oldPassword}
            onChange={e => setOldPassword(e.target.value)}
          />
        </div>
      </div>

      <div className="field">
        <div className="control">
          <label className="label">{t("auth.newPassword")}</label>
          <input
            type="password"
            className="input"
            placeholder="********"
            value={newPassword}
            onChange={e => setNewPassword(e.target.value)}
          />
        </div>
      </div>

      <div className="field">
        <div className="control">
          <label className="label">{t("auth.newPasswordRep")}</label>
          <input
            type="password"
            className="input"
            placeholder="********"
            value={newPasswordRep}
            onChange={e => setNewPasswordRep(e.target.value)}
          />
        </div>
      </div>

      <div className="field">
        <div className="control">
          <button
            className="button is-link is-fullwidth"
            onClick={resetPassword}
            disabled={sub}
          >
            {t("auth.resetPassword")}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
