import { createContext, useContext, useState } from "react"
import jwt from "jsonwebtoken"
import axios from "axios"

export const UserContext = createContext()

const userFromToken = token => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token
    const { userID, email, userType, extraData } = jwt.decode(token)
    return { userID, email, userType, extraData }
  } else {
    return {}
  }
}

const tokenFromStorage = localStorage.getItem("token") || ""
const userFromStorage = userFromToken(tokenFromStorage)

const UserContextProvider = ({ children }) => {
  const [token, setToken] = useState(tokenFromStorage)
  const [user, setUser] = useState(userFromStorage)

  const login = newToken => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + newToken
    localStorage.setItem("token", newToken)
    setUser(userFromToken(newToken))
    setToken(newToken)
  }

  const logout = async () => {
    localStorage.removeItem("token")
    axios.defaults.headers.common["Authorization"] = ""
    setToken("")
    setUser({})
  }

  const value = {
    token,
    user,
    login,
    logout,
    isSigned: token.length,
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export const useUserContext = () => {
  const context = useContext(UserContext)

  return context
}

export default UserContextProvider
