import ReactQuill from "react-quill"

const PracticeFinish = () => {
  return (
    <div className="box">
      <p className="title is-5">Завершить практику</p>

      <div className="field">
        <div className="control">
          <label className="label">
            Описание профессиональных задач, решаемых студентом на практике
          </label>

          <ReactQuill
            // value={text}
            // onChange={setText}
            // placeholder={t("practice.taskTextPlh")}
            theme="snow"
          />
        </div>
      </div>

      <div className="field">
        <div className="control">
          <label className="label">Исполненное индивидуальное задание</label>

          <ReactQuill
            // value={text}
            // onChange={setText}
            // placeholder={t("practice.taskTextPlh")}
            theme="snow"
          />
        </div>
      </div>

      <div className="field">
        <div className="control">
          <label className="label">Заключение</label>

          <ReactQuill
            // value={text}
            // onChange={setText}
            // placeholder={t("practice.taskTextPlh")}
            theme="snow"
          />
        </div>
      </div>

      <div className="field is-grouped is-grouped-right">
        <div className="control">
          <button className="button is-link">Сгенерировать отчёт</button>
        </div>
      </div>
    </div>
  )
}

export default PracticeFinish
