import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { errorMessage } from "../../errors"
import { useDataModelContext } from "../../hooks/DataModelContext"
import { API_URL } from "../../util"

const ReadXLSX = ({ dataName }) => {
  const { t } = useTranslation()
  const { refetch } = useDataModelContext()
  const [keyFI, setKeyFI] = useState(Math.random().toString())
  const [uploadFile, setUploadFile] = useState(null)

  const [submitting, setSubmitting] = useState(false)

  const updateData = async () => {
    setSubmitting(true)

    try {
      const formData = new FormData()
      formData.append("file", uploadFile)

      const resp = await axios.post(
        `${API_URL}/thesaurus/${dataName}/addFromXLSX`,
        formData
      )
      setSubmitting(false)
      const { status, message, added, skipped } = resp.data

      if (status === "failed") return toast.error(errorMessage(message))

      refetch()
      setUploadFile(null)
      setKeyFI(Math.random().toString())
      toast.success(t("thesaurus.readXLSX", { added, skipped }))
    } catch (error) {
      setSubmitting(false)
      toast.error("Server error!")
    }
  }

  return (
    <div className="box">
      <div className="field has-addons">
        <div className="control is-expanded">
          <div className="file has-name is-fullwidth">
            <label className="file-label">
              <input
                className="file-input"
                type="file"
                name="file"
                key={keyFI}
                onChange={e => setUploadFile(e.target.files[0])}
              />
              <span className="file-cta">
                <span className="file-icon">
                  <FontAwesomeIcon icon="upload" />
                </span>
                <span className="file-label">{t("chooseFile")}</span>
              </span>
              <span className="file-name">
                {uploadFile ? uploadFile.name : t("noFile")}
              </span>
            </label>
          </div>
        </div>

        <div className="control">
          <button
            className="button is-link"
            onClick={updateData}
            disabled={submitting}
          >
            {t("upload")}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ReadXLSX
