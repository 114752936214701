import axios from "axios"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { errorMessage } from "../errors"
import { API_URL, serverError } from "../util"

const useNeeds = (currentPage, planID = "") => {
  const [needs, setNeeds] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState("")
  const [needRefetch, setNeedRefetch] = useState(true)
  const [pageCount, setPageCount] = useState(0)

  useEffect(() => {
    const fetchNeeds = async () => {
      setLoading(true)

      console.log(planID)

      try {
        const resp = await axios.get(
          `${API_URL}/need/list${
            planID.length ? "ForPlan?planID=" + planID + "&" : "?"
          }page=${currentPage}`
        )
        const { status, message, needs, pageCount } = resp.data

        setLoading(false)
        if (status === "failed") return setError(errorMessage(message))

        setNeeds(needs)
        setPageCount(pageCount)
      } catch (error) {
        setLoading(false)
        toast.error(serverError())
      }
    }

    fetchNeeds()
    setNeedRefetch(false)
  }, [needRefetch, currentPage, planID])

  const refetch = () => setNeedRefetch(true)

  return { needs, loading, error, refetch, pageCount }
}

export default useNeeds
