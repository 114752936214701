import { useTranslation } from "react-i18next"
import SpecialityNew from "../../data/thesaurus/SpecialityNew"
import SpecialityList from "../../data/thesaurus/SpecialityList"
import PageTitle from "../../util/PageTitle"
import { useUserContext } from "../../hooks/UserContext"
import DataModelContextProvider from "../../hooks/DataModelContext"
import ReadXLSX from "../../data/thesaurus/ReadXLSX"

const SpecialityView = () => {
  const { t } = useTranslation()
  const {
    user: { userType },
  } = useUserContext()

  return (
    <DataModelContextProvider
      dataModelName="speciality"
      url="/thesaurus/speciality"
    >
      <PageTitle>{t("thesaurus.specialities")}</PageTitle>

      {userType !== "admin" || <ReadXLSX dataName="speciality" />}

      {userType !== "admin" || <SpecialityNew />}

      <SpecialityList />
    </DataModelContextProvider>
  )
}

export default SpecialityView
