import dayjs from "dayjs"
import useListData from "../../hooks/useListData"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import LoadingSpinner from "../../util/LoadingSpinner"
import Pagination from "../../util/Pagination"

const EduInfoList = () => {
  const { t } = useTranslation()
  const { eduInfos, loading, pageCount, currentPage, setCurrentPage } =
    useListData("eduInfos", "/eduInfo")
  const { regions } = useListData("regions", "/thesaurus/region")

  if (loading) return <LoadingSpinner />

  if (!eduInfos?.length) return <div className="box"></div>

  const regionsMap = (regions || []).reduce(
    (acc, el) => ({ ...acc, [el?.code]: el?.title }),
    {}
  )

  const fullAddress = ei =>
    (ei?.regionCode ? regionsMap[ei?.regionCode] + ", " : "") +
    (ei?.city ? ei?.city + ", " : "") +
    (ei?.address ? ei?.address : "")

  return (
    <>
      {eduInfos.map(ei => (
        <div className="box" key={ei?.id}>
          <p className="title is-5">
            <Link to={"/r/eduInfos/" + ei?.eduOrgID}>{ei?.eduOrg?.title}</Link>
          </p>

          <hr />

          <p className="strong">
            <strong>{t("thesaurus.address")}:&nbsp;</strong>
            {fullAddress(ei)}
          </p>

          <p>
            <strong>{t("eduInfo.canChangeStandard")}:&nbsp;</strong>
            {ei?.canChangeStandard ? t("yes") : t("no")}
          </p>

          <p>
            <strong>{t("creationDate")}:&nbsp;</strong>
            {dayjs(ei?.creationDate).format("DD/MM/YYYY HH:mm")}
          </p>

          <p>
            <strong>{t("lastUpdateDate")}:&nbsp;</strong>
            {dayjs(ei?.lastUpdateDate).format("DD/MM/YYYY HH:mm")}
          </p>
        </div>
      ))}

      <Pagination
        page={currentPage}
        setPage={setCurrentPage}
        pageCount={pageCount}
      />
    </>
  )
}

export default EduInfoList
