import { useTranslation } from "react-i18next"

const EmptyList = ({ text = "" }) => {
  const { t } = useTranslation()

  return (
    <div className="box has-text-grey">
      {text?.length ? text : t("emptyList")}
    </div>
  )
}

export default EmptyList
