import { useDataModelContext } from "../../hooks/DataModelContext"
import LoadingSpinner from "../../util/LoadingSpinner"
import EmptyList from "../../util/EmptyList"
import PracticePreview from "./PracticePreview"
import Pagination from "../../util/Pagination"

const PracticeList = () => {
  const { practices, loading, currentPage, setCurrentPage, pageCount } =
    useDataModelContext()

  if (loading) return <LoadingSpinner />

  if (!practices?.length) return <EmptyList />

  return (
    <>
      {(practices || []).map(practice => (
        <PracticePreview practice={practice} key={practice?.key} />
      ))}

      <Pagination
        page={currentPage}
        pageCount={pageCount}
        setPage={setCurrentPage}
      />
    </>
  )
}

export default PracticeList
