import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useCommonDataContext } from "../../hooks/CommonDataContext"
import { useDataModelContext } from "../../hooks/DataModelContext"
import { useUserContext } from "../../hooks/UserContext"
import EmptyList from "../../util/EmptyList"
import LoadingSpinner from "../../util/LoadingSpinner"
import SuccessRateTag from "./SuccessRateTag"

const SpecialityList = () => {
  const { t } = useTranslation()
  const { specialities, loading } = useDataModelContext()
  const { allUsersMap } = useCommonDataContext()
  const {
    user: { userType },
  } = useUserContext()

  if (loading) return <LoadingSpinner />

  if (specialities.length === 0) return <EmptyList />

  return specialities.map(spec => {
    const author = allUsersMap[spec.authorID]?.fullName

    return (
      <div className="box" key={spec.id}>
        <p className="title is-5">
          {spec?.code}&nbsp;{spec?.title}
          <span className="is-pulled-right has-text-grey is-size-7">
            {spec.id}
          </span>
        </p>

        <div className="columns">
          <div className="column is-half">
            <p>
              <strong>{t("thesaurus.yearsToStudy")}: </strong>
              {spec.yearsToStudy}
            </p>

            <p>
              <strong>{t("thesaurus.date")}: </strong>
              {dayjs(spec?.creationDate).format("DD/MM/YYYY HH:mm")}
            </p>
          </div>

          {userType !== "admin" || (
            <div className="column is-half">
              <p>
                <strong>{t("thesaurus.author")}: </strong>
                {author}
              </p>
            </div>
          )}
        </div>

        {!spec?.description || (
          <div className="field">
            <p className="control">
              <textarea
                className="textarea"
                value={spec?.description}
                rows={Math.min(4, spec?.description.split("\n").length)}
                disabled
              />
            </p>
          </div>
        )}

        <hr className="mt-0 mb-3" />
        <div className="columns">
          <div className="column is-6">
            <SuccessRateTag
              entryID={spec.id}
              entryType="speciality"
              currentVal={spec.successRate}
            />
          </div>

          <div className="column is-6 has-text-right has-text-grey">
            {t(`thesaurus.${spec?.specialityType}`)}
          </div>
        </div>
      </div>
    )
  })
}

export default SpecialityList
