import i18next from "i18next"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import Select from "react-select"
import { useInviteContext } from "../../hooks/InviteContext"
import { useUserContext } from "../../hooks/UserContext"
import { userTypeOptions } from "../../util"

const InviteNew = () => {
  const { t } = useTranslation()
  const {
    user: { userType },
  } = useUserContext()
  const { create } = useInviteContext()

  const [selectedUserType, setSelectedUserType] = useState(null)
  const [email, setEmail] = useState("")
  const [submitting, setSubmitting] = useState(false)

  const addInvite = async () => {
    setSubmitting(true)

    try {
      await create(selectedUserType?.value, email, () => {
        setSelectedUserType(null)
        setEmail("")
      })
      setSubmitting(false)
    } catch (error) {
      setSubmitting(false)
      console.log(error)
    }
  }

  const utOptions =
    userType === "admin"
      ? userTypeOptions[i18next.language]
      : userTypeOptions[i18next.language].filter(opt =>
          ["prodLeader", "prodSenior"].includes(opt?.value)
        )

  return (
    <div className="box">
      <p className="title is-5">{t("invite.addNew")}</p>

      <hr />

      <div className="columns">
        <div className="column is-5">
          <div className="field">
            <Select
              placeholder={t("invite.userTypePlaceholder")}
              options={utOptions}
              value={selectedUserType}
              onChange={setSelectedUserType}
              isClearable
            />
          </div>
        </div>

        <div className="column is-4">
          <div className="field">
            <input
              type="email"
              className="input"
              value={email}
              placeholder="ivanov@mail.com"
              onChange={e => setEmail(e.target.value)}
            />
          </div>
        </div>

        <div className="column is-3">
          <div className="field">
            <p className="control">
              <button
                className="button is-primary is-fullwidth"
                onClick={addInvite}
                disabled={submitting}
              >
                {t("add")}
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InviteNew
