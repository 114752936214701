import { useState } from "react"
import useTasks from "../../hooks/useTasks"
import EmptyList from "../../util/EmptyList"
import LoadingSpinner from "../../util/LoadingSpinner"
import Pagination from "../../util/Pagination"
import TaskPreview from "./TaskPreview"

const TaskList = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const { tasks, loading, pageCount } = useTasks(currentPage)

  if (loading) return <LoadingSpinner />

  if (tasks.length === 0) return <EmptyList />

  return (
    <>
      {tasks.map(task => (
        <TaskPreview task={task} key={task.id} />
      ))}

      <Pagination
        page={currentPage}
        pageCount={pageCount}
        setPage={setCurrentPage}
      />
    </>
  )
}

export default TaskList
