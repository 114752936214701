import { useTranslation } from "react-i18next"
import { Route, Switch, useRouteMatch } from "react-router"
import { Link } from "react-router-dom"
import PlanList from "../../data/plan/PlanList"
import PlanNew from "../../data/plan/PlanNew"
import { useUserContext } from "../../hooks/UserContext"
import PlanInfo from "./PlanInfo"

const PlanView = () => {
  const { t } = useTranslation()
  const { path, url } = useRouteMatch()
  const {
    user: { userType },
  } = useUserContext()

  return (
    <>
      <Switch>
        <Route exact path={path}>
          {["hrLeader", "hrSenior"].includes(userType) ? (
            <div className="box has-text-centered">
              <Link className="is-size-5" to={url + "/add"}>
                {"+ " + t("plan.addNew")}
              </Link>
            </div>
          ) : null}

          <PlanList />
        </Route>

        <Route exact path={path + "/add"}>
          <PlanNew />
        </Route>

        <Route path={path + "/view/:planID"}>
          <PlanInfo />
        </Route>
      </Switch>
    </>
  )
}

export default PlanView
