import axios from "axios"
import Select from "react-select"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { errorMessage } from "../../errors"
import { useDataModelContext } from "../../hooks/DataModelContext"
import useGetData from "../../hooks/useGetData"
import useListData from "../../hooks/useListData"
import { API_URL, serverError } from "../../util"
import { useEffect } from "react"

const PortfolioEdit = ({
  portfolio,
  speciality,
  student,
  eduOrg,
  setPageMode,
}) => {
  const { t } = useTranslation()
  const { refetch } = useDataModelContext()
  const { workplaces, loading: wpLoad } = useListData(
    "workplaces",
    "/thesaurus/workplace"
  )
  const { user } = useGetData("user", "/user", { userID: portfolio?.studentID })

  const [submitting, setSubmitting] = useState(false)

  const workplaceOptions = (workplaces || []).map(wp => ({
    value: wp?.id,
    label: wp?.title,
  }))

  const reasonOptions = [
    { value: "prodInternship", label: t("portfolio.prodInternship") },
    { value: "diplomaInternship", label: t("portfolio.diplomaInternship") },
    { value: "employment", label: t("portfolio.employment") },
    { value: "other", label: t("portfolio.other") },
  ]

  const [selectedWorkplace, setSelectedWorkplace] = useState(
    workplaceOptions.find(opt => opt?.value === portfolio?.workplaceID)
  )
  const [selectedReason, setSelectedReason] = useState(
    reasonOptions?.find(opt => opt?.value === portfolio?.reason)
  )
  const [isReadyToMove, setIsReadyToMove] = useState(portfolio?.isReadyToMove)
  const [hasMilitaryDep, setHasMilitaryDep] = useState(
    portfolio?.hasMilitaryDep
  )
  const [isInMilitaryDep, setIsInMilitaryDep] = useState(
    portfolio?.isInMilitaryDep
  )
  const [letter, setLetter] = useState(portfolio?.letter)

  useEffect(() => {
    if (!wpLoad && workplaceOptions.length) {
      console.log("fired")
      console.log(
        workplaceOptions.find(opt => opt?.value === portfolio?.workplaceID)
      )

      setSelectedWorkplace(
        workplaceOptions.find(opt => opt?.value === portfolio?.workplaceID)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wpLoad, workplaceOptions.length])

  const resetForm = () => {
    setPageMode("view")
    setIsReadyToMove(portfolio?.isReadyToMove)
    setHasMilitaryDep(portfolio?.hasMilitaryDep)
    setIsInMilitaryDep(portfolio?.isInMilitaryDep)
    setLetter(portfolio?.letter)
  }

  const updatePortfolio = async () => {
    setSubmitting(true)

    try {
      const data = {
        id: portfolio?.id,
        reason: selectedReason?.value,
        workplaceID: selectedWorkplace?.value,
        isReadyToMove,
        hasMilitaryDep,
        isInMilitaryDep,
        letter,
      }
      const resp = await axios.post(`${API_URL}/portfolio/update`, data)
      const { status, message } = resp.data

      setSubmitting(false)

      if (status === "failed") return toast.error(errorMessage(message))

      resetForm()
      refetch()
    } catch (error) {
      setSubmitting(false)
      toast.error(serverError())
    }
  }

  return (
    <div className="box">
      <div className="columns">
        <div className="column is-half">
          <p>
            <strong>{t("auth.eduOrg")}:&nbsp;</strong>
            {eduOrg?.title ? eduOrg?.title : student?.extraData?.otherEduText}
          </p>
        </div>

        <div className="column is-half">
          <p>
            <strong>{t("auth.speciality")}:&nbsp;</strong>
            {speciality?.code
              ? `${speciality?.code} ${speciality?.title}`
              : student?.extraData?.otherSpecialityText}
          </p>
        </div>
      </div>

      <div className="columns">
        <div className="column is-third">
          <div className="field">
            <div className="control">
              <label className="label">{t("portfolio.workplace")}</label>
              <Select
                placeholder={t("portfolio.workplacePlh")}
                options={workplaceOptions}
                value={selectedWorkplace}
                onChange={setSelectedWorkplace}
                isLoading={wpLoad}
                isClearable
              />
              <p className="help">{t("req")}</p>
            </div>
          </div>
        </div>

        <div className="column is-third">
          <div className="field">
            <div className="control">
              <label className="label">{t("portfolio.reason")}</label>
              <Select
                placeholder={t("portfolio.reasonPlh")}
                options={reasonOptions}
                value={selectedReason}
                onChange={setSelectedReason}
                isClearable
              />
              {
                <p className="help">
                  {t("req")}
                  {selectedReason?.value !== "other" ||
                    ", " + t("portfolio.otherHelp")}
                </p>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="columns">
        <div className="column is-4">
          <div className="field pt-2">
            <label className="switch is-rounded">
              <input
                type="checkbox"
                value="false"
                checked={isReadyToMove}
                onChange={e => setIsReadyToMove(e.target.checked)}
              />
              <span className="check is-link"></span>
              &nbsp; {t("portfolio.isReadyToMove")}
            </label>
          </div>
        </div>

        <div className="column is-4 has-text-centered">
          {user?.extraData?.gender !== "male" || (
            <div className="field pt-2">
              <label className="switch is-rounded">
                <input
                  type="checkbox"
                  value="false"
                  checked={hasMilitaryDep}
                  onChange={e => setHasMilitaryDep(e.target.checked)}
                />
                <span className="check is-link"></span>
                &nbsp; {t("portfolio.hasMilitaryDep")}
              </label>
            </div>
          )}
        </div>

        <div className="column is-4 has-text-right">
          {!hasMilitaryDep || (
            <div className="field pt-2">
              <label className="switch is-rounded">
                <input
                  type="checkbox"
                  value="false"
                  checked={isInMilitaryDep}
                  onChange={e => setIsInMilitaryDep(e.target.checked)}
                />
                <span className="check is-link"></span>
                &nbsp; {t("portfolio.isInMilitaryDep")}
              </label>
            </div>
          )}
        </div>
      </div>

      <div className="field">
        <div className="control">
          <label className="label">{t("portfolio.letter")}</label>

          <textarea
            className="textarea"
            value={letter}
            onChange={e => setLetter(e.target.value)}
            placeholder={t("portfolio.letterPlh")}
          />

          <p
            className={
              "help" + (letter?.length > 500 ? " has-text-danger" : "")
            }
          >
            {letter?.length}/500&nbsp;{t("symbolsHelp")}
          </p>
        </div>
      </div>

      <div className="field is-grouped is-grouped-right">
        <p className="control">
          <button
            className="button is-light"
            onClick={resetForm}
            disabled={submitting}
          >
            {t("cancel")}
          </button>
        </p>

        <p className="control">
          <button
            className="button is-primary"
            onClick={updatePortfolio}
            disabled={submitting}
          >
            {t("edit")}
          </button>
        </p>
      </div>
    </div>
  )
}

export default PortfolioEdit
