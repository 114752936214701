import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { errorMessage } from "../../errors"
import { API_URL, serverError } from "../../util"

const EduProgramNew = ({ refetch }) => {
  const { t } = useTranslation()

  const [title, setTitle] = useState("")
  const [theoryHours, setTheoryHours] = useState(0)
  const [practiceHours, setPracticeHours] = useState(0)
  const [generalHours, setGeneralHours] = useState(0)
  const [techHours, setTechHours] = useState(0)
  const [examHours, setExamHours] = useState(0)

  const [mode, setMode] = useState("view")
  const [sub, setSub] = useState(false)

  const resetForm = () => {
    setMode("view")

    setTitle("")
    setTheoryHours(0)
    setPracticeHours(0)
    setGeneralHours(0)
    setTechHours(0)
    setExamHours(0)

    refetch()
  }

  const addEduProgram = async () => {
    setSub(true)

    try {
      const data = {
        title,
        theoryHours: Number(theoryHours),
        practiceHours: Number(practiceHours),
        generalHours: Number(generalHours),
        techHours: Number(techHours),
        examHours: Number(examHours),
      }

      const resp = await axios.post(`${API_URL}/eduProgram/add`, data)
      const { status, message } = resp.data

      setSub(false)
      if (status === "failed") return toast.error(errorMessage(message))

      resetForm()
      toast.success(t("eduProgram.addSuccess"))
    } catch (error) {
      setSub(false)
      toast.error(serverError())
    }
  }

  if (mode === "view")
    return (
      <div className="box has-text-centered">
        <span
          className="is-size-5 pointer has-text-link"
          onClick={() => setMode("add")}
        >
          <FontAwesomeIcon icon="plus" />
          &nbsp;{t("eduProgram.addNew")}
        </span>
      </div>
    )

  return (
    <div className="box">
      <p className="title is-5">{t("eduProgram.addNew")}</p>

      <hr />

      <div className="columns">
        <div className="column is-6">
          <div className="field">
            <div className="control">
              <label className="label">{t("eduProgram.title")}</label>
              <input
                type="text"
                className="input"
                placeholder={t("eduProgram.titlePlh")}
                value={title}
                onChange={e => setTitle(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="column is-3">
          <div className="field">
            <div className="control">
              <label className="label">{t("eduProgram.theoryHours")}</label>
              <input
                type="number"
                className="input"
                value={theoryHours}
                onChange={e => setTheoryHours(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="column is-3">
          <div className="field">
            <div className="control">
              <label className="label">{t("eduProgram.practiceHours")}</label>
              <input
                type="number"
                className="input"
                value={practiceHours}
                onChange={e => setPracticeHours(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="columns">
        <div className="column is-4">
          <div className="field">
            <div className="control">
              <label className="label">{t("eduProgram.generalHours")}</label>
              <input
                type="number"
                className="input"
                value={generalHours}
                onChange={e => setGeneralHours(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="column is-4">
          <div className="field">
            <div className="control">
              <label className="label">{t("eduProgram.techHours")}</label>
              <input
                type="number"
                className="input"
                value={techHours}
                onChange={e => setTechHours(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="column is-4">
          <div className="field">
            <div className="control">
              <label className="label">{t("eduProgram.examHours")}</label>
              <input
                type="number"
                className="input"
                value={examHours}
                onChange={e => setExamHours(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="field is-grouped is-grouped-right">
        <div className="control">
          <button
            className="button is-light"
            onClick={resetForm}
            disabled={sub}
          >
            {t("cancel")}
          </button>
        </div>
        <div className="control">
          <button
            className="button is-link"
            onClick={addEduProgram}
            disabled={sub}
          >
            {t("add")}
          </button>
        </div>
      </div>
    </div>
  )
}

export default EduProgramNew
