import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import dayjs from "dayjs"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useCommonDataContext } from "../../hooks/CommonDataContext"
import { useUserContext } from "../../hooks/UserContext"
import NeedNew from "./NeedNew"

const NeedPreview = ({ need }) => {
  const { t } = useTranslation()
  const {
    user: { userType },
  } = useUserContext()
  const { allUsersMap, specialities, workplaces } = useCommonDataContext()

  const [detailed, setDetailed] = useState(false)
  const [editMode, setEditMode] = useState(false)

  if (editMode)
    return <NeedNew editMode={true} need={need} setEditMode={setEditMode} />

  return (
    <div className="box">
      <div className="columns mb-0 pb-0">
        <div className="column is-10">
          <p className="title is-6 has-text-grey">{need?.id}</p>
        </div>

        <div className="column is-2 has-text-right">
          <span
            className="has-text-grey pointer"
            onClick={() => setDetailed(!detailed)}
            title={t("need.showInfoHint")}
          >
            <FontAwesomeIcon icon="info-circle" size="lg" />
          </span>

          {userType !== "hrSenior" || (
            <>
              &nbsp;
              <span
                className="has-text-grey pointer"
                onClick={() => setEditMode(!editMode)}
                title={t("need.showEditHint")}
              >
                <FontAwesomeIcon icon="pen" size="lg" />
              </span>
            </>
          )}
        </div>
      </div>

      <hr className="mb-3 mt-0" />

      <div className="columns">
        <div className="column is-half">
          <p>
            <strong>{t("need.speciality")}: </strong>
            {specialities.find(spec => spec.id === need.specialityID)?.title}
          </p>

          <p>
            <strong>{t("need.workplace")}: </strong>
            {workplaces.find(wp => wp.id === need.workplaceID)?.title}
          </p>

          <p>
            <strong>{t("need.employmentDate")}: </strong>
            {t(`month.${need?.monthOfEmployment}`)} {need?.yearOfEmployment}
          </p>
        </div>

        <div className="column is-half">
          <p>
            <strong>{t("plan.author")}: </strong>
            {allUsersMap[need?.authorID]?.fullName}
          </p>

          <p>
            <strong>{t("plan.creationDate")}: </strong>
            {dayjs(need?.creationDate).format("DD/MM/YYYY HH:mm")}
          </p>

          <p>
            <strong>{t("need.numberOfEngaged")}: </strong>
            {need?.numberOfEngaged}
          </p>
        </div>
      </div>

      {!detailed || (
        <>
          <p>
            <strong>{t("need.competence")}</strong>
            <div
              style={{ whiteSpace: "pre-wrap" }}
              className="has-background-light"
            >
              {need?.competence}
            </div>
          </p>

          <p className="mt-3">
            <strong>{t("need.health")}</strong>
            <div
              style={{ whiteSpace: "pre-wrap" }}
              className="has-background-light"
            >
              {need?.healthRestrictions}
            </div>
          </p>
        </>
      )}
    </div>
  )
}

export default NeedPreview
