import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useCommonDataContext } from "../../hooks/CommonDataContext"

const TaskInfo = ({ task }) => {
  const { t } = useTranslation()
  const { specialities, workplaces } = useCommonDataContext()

  return (
    <div className="box">
      <div className="columns">
        <div className="column is-half">
          <p>
            <strong>{t("need.speciality")}: </strong>
            {specialities.find(spec => spec.id === task.specialityID)?.title}
          </p>

          <p>
            <strong>{t("need.workplace")}: </strong>
            {workplaces.find(wp => wp.id === task.workplaceID)?.title}
          </p>

          <p>
            <strong>{t("plan.creationDate")}: </strong>
            {dayjs(task?.creationDate).format("DD/MM/YYYY HH:mm")}
          </p>

          <p>
            <strong>{t("plan.lastUpdateDate")}: </strong>
            {dayjs(task?.lastUpdateDate).format("DD/MM/YYYY HH:mm")}
          </p>
        </div>

        {/* <div className="column is-half">
          <p>
            <strong>{t("need.competence")}</strong>
          </p>
        </div> */}
      </div>
    </div>
  )
}

export default TaskInfo
