import axios from "axios"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { errorMessage } from "../errors"
import { API_URL, serverError } from "../util"

const useModels = currentPage => {
  const [models, setModels] = useState([])
  const [currentModel, setCurrentModel] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState("")
  const [needRefetch, setNeedRefetch] = useState(true)
  const [pageCount, setPageCount] = useState(0)

  useEffect(() => {
    const fetchModels = async () => {
      setLoading(true)

      try {
        const resp = await axios.get(
          `${API_URL}/model/list?page=${currentPage}`
        )
        const { status, message, models, pageCount } = resp.data

        setLoading(false)
        if (status === "failed") return setError(errorMessage(message))

        setModels(models)
        setPageCount(pageCount)
        setCurrentModel(models.find(mod => mod.isCurrent))
      } catch (error) {
        setLoading(false)
        toast.error(serverError())
      }
    }

    fetchModels()
    setNeedRefetch(false)
  }, [needRefetch, currentPage])

  const refetch = () => setNeedRefetch(true)

  return { models, currentModel, loading, error, refetch, pageCount }
}

export default useModels
