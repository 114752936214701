import axios from "axios"
import i18next from "i18next"
import { createContext, useContext } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { errorMessage } from "../errors"
import { API_URL, serverError, userTypeOptions } from "../util"
import useInvites from "./useInvites"

export const InviteContext = createContext()

const InviteContextProvider = ({ children }) => {
  const { t } = useTranslation()
  const { invites, loading, error, refetch } = useInvites()

  const create = async (userType, email, resetForm) => {
    if (
      !userTypeOptions[i18next.language]
        .map(uto => uto.value)
        .includes(userType)
    )
      return toast.error(errorMessage("user:::007"))

    try {
      const resp = await axios.post(`${API_URL}/user/invite/add`, {
        userType,
        email,
      })
      const { status, message } = resp.data

      if (status === "failed") return toast.error(errorMessage(message))

      resetForm()
      refetch()
      toast.success(t("invite.addNewSuccess"))
    } catch (error) {
      toast.error(serverError())
    }
  }

  const value = {
    invites,
    loading,
    error,
    refetch,
    create,
  }

  return (
    <InviteContext.Provider value={value}>{children}</InviteContext.Provider>
  )
}

export const useInviteContext = () => {
  const context = useContext(InviteContext)

  return context
}

export default InviteContextProvider
