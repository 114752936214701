const AuthLayout = ({ children }) => (
  <div className="section pt-1">
    <div className="container">
      <div className="columns">
        <div className="column is-8 is-offset-2">
          <div className="box mt-5">{children}</div>
        </div>
      </div>
    </div>
  </div>
)

export default AuthLayout
