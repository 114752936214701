import EduInfoList from "../data/eduInfo/EduInfoList"
import { Redirect, Route, Switch, useRouteMatch } from "react-router"
import EduInfoView from "../data/eduInfo/EduInfoView"
import UserProfile from "../data/profile/UserProfile"
import ModelContextProvider from "../hooks/ModelContext"
import PlanContextProvider from "../hooks/PlanContext"
import { useUserContext } from "../hooks/UserContext"
import Navbar from "../util/Navbar"
import HomeView from "./HomeView"
import PlanView from "./plan/PlanView"
import PortfolioView from "./portfolio/PortfolioView"
import SystemView from "./system/SystemView"
import TaskView from "./task/TaskView"
import ThesaurusView from "./thesaurus/ThesaurusView"
import PracticeView from "./practice/PracticeView"
import EduProgramView from "../data/eduProgram/EduProgramView"
import InviteView from "./system/InviteView"

const AuthorizedArea = () => {
  const { path } = useRouteMatch()
  const {
    user: { userType, userID },
  } = useUserContext()

  return (
    <>
      <Navbar />

      <div className="section">
        <div className="container">
          <Switch>
            <Route exact path={path}>
              <HomeView />
            </Route>

            <Route path={path + "/plans"}>
              {[
                "admin",
                "fondLeader",
                "hrLeader",
                "hrSenior",
                "hrJunior",
              ].includes(userType) ? (
                <PlanContextProvider>
                  <PlanView />
                </PlanContextProvider>
              ) : (
                <Redirect to={path} />
              )}
            </Route>

            <Route path={path + "/tasks"}>
              {["admin", "fondLeader", "fondSenior", "fondJunior"].includes(
                userType
              ) ? (
                <PlanContextProvider>
                  <TaskView />
                </PlanContextProvider>
              ) : (
                <Redirect to={path} />
              )}
            </Route>

            <Route path={path + "/portfolio"}>
              {[
                "admin",
                "fondLeader",
                "fondSenior",
                "fondJunior",
                "prodLeader",
                "student",
              ].includes(userType) ? (
                <PortfolioView />
              ) : (
                <Redirect to={path} />
              )}
            </Route>

            <Route path={path + "/practice"}>
              {[
                "admin",
                "fondLeader",
                "fondSenior",
                "fondJunior",
                "prodLeader",
                "prodSenior",
                "student",
              ].includes(userType) ? (
                <PracticeView />
              ) : (
                <Redirect to={path} />
              )}
            </Route>

            <Route path={path + "/invites"}>
              {["prodLeader"].includes(userType) ? (
                <InviteView />
              ) : (
                <Redirect to={path} />
              )}
            </Route>

            <Route path={path + "/eduProgram"}>
              {[
                "admin",
                "fondLeader",
                "fondSenior",
                "fondJunior",
                "eduCorpUni",
              ].includes(userType) ? (
                <EduProgramView />
              ) : (
                <Redirect to={path} />
              )}
            </Route>

            <Route path={path + "/eduInfo"}>
              {["eduLeader", "eduSenior", "eduJunior"].includes(userType) ? (
                <EduInfoView />
              ) : (
                <Redirect to={path} />
              )}
            </Route>

            <Route exact path={path + "/eduInfos"}>
              {["admin"].includes(userType) ? (
                <EduInfoList />
              ) : (
                <Redirect to={path} />
              )}
            </Route>

            <Route path={path + "/eduInfos/:eduOrgID"}>
              {["admin"].includes(userType) ? (
                <EduInfoView />
              ) : (
                <Redirect to={path} />
              )}
            </Route>

            <Route path={path + "/thesaurus"}>
              {["admin", "hrLeader"].includes(userType) ? (
                <ThesaurusView />
              ) : (
                <Redirect to={path} />
              )}
            </Route>

            <Route path={path + "/system"}>
              {userType === "admin" ? (
                <ModelContextProvider>
                  <SystemView />
                </ModelContextProvider>
              ) : (
                <Redirect to={path} />
              )}
            </Route>

            <Route path={path + "/profile"}>
              <UserProfile userID={userID} />
            </Route>
          </Switch>
        </div>
      </div>
    </>
  )
}

export default AuthorizedArea
