import { useTranslation } from "react-i18next"
import { Link, Route, Switch, useParams, useRouteMatch } from "react-router-dom"
import PracticeFinish from "../../data/practice/PracticeFinish"
import PracticePreview from "../../data/practice/PracticePreview"
import useGetData from "../../hooks/useGetData"
import { useUserContext } from "../../hooks/UserContext"
import LoadingSpinner from "../../util/LoadingSpinner"
import PracticeTasksView from "./PracticeTasksView"

const PracticePageView = () => {
  const { t } = useTranslation()
  const {
    user: { userType },
  } = useUserContext()
  const { practiceID } = useParams()
  const { path, url } = useRouteMatch()
  const { practice, loading } = useGetData("practice", "/practice", {
    practiceID,
  })

  if (loading || !practice?.status?.length) return <LoadingSpinner />

  return (
    <div className="columns">
      <div className="column is-3">
        <aside className="menu box">
          <p className="menu-label">
            {userType === "student"
              ? "Прохождение практики"
              : t("practice.practice")}
          </p>
          <ul className="menu-list">
            <li>
              <Link to={url}>{t("nav.info")}</Link>
            </li>

            <li>
              <Link to={url + "/tasks"}>{t("nav.tasks")}</Link>
            </li>

            {userType === "student" || (
              <li>
                <Link to={url + "/finish"}>
                  {t("nav.finish")}
                  &nbsp;
                  {practice?.deadline > new Date() || <>({t("ahead")})</>}
                </Link>
              </li>
            )}
          </ul>
        </aside>
      </div>

      <div className="column is-9">
        <Switch>
          <Route exact path={path}>
            <PracticePreview practice={practice} infoMode={true} />
          </Route>

          <Route path={path + "/tasks"}>
            <PracticeTasksView />
          </Route>

          <Route path={path + "/finish"}>
            <PracticeFinish />
          </Route>
        </Switch>
      </div>
    </div>
  )
}

export default PracticePageView
