import axios from "axios"
import AuthLayout from "./AuthLayout"
import { API_URL, serverError, useQuery } from "../../util"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { errorMessage } from "../../errors"
import { useTranslation } from "react-i18next"
import LoadingSpinner from "../../util/LoadingSpinner"
import { Link } from "react-router-dom"

const VerifyView = () => {
  const { t } = useTranslation()
  const query = useQuery()
  const code = query.get("code")
  const [pageStatus, setPageStatus] = useState("loading")
  const [boxText, setBoxText] = useState("")

  useEffect(() => {
    const postVerify = async () => {
      try {
        const resp = await axios.post(`${API_URL}/user/verify`, { code })
        const { status, message } = resp.data

        if (status === "failed") {
          setPageStatus("error")
          setBoxText(errorMessage(message))
        } else {
          setPageStatus("success")
          setBoxText(t("auth.verifySuccess"))
        }
      } catch (error) {
        toast.error(serverError())
      }
    }

    if (code?.length) postVerify()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code])

  if (pageStatus === "loading")
    return (
      <AuthLayout>
        <h3 className="title is-4 has-text-centered">
          Plan-&#x3e;Hire-&#x3e;Deploy
        </h3>
        <LoadingSpinner />
      </AuthLayout>
    )

  return (
    <AuthLayout>
      <h3 className="title is-4 has-text-centered">
        Plan-&#x3e;Hire-&#x3e;Deploy
      </h3>

      <div
        className={
          "message " + (pageStatus === "error" ? "is-danger" : "is-success")
        }
      >
        <div className="message-body">{boxText}</div>
      </div>

      <hr />
      <p>
        <Link className="has-text-grey" to="../auth/student">
          {t("home")}
        </Link>{" "}
      </p>
    </AuthLayout>
  )
}

export default VerifyView
