import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useCommonDataContext } from "../../hooks/CommonDataContext"
import { useDataModelContext } from "../../hooks/DataModelContext"
import useListData from "../../hooks/useListData"
import { useUserContext } from "../../hooks/UserContext"
import EmptyList from "../../util/EmptyList"
import LoadingSpinner from "../../util/LoadingSpinner"
import SuccessRateTag from "./SuccessRateTag"

const WorkplaceList = () => {
  const { t } = useTranslation()
  const { workplaces, loading } = useDataModelContext()
  const { regions } = useListData("regions", "/thesaurus/region")
  const { allUsersMap } = useCommonDataContext()
  const {
    user: { userType },
  } = useUserContext()

  if (loading) return <LoadingSpinner />

  if (workplaces.length === 0) return <EmptyList />

  const regionsMap = (regions || []).reduce((acc, el) => {
    acc[el.code] = el
    return acc
  }, {})

  return workplaces.map(wp => {
    const author = allUsersMap[wp.authorID]?.fullName

    return (
      <div className="box" key={wp.id}>
        <p className="title is-5">
          {wp.title}&nbsp;
          <span className="has-text-grey">({wp?.branchTitle})</span>
        </p>

        <div className="columns">
          <div className="column is-half">
            <p>
              <strong>{t("thesaurus.region")}: </strong>
              {regionsMap[wp?.regionCode]?.title}
            </p>

            <p>
              <strong>{t("thesaurus.city")}: </strong>
              {wp?.city}
            </p>

            <p>
              <strong>{t("thesaurus.date")}: </strong>
              {dayjs(wp?.creationDate).format("DD/MM/YYYY")}
            </p>
          </div>

          {userType !== "admin" || (
            <div className="column is-half">
              <p>
                <strong>{t("thesaurus.author")}: </strong>
                {author}
              </p>
            </div>
          )}
        </div>

        <hr className="mt-0" />
        <div className="columns">
          <div className="column is-6">
            <SuccessRateTag
              entryID={wp.id}
              entryType="workplace"
              currentVal={wp.successRate}
            />
          </div>

          <div className="column is-6 has-text-grey has-text-right">
            {regionsMap[wp.regionCode]?.federalDistrict}&nbsp;
            {t("thesaurus.federalDistrict")}
          </div>
        </div>
      </div>
    )
  })
}

export default WorkplaceList
