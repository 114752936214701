import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Link, useRouteMatch } from "react-router-dom"
import { useUserContext } from "../hooks/UserContext"

const Navbar = () => {
  const {
    user: { userType },
    logout,
  } = useUserContext()
  const { url } = useRouteMatch()
  const { t } = useTranslation()
  const [showBurger, setShowBurger] = useState(false)

  return (
    <nav
      className="navbar is-dark is-spaced"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <Link
          className="navbar-item has-text-weight-semibold is-uppercase is-size-4 mr-5"
          to="/r"
        >
          {t("appTitle")}
        </Link>

        <span
          role="button"
          className="navbar-burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="appNavbar"
          onClick={() => setShowBurger(!showBurger)}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </span>
      </div>

      <div
        id="appNavbar"
        className={"navbar-menu" + (showBurger ? " is-active" : "")}
      >
        <div className="navbar-start">
          {userType === "admin" ? (
            <>
              <Link className="navbar-item" to={url + "/system"}>
                {t("nav.system")}
              </Link>
            </>
          ) : null}

          {![
            "admin",
            "fondLeader",
            "hrLeader",
            "hrSenior",
            "hrJunior",
          ].includes(userType) || (
            <Link className="navbar-item" to={url + "/plans"}>
              {t("nav.plans")}
            </Link>
          )}

          {!["admin", "fondLeader", "fondSenior", "fondJunior"].includes(
            userType
          ) || (
            <Link className="navbar-item" to={url + "/tasks"}>
              {t("nav.tasks")}
            </Link>
          )}

          {!["eduLeader", "eduSenior", "eduJunior"].includes(userType) || (
            <Link className="navbar-item" to={url + "/eduInfo"}>
              {t("nav.eduInfo")}
            </Link>
          )}

          {!["admin"].includes(userType) || (
            <Link className="navbar-item" to={url + "/eduInfos"}>
              {t("nav.eduInfos")}
            </Link>
          )}

          {!["prodLeader"].includes(userType) || (
            <Link className="navbar-item" to={url + "/invites"}>
              {t("nav.invites")}
            </Link>
          )}

          {![
            "admin",
            "fondLeader",
            "fondSenior",
            "fondJunior",
            "prodLeader",
            "student",
          ].includes(userType) || (
            <Link className="navbar-item" to={url + "/portfolio"}>
              {t("nav.portfolio")}
            </Link>
          )}

          {![
            "admin",
            "fondLeader",
            "fondSenior",
            "fondJunior",
            "prodLeader",
            "prodSenior",
            "student",
          ].includes(userType) || (
            <Link className="navbar-item" to={url + "/practice"}>
              {t("nav.practice")}
            </Link>
          )}

          {![
            "admin",
            "fondLeader",
            "fondSenior",
            "fondJunior",
            "eduCorpUni",
          ].includes(userType) || (
            <Link className="navbar-item" to={url + "/eduProgram"}>
              {t("nav.eduProgram")}
            </Link>
          )}

          {["admin", "hrLeader"].includes(userType) ? (
            <Link className="navbar-item" to={url + "/thesaurus"}>
              {t("nav.thesaurus")}
            </Link>
          ) : null}
        </div>

        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">
              <Link
                className="button is-info"
                to={url + "/profile"}
                title={t("nav.profile")}
              >
                <FontAwesomeIcon icon="user-circle" size="lg" />
              </Link>

              <button
                className="button is-info has-text-danger ml-2"
                onClick={logout}
                title={t("nav.signOut")}
              >
                <FontAwesomeIcon icon="sign-out-alt" size="lg" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
