import axios from "axios"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import Select from "react-select"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { errorMessage } from "../../errors"
import { API_URL, serverError } from "../../util"
import useListData from "../../hooks/useListData"
import { useDataModelContext } from "../../hooks/DataModelContext"

const EduOrgNew = () => {
  const { t } = useTranslation()
  const { refetch } = useDataModelContext()
  const { specialities, loading: specLoad } = useListData(
    "specialities",
    "/thesaurus/speciality"
  )
  const { regions, loading: regLoad } = useListData(
    "regions",
    "/thesaurus/region"
  )

  const [mode, setMode] = useState("view")

  const [submitting, setSubmitting] = useState(false)
  const [title, setTitle] = useState("")
  const [selectedEduOrgType, setSelectedEduOrgType] = useState(null)
  const [selectedSpecilitiesCode, setSelectedSpecilitiesCode] = useState(null)
  const [selectedRegionCode, setSelectedRegionCode] = useState(null)
  const [address, setAddress] = useState("")
  const [contacts, setContacts] = useState("")
  const [description, setDescription] = useState("")
  const [successRate, setSuccessRate] = useState(1.0)

  const eduOrgTypeOptions = [
    { value: "green", label: t("thesaurus.green") },
    { value: "blue", label: t("thesaurus.blue") },
    { value: "yellow", label: t("thesaurus.yellow") },
  ]

  const specOptions = (specialities || []).map(spec => ({
    value: spec?.code,
    label: `${spec?.code} ${spec?.title}`,
  }))

  const regionOptions = (regions || []).map(region => ({
    value: region?.code,
    label: region?.title,
  }))

  const resetForm = () => {
    setMode("view")
    setTitle("")
    setAddress("")
    setContacts("")
    setSelectedEduOrgType(null)
    setSelectedSpecilitiesCode(null)
    setSelectedRegionCode(null)
    setDescription("")
    setSuccessRate(1.0)
  }

  const addEduOrg = async () => {
    setSubmitting(true)

    try {
      const resp = await axios.post(`${API_URL}/thesaurus/eduOrg/add`, {
        title,
        eduOrgType: selectedEduOrgType?.value,
        specialitiesCode: (selectedSpecilitiesCode || []).map(
          selSpec => selSpec?.value
        ),
        regionCode: selectedRegionCode?.value,
        address,
        contacts,
        description,
        successRate: Number(successRate),
      })

      setSubmitting(false)
      const { status, message } = resp.data

      if (status === "failed") return toast.error(errorMessage(message))

      refetch()
      resetForm()
      toast.success(t("thesaurus.addEduOrgSuccess"))
    } catch (error) {
      setSubmitting(false)
      toast.error(serverError())
    }
  }

  if (mode === "view")
    return (
      <div className="box has-text-centered">
        <span
          className="is-size-5 pointer has-text-link"
          onClick={() => setMode("add")}
        >
          <FontAwesomeIcon icon="plus" />
          &nbsp;{t("thesaurus.addEduOrg")}
        </span>
      </div>
    )

  return (
    <div className="box">
      <p className="title is-5">{t("thesaurus.addEduOrg")}</p>

      <hr />

      <div className="columns">
        <div className="column is-8">
          <div className="field">
            <label className="label">{t("thesaurus.title")}</label>
            <input
              type="text"
              className="input"
              value={title}
              placeholder={t("thesaurus.titlePlh")}
              onChange={e => setTitle(e.target.value)}
            />
          </div>
        </div>

        <div className="column is-4">
          <div className="field">
            <label className="label">{t("thesaurus.eduOrgType")}</label>
            <Select
              placeholder={t("thesaurus.eduOrgTypePlh")}
              options={eduOrgTypeOptions}
              value={selectedEduOrgType}
              onChange={setSelectedEduOrgType}
            />
          </div>
        </div>
      </div>

      <div className="columns">
        <div className="column is-8">
          <div className="field">
            <label className="label">{t("thesaurus.sepcilitiesCode")}</label>
            <Select
              placeholder={t("thesaurus.sepcilitiesCodePlh")}
              options={specOptions}
              value={selectedSpecilitiesCode}
              onChange={setSelectedSpecilitiesCode}
              isLoading={specLoad}
              isMulti
              isClearable
            />
          </div>
        </div>

        <div className="column is-4">
          <div className="field">
            <label className="label">{t("thesaurus.successRate")}</label>
            <input
              type="text"
              className="input"
              value={successRate}
              placeholder="Наименование специальности"
              onChange={e => setSuccessRate(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="columns">
        <div className="column is-8">
          <div className="field">
            <label className="label">{t("thesaurus.address")}</label>
            <input
              type="text"
              className="input"
              value={address}
              placeholder={t("thesaurus.addressPlh")}
              onChange={e => setAddress(e.target.value)}
            />
          </div>
        </div>

        <div className="column is-4">
          <div className="field">
            <label className="label">{t("thesaurus.region")}</label>
            <Select
              placeholder={t("thesaurus.regionPlh")}
              value={selectedRegionCode}
              onChange={setSelectedRegionCode}
              options={regionOptions}
              isLoading={regLoad}
            />
          </div>
        </div>
      </div>

      <div className="field">
        <p className="control">
          <label className="label">{t("thesaurus.contacts")}</label>
          <textarea
            className="textarea"
            placeholder={t("thesaurus.contactsPlh")}
            value={contacts}
            onChange={e => setContacts(e.target.value)}
          />
        </p>
      </div>

      <div className="field">
        <p className="control">
          <label className="label">{t("thesaurus.description")}</label>
          <textarea
            className="textarea"
            placeholder={t("thesaurus.descriptionPlh")}
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
        </p>
      </div>

      <div className="field is-grouped is-grouped-right">
        <p className="control">
          <button
            className="button is-light"
            onClick={resetForm}
            disabled={submitting}
          >
            {t("cancel")}
          </button>
        </p>

        <p className="control">
          <button
            className="button is-primary"
            onClick={addEduOrg}
            disabled={submitting}
          >
            {t("add")}
          </button>
        </p>
      </div>
    </div>
  )
}

export default EduOrgNew
