import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import Select from "react-select"
import { toast } from "react-toastify"
import { errorMessage } from "../../errors"
import { useDataModelContext } from "../../hooks/DataModelContext"
import useListData from "../../hooks/useListData"
import { API_URL, serverError } from "../../util"
import { educationFormatOptions } from "./common"

const EduSpecNew = ({ eduInfoID }) => {
  const { t } = useTranslation()
  const { specialities } = useListData("specialities", "/thesaurus/speciality")
  const { refetch } = useDataModelContext()

  const [selectedSpeciality, setSelectedSpeciality] = useState(null)
  const [otherSpecialityText, setOtherSpecialityText] = useState("")
  const [startYear, setStartYear] = useState(new Date().getFullYear())
  const [educationLength, setEducationLength] = useState(5)
  const [selectedEducationFormat, setSelectedEducationFormat] = useState(null)
  const [totalStudentsCount, setTotalStudentsCount] = useState(0)
  const [targetStudentsCount, setTargetStudentsCount] = useState(0)
  const [holdingStudentsCount, setHoldingStudentsCount] = useState(0)
  const [competences, setCompetences] = useState("")
  const [extraSkills, setExtraSkills] = useState("")

  const [pageMode, setPageMode] = useState("view")

  const otherOption = {
    value: "other",
    label: t("auth.other"),
  }

  const specialityOptions = [
    otherOption,
    ...(specialities || []).map(sp => ({
      value: sp?.code,
      label: `${sp?.code} ${sp?.title}`,
    })),
  ]

  const resetForm = () => {
    setPageMode("view")

    setSelectedSpeciality(null)
    setStartYear(new Date().getFullYear())
    setEducationLength(5)
    setSelectedEducationFormat(null)
    setTotalStudentsCount(0)
    setTargetStudentsCount(0)
    setHoldingStudentsCount(0)
    setCompetences("")
    setExtraSkills("")
  }

  const addEduSpec = async () => {
    try {
      const data = {
        eduInfoID,
        specialityCode: selectedSpeciality?.value,
        startYear: Number(startYear),
        educationLength: Number(educationLength),
        educationFormat: selectedEducationFormat?.value,
        totalStudentsCount: Number(totalStudentsCount),
        targetStudentsCount: Number(targetStudentsCount),
        holdingStudentsCount: Number(holdingStudentsCount),
        competences,
        extraSkills,
        otherSpecialityText,
      }

      const resp = await axios.post(`${API_URL}/eduSpec/add`, data)
      const { status, message } = resp.data

      if (status === "failed") return toast.error(errorMessage(message))

      resetForm()
      refetch()
    } catch (error) {
      toast.error(serverError())
    }
  }

  if (pageMode === "view")
    return (
      <div className="box has-text-centered">
        <span
          className="is-size-5 pointer has-text-link"
          onClick={() => setPageMode("add")}
        >
          <FontAwesomeIcon icon="plus" />
          &nbsp;{t("eduInfo.addSpec")}
        </span>
      </div>
    )

  return (
    <div className="box">
      <div className="field">
        <div className="control">
          <label className="label">{t("eduInfo.speciality")}</label>
          <Select
            options={specialityOptions}
            placeholder={t("eduInfo.specialityPlh")}
            value={selectedSpeciality}
            onChange={setSelectedSpeciality}
            isClearable
          />
        </div>
      </div>

      {selectedSpeciality?.value !== "other" || (
        <div className="field">
          <div className="control">
            <label className="label">{t("eduInfo.otherSpec")}</label>
            <input
              type="text"
              className="input"
              value={otherSpecialityText}
              onChange={e => setOtherSpecialityText(e.target.value)}
              placeholder={t("eduInfo.otherSpecPlh")}
            />
          </div>
        </div>
      )}

      <div className="columns">
        <div className="column is-third">
          <div className="field">
            <div className="control">
              <label className="label">{t("eduInfo.startYear")}</label>
              <input
                type="number"
                className="input"
                value={startYear}
                onChange={e => setStartYear(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="column is-third">
          <div className="field">
            <div className="control">
              <label className="label">{t("eduInfo.educationLength")}</label>
              <input
                type="number"
                className="input"
                value={educationLength}
                onChange={e => setEducationLength(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="column is-third">
          <div className="field">
            <div className="control">
              <label className="label">{t("eduInfo.educationFormat")}</label>
              <Select
                options={educationFormatOptions(t)}
                placeholder={t("eduInfo.educationFormatPlh")}
                value={selectedEducationFormat}
                onChange={setSelectedEducationFormat}
                isClearable
              />
            </div>
          </div>
        </div>
      </div>

      {/* Students Count */}

      <div className="columns">
        <div className="column is-third">
          <div className="field">
            <div className="control">
              <label className="label">{t("eduInfo.totalStudentsCount")}</label>
              <input
                type="number"
                className="input"
                value={totalStudentsCount}
                onChange={e => setTotalStudentsCount(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="column is-third">
          <div className="field">
            <div className="control">
              <label className="label">
                {t("eduInfo.targetStudentsCount")}
              </label>
              <input
                type="number"
                className="input"
                value={targetStudentsCount}
                onChange={e => setTargetStudentsCount(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="column is-third">
          <div className="field">
            <div className="control">
              <label className="label">
                {t("eduInfo.holdingStudentsCount")}
              </label>
              <input
                type="number"
                className="input"
                value={holdingStudentsCount}
                onChange={e => setHoldingStudentsCount(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Text fields */}
      <div className="field">
        <div className="control">
          <label className="label">{t("eduInfo.competences")}</label>
          <textarea
            className="textarea"
            placeholder={t("eduInfo.competencesPlh")}
            value={competences}
            onChange={e => setCompetences(e.target.value)}
          />
        </div>
      </div>

      <div className="field">
        <div className="control">
          <label className="label">{t("eduInfo.extraSkills")}</label>
          <textarea
            className="textarea"
            placeholder={t("eduInfo.extraSkillsPlh")}
            value={extraSkills}
            onChange={e => setExtraSkills(e.target.value)}
          />
        </div>
      </div>

      {/* Buttons */}

      <div className="field is-grouped is-grouped-right">
        <div className="control">
          <button
            className="button is-small is-light"
            onClick={() => setPageMode("view")}
          >
            {t("cancel")}
          </button>
        </div>

        <div className="control">
          <button className="button is-small is-link" onClick={addEduSpec}>
            {t("add")}
          </button>
        </div>
      </div>
    </div>
  )
}

export default EduSpecNew
