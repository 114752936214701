import { useState } from "react"
import { usePlanContext } from "../../hooks/PlanContext"
import useNeeds from "../../hooks/useNeeds"
import EmptyList from "../../util/EmptyList"
import LoadingSpinner from "../../util/LoadingSpinner"
import Pagination from "../../util/Pagination"
import NeedPreview from "./NeedPreview"

const NeedList = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const { currentPlan } = usePlanContext()
  const { needs, pageCount, loading } = useNeeds(currentPage, currentPlan?.id)

  if (loading || !currentPlan?.id) return <LoadingSpinner />

  if ((needs || []).length === 0) return <EmptyList />

  return (
    <>
      {needs.map(need => (
        <NeedPreview need={need} key={need?.id} />
      ))}

      <Pagination
        pageCount={pageCount}
        page={currentPage}
        setPage={setCurrentPage}
      />
    </>
  )
}

export default NeedList
