import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useRouteMatch, Link } from "react-router-dom"
import { useDataModelContext } from "../../hooks/DataModelContext"
import EmptyList from "../../util/EmptyList"
import LoadingSpinner from "../../util/LoadingSpinner"

const tdStyle = (status, deadline) => {
  if (status === "closed") return ""

  const now = new Date()
  const diffDays = Math.ceil((new Date(deadline) - now) / (1000 * 60 * 60 * 24))

  if (diffDays < 0) return "has-text-danger"
  else if (diffDays <= 1) return "has-text-warning"
  else return ""
}

const PracticeTaskList = () => {
  const { t } = useTranslation()
  const { url } = useRouteMatch()
  const { loading, tasks } = useDataModelContext()

  if (loading) return <LoadingSpinner />

  if (!tasks?.length) return <EmptyList />

  return (
    <div className="box">
      <div className="table-container">
        <table className="table is-fullwidth">
          <thead>
            <tr>
              <th>{t("practice.taskTitle")}</th>
              <th>{t("practice.taskType")}</th>
              <th>{t("practice.deadline")}</th>
              <th className="has-text-centered">{t("practice.status")}</th>
            </tr>
          </thead>

          <tbody>
            {tasks.map(task => (
              <tr key={task?.id}>
                <td>
                  <Link to={url + "/" + task?.id}>{task?.title}</Link>
                </td>
                <td>{t(`practice.taskTypes.${task.taskType}`)}</td>
                <td className={tdStyle(task?.status, task?.deadline)}>
                  {dayjs(task?.deadline).format("DD/MM/YYYY")}
                </td>
                <td className="has-text-centered">
                  {t(`practice.taskStatus.${task?.status}`)}
                  {task?.status !== "closed" || (
                    <span>&nbsp;({task?.grade})</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default PracticeTaskList
