import { useTranslation } from "react-i18next"
import EduOrgList from "../../data/thesaurus/EduOrgList"
import EduOrgNew from "../../data/thesaurus/EduOrgNew"
import ReadXLSX from "../../data/thesaurus/ReadXLSX"
import DataModelContextProvider from "../../hooks/DataModelContext"
import { useUserContext } from "../../hooks/UserContext"
import PageTitle from "../../util/PageTitle"

const EduOrgView = () => {
  const { t } = useTranslation()
  const {
    user: { userType },
  } = useUserContext()

  return (
    <DataModelContextProvider dataModelName="eduOrg" url="/thesaurus/eduOrg">
      <PageTitle>{t("thesaurus.eduOrgs")}</PageTitle>

      {userType !== "admin" || <ReadXLSX dataName="eduOrg" />}

      {userType !== "admin" || <EduOrgNew />}

      <EduOrgList />
    </DataModelContextProvider>
  )
}

export default EduOrgView
