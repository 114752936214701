import AceEditor from "react-ace"
import "ace-builds/src-noconflict/mode-python"
import "ace-builds/src-noconflict/ext-language_tools"
import "ace-builds/src-noconflict/theme-monokai"
import { useEffect, useState } from "react"
import { API_URL, defaultSourceCode, serverError } from "../../util"
import { useModelContext } from "../../hooks/ModelContext"
import { useTranslation } from "react-i18next"
import LoadingSpinner from "../../util/LoadingSpinner"
import { toast } from "react-toastify"
import axios from "axios"
import { errorMessage } from "../../errors"

const validateSourceCode = code => {
  const regexp =
    /^def compute\(need, regionRate, wpRate, specRate, specYears\):((.|\n)*)return need\s+$/m
  return regexp.test(code)
}
const ModelEditView = () => {
  const { t } = useTranslation()
  const [sourceCode, setSourceCode] = useState(defaultSourceCode)
  const [changesDescription, setChangesDescription] = useState("")
  const { currentModel, loading, refetch } = useModelContext()

  useEffect(() => {
    if (currentModel?.sourceCode) setSourceCode(currentModel?.sourceCode)
  }, [currentModel])

  const createModel = async () => {
    if (!validateSourceCode(sourceCode))
      return toast.error(errorMessage("model:::001"))

    if (changesDescription.length < 5)
      return toast.error(errorMessage("model:::002"))

    try {
      const resp = await axios.post(`${API_URL}/model/add`, {
        sourceCode,
        changesDescription,
      })

      const { status, message } = resp.data
      if (status === "failed") return toast.error(errorMessage(message))

      refetch()
      setChangesDescription("")
    } catch (error) {
      toast.error(serverError())
    }
  }

  if (loading) return <LoadingSpinner />

  return (
    <div className="box">
      <p className="title is-5">
        {t("model.currentModel")}:{" "}
        <span className="has-text-grey">
          {currentModel
            ? `V${currentModel?.version} - ${currentModel?.name}`
            : t("model.default")}
        </span>
      </p>

      <hr />

      <AceEditor
        placeholder="Placeholder Text"
        mode="python"
        theme="monokai"
        name="model-editor"
        onChange={newVal => setSourceCode(newVal)}
        fontSize={14}
        showPrintMargin={true}
        showGutter={true}
        highlightActiveLine={true}
        value={sourceCode}
        width="100%"
        height="300px"
        setOptions={{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: false,
          showLineNumbers: true,
          tabSize: 4,
        }}
      />

      {sourceCode !== currentModel?.sourceCode ? (
        <>
          <div className="field mt-3">
            <p className="control">
              <textarea
                rows={2}
                value={changesDescription}
                onChange={e => setChangesDescription(e.target.value)}
                placeholder={t("model.changesPlaceholder")}
                className="textarea"
              ></textarea>
            </p>
          </div>

          <div className="field is-grouped is-grouped-centered">
            <p className="control">
              <button className="button is-primary" onClick={createModel}>
                {t("model.save")}
              </button>
            </p>

            <p className="control mx-5">
              <button
                className="button is-light"
                onClick={() =>
                  setSourceCode(
                    currentModel?.sourceCode
                      ? currentModel.sourceCode
                      : defaultSourceCode
                  )
                }
              >
                {t("model.cancel")}
              </button>
            </p>

            <p className="control">
              <button
                className="button is-light"
                onClick={() => setSourceCode(defaultSourceCode)}
              >
                {t("model.toDefault")}
              </button>
            </p>
          </div>
        </>
      ) : null}
    </div>
  )
}

export default ModelEditView
