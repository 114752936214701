import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useUserContext } from "../../hooks/UserContext"
import { taRows } from "../../util"
import EduSpecEdit from "./EduSpecEdit"
import EduSpecRemove from "./EduSpecRemove"

const EduSpecPreview = ({ es, specialitiesMap }) => {
  const { t } = useTranslation()
  const {
    user: { userType },
  } = useUserContext()

  const [mode, setMode] = useState("view")

  const spec = specialitiesMap[es?.specialityCode]
  const specTitle = `${spec?.code} ${spec?.title}`

  if (mode === "edit")
    return <EduSpecEdit es={es} setMode={setMode} specTitle={specTitle} />

  if (mode === "remove")
    return (
      <EduSpecRemove
        eduSpecTitle={specTitle}
        eduSpecID={es?.id}
        setMode={setMode}
      />
    )

  return (
    <div className="box">
      <p className="title is-6">
        {spec?.code}
        &nbsp;
        {spec?.title}
      </p>

      <hr />

      <div className="columns">
        <div className="column is-half">
          <p>
            <strong>{t("eduInfo.startYear")}:&nbsp;</strong>
            {es?.startYear}
          </p>

          <p>
            <strong>{t("eduInfo.educationLength")}:&nbsp;</strong>
            {es?.educationLength} лет
          </p>

          <p>
            <strong>{t("eduInfo.educationFormat")}:&nbsp;</strong>
            {t("eduInfo." + es?.educationFormat)}
          </p>
        </div>

        <div className="column is-half">
          <p>
            <strong>{t("eduInfo.totalStudentsCount")}:&nbsp;</strong>
            <span className="tag is-round">{es?.totalStudentsCount}</span>
          </p>

          <p>
            <strong>{t("eduInfo.targetStudentsCount")}:&nbsp;</strong>
            <span className="tag is-round">{es?.targetStudentsCount}</span>
          </p>

          <p>
            <strong>{t("eduInfo.holdingStudentsCount")}:&nbsp;</strong>
            <span className="tag is-round">{es?.holdingStudentsCount}</span>
          </p>
        </div>
      </div>

      <div className="field">
        <div className="control">
          <label className="label">{t("eduInfo.competences")}</label>
          <textarea
            className="textarea"
            value={es?.competences}
            rows={taRows(es?.competences)}
            disabled
          />
        </div>
      </div>

      {!es?.extraSkills?.length || (
        <div className="field">
          <div className="control">
            <label className="label">{t("eduInfo.extraSkills")}</label>
            <textarea
              className="textarea"
              value={es?.extraSkills}
              rows={taRows(es?.extraSkills)}
              disabled
            />
          </div>
        </div>
      )}

      {!userType.includes("edu") || (
        <div className="field is-grouped is-grouped-right mt-4">
          <div className="control">
            <button
              className="button is-small is-link"
              onClick={() => setMode("edit")}
            >
              {t("edit")}
            </button>
          </div>

          <div className="control">
            <button
              className="button is-small is-danger"
              onClick={() => setMode("remove")}
            >
              {t("remove")}
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default EduSpecPreview
