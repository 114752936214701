import axios from "axios"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { errorMessage } from "../../errors"
import { API_URL, serverError } from "../../util"
import { useDataModelContext } from "../../hooks/DataModelContext"
import Select from "react-select"
import useListData from "../../hooks/useListData"

const BranchNew = () => {
  const { t } = useTranslation()
  const { refetch } = useDataModelContext()
  const { divisions } = useListData("divisions", "/thesaurus/division")

  const [mode, setMode] = useState("view")

  const [submitting, setSubmitting] = useState(false)
  const [title, setTitle] = useState("")
  const [selectedDiv, setSelectedDiv] = useState(null)

  const divOptions = (divisions || []).map(div => ({
    value: div?.id,
    label: div?.title,
  }))

  const resetForm = () => {
    setMode("view")
    setTitle("")
  }

  const addBranch = async () => {
    setSubmitting(true)

    try {
      const resp = await axios.post(`${API_URL}/thesaurus/branch/add`, {
        title,
        divisionID: selectedDiv?.value,
      })

      setSubmitting(false)
      const { status, message } = resp.data

      if (status === "failed") return toast.error(errorMessage(message))

      refetch()
      resetForm()
      toast.success(t("thesaurus.addBranchSuccess"))
    } catch (error) {
      setSubmitting(false)
      toast.error(serverError())
    }
  }

  if (mode === "view")
    return (
      <div className="box has-text-centered">
        <span
          className="is-size-5 pointer has-text-link"
          onClick={() => setMode("add")}
        >
          <FontAwesomeIcon icon="plus" />
          &nbsp;{t("thesaurus.addBranch")}
        </span>
      </div>
    )

  return (
    <div className="box">
      <p className="title is-5">{t("thesaurus.addBranch")}</p>

      <hr />

      <div className="columns">
        <div className="column is-7">
          <div className="field">
            <label className="label">{t("thesaurus.title")}</label>
            <input
              type="text"
              className="input"
              value={title}
              placeholder={t("thesaurus.titlePlh")}
              onChange={e => setTitle(e.target.value)}
            />
          </div>
        </div>

        <div className="column is-5">
          <div className="field">
            <div className="control">
              <label className="label">{t("thesaurus.division")}</label>
              <Select
                options={divOptions}
                value={selectedDiv}
                onChange={setSelectedDiv}
                placeholder={t("thesaurus.divisionPlh")}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="field is-grouped is-grouped-right">
        <p className="control">
          <button
            className="button is-light"
            onClick={resetForm}
            disabled={submitting}
          >
            {t("cancel")}
          </button>
        </p>

        <p className="control">
          <button
            className="button is-primary"
            onClick={addBranch}
            disabled={submitting}
          >
            {t("add")}
          </button>
        </p>
      </div>
    </div>
  )
}

export default BranchNew
