import axios from "axios"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { errorMessage } from "../../errors"
import { API_URL, serverError } from "../../util"

const PracticeDecision = ({ practiceID, refetch }) => {
  const { t } = useTranslation()

  const [declineReason, setDeclineReason] = useState("")

  const [mode, setMode] = useState("view")
  const [sub, setSub] = useState(false)

  const makeDecision = async decision => {
    setSub(true)

    try {
      const data = { practiceID, decision, declineReason }
      const resp = await axios.post(`${API_URL}/practice/decise`, data)
      const { status, message } = resp.data
      setSub(false)

      if (status === "failed") return toast.error(errorMessage(message))

      refetch()
    } catch (err) {
      setSub(false)
      toast.error(serverError())
    }
  }

  if (mode === "accept")
    return (
      <>
        <hr />

        <div className="columns">
          <div className="column is-8">{t("practice.acceptHint")}</div>

          <div className="column is-4">
            <div className="field is-grouped is-grouped-right">
              <div className="control">
                <button
                  className="button is-small is-light"
                  onClick={() => setMode("view")}
                  disabled={sub}
                >
                  {t("cancel")}
                </button>
              </div>
              <div className="control">
                <button
                  className="button is-small is-success"
                  onClick={() => makeDecision("accept")}
                  disabled={sub}
                >
                  {t("accept")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    )

  if (mode === "decline")
    return (
      <>
        <hr />

        <div className="field">
          <div className="control">
            <input
              type="text"
              className="input"
              placeholder={t("practice.declinePlh")}
              value={declineReason}
              onChange={e => setDeclineReason(e.target.value)}
            />
          </div>
        </div>

        <div className="field is-grouped is-grouped-right">
          <div className="control">
            <button
              className="button is-small is-light"
              onClick={() => setMode("view")}
              disabled={sub}
            >
              {t("cancel")}
            </button>
          </div>
          <div className="control">
            <button
              className="button is-small is-danger"
              onClick={() => makeDecision("decline")}
              disabled={sub}
            >
              {t("decline")}
            </button>
          </div>
        </div>
      </>
    )

  return (
    <>
      <hr />

      <div className="field is-grouped is-grouped-right">
        <div className="control">
          <button
            className="button is-small is-danger"
            onClick={() => setMode("decline")}
            disabled={sub}
          >
            {t("decline")}
          </button>
        </div>
        <div className="control">
          <button
            className="button is-small is-success"
            onClick={() => setMode("accept")}
            disabled={sub}
          >
            {t("accept")}
          </button>
        </div>
      </div>
    </>
  )
}

export default PracticeDecision
