import axios from "axios"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import Select from "react-select"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { errorMessage } from "../../errors"
import { API_URL, serverError } from "../../util"
import { useDataModelContext } from "../../hooks/DataModelContext"

const SpecialityNew = () => {
  const { t } = useTranslation()
  const { refetch } = useDataModelContext()

  const [mode, setMode] = useState("view")

  const [submitting, setSubmitting] = useState(false)
  const [code, setCode] = useState("")
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [selectedSpecialityType, setSelectedSpecialityType] = useState(null)
  const [yearsToStudy, setYearsToStudy] = useState(4)
  const [successRate, setSuccessRate] = useState(1.0)

  const specOptions = ["college", "university"].map(val => ({
    value: val,
    label: t(`thesaurus.${val}`),
  }))

  const resetForm = () => {
    setMode("view")
    setCode("")
    setTitle("")
    setDescription("")
    setSelectedSpecialityType(null)
    setYearsToStudy(1)
    setSuccessRate(1.0)
  }

  const addSpeciality = async () => {
    setSubmitting(true)

    try {
      const resp = await axios.post(`${API_URL}/thesaurus/speciality/add`, {
        code,
        title,
        specialityType: selectedSpecialityType?.value,
        description,
        yearsToStudy: Number(yearsToStudy),
        successRate: Number(successRate),
      })

      setSubmitting(false)
      const { status, message } = resp.data

      if (status === "failed") return toast.error(errorMessage(message))

      refetch()
      resetForm()
      toast.success(t("thesaurus.addSpecialitySuccess"))
    } catch (error) {
      setSubmitting(false)
      toast.error(serverError())
    }
  }

  if (mode === "view")
    return (
      <div className="box has-text-centered">
        <span
          className="is-size-5 pointer has-text-link"
          onClick={() => setMode("add")}
        >
          <FontAwesomeIcon icon="plus" />
          &nbsp;{t("thesaurus.addSpeciality")}
        </span>
      </div>
    )

  return (
    <div className="box">
      <p className="title is-5">{t("thesaurus.addSpeciality")}</p>

      <hr />

      <div className="columns">
        <div className="column is-3">
          <div className="field">
            <label className="label">{t("thesaurus.code")}</label>
            <input
              type="text"
              className="input"
              value={code}
              placeholder={t("thesaurus.codePlh")}
              onChange={e => setCode(e.target.value)}
            />
          </div>
        </div>

        <div className="column is-9">
          <div className="field">
            <label className="label">{t("thesaurus.title")}</label>
            <input
              type="text"
              className="input"
              value={title}
              placeholder={t("thesaurus.titlePlh")}
              onChange={e => setTitle(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="columns">
        <div className="column is-6">
          <div className="field">
            <label className="label">{t("thesaurus.specType")}</label>
            <Select
              value={selectedSpecialityType}
              onChange={setSelectedSpecialityType}
              options={specOptions}
              placeholder={t("thesaurus.specTypePlh")}
            />
          </div>
        </div>

        <div className="column is-3">
          <div className="field">
            <label className="label">{t("thesaurus.yearsToStudy")}</label>
            <input
              type="number"
              min={0}
              max={25}
              className="input"
              value={yearsToStudy}
              placeholder="Наименование специальности"
              onChange={e => setYearsToStudy(e.target.value)}
            />
          </div>
        </div>

        <div className="column is-3">
          <div className="field">
            <label className="label">{t("thesaurus.successRate")}</label>
            <input
              type="text"
              className="input"
              value={successRate}
              placeholder="Наименование специальности"
              onChange={e => setSuccessRate(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="field">
        <p className="control">
          <label className="label">{t("thesaurus.description")}</label>
          <textarea
            className="textarea"
            placeholder={t("thesaurus.descriptionPlh")}
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
        </p>
      </div>

      <div className="field is-grouped is-grouped-right">
        <p className="control">
          <button
            className="button is-light"
            onClick={resetForm}
            disabled={submitting}
          >
            {t("cancel")}
          </button>
        </p>

        <p className="control">
          <button
            className="button is-primary"
            onClick={addSpeciality}
            disabled={submitting}
          >
            {t("add")}
          </button>
        </p>
      </div>
    </div>
  )
}

export default SpecialityNew
