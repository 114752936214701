import axios from "axios"
import { useEffect, useState } from "react"
import { API_URL } from "../../util"

const GroupList = ({ task }) => {
  const [groups, setGroups] = useState([])

  useEffect(() => {
    const fetchGroups = async () => {
      const { data } = await axios.get(
        `${API_URL}/group/list?taskID=${task?.id}`
      )

      setGroups(data.groups)
    }

    fetchGroups()
  }, [task?.id])

  return (
    <div className="box">
      <pre>{JSON.stringify(groups, "", 2)}</pre>
    </div>
  )
}

export default GroupList
