import axios from "axios"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { errorMessage } from "../errors"
import { API_URL, serverError } from "../util"

const useGetData = (dataName, url, query = {}) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [needRefetch, setNeedRefetch] = useState(true)

  const queryString = (Object.entries(query) || []).reduce(
    (acc, [key, value]) => acc + `${key}=${value}&`,
    "?"
  )

  useEffect(() => {
    const fetch = async () => {
      try {
        const resp = await axios.get(`${API_URL + url}/get${queryString}`)
        const { status, message } = resp.data

        setLoading(false)
        if (status === "failed") return toast.error(errorMessage(message))

        setData(resp.data[dataName])
      } catch (error) {
        setLoading(false)
        toast.error(serverError())
      }
    }

    if (needRefetch) {
      fetch()
      setNeedRefetch(false)
    }
  }, [needRefetch, dataName, url, queryString])

  const refetch = (needsReload = false) => {
    if (needsReload) setLoading(true)

    setNeedRefetch(true)
  }

  return {
    [dataName]: data,
    loading,
    refetch,
  }
}

export default useGetData
