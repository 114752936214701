import axios from "axios"
import { errorMessage } from "../../errors"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { API_URL } from "../../util"
import { serverError } from "../../util"

const PasswordForgot = ({ userType, setMode }) => {
  const { t } = useTranslation()
  const [userEmail, setUserEmail] = useState("")

  const [sub, setSub] = useState(false)

  const forgotPassword = async event => {
    event.preventDefault()
    setSub(true)

    try {
      const data = { userEmail, userType }

      const resp = await axios.post(`${API_URL}/passwordReset/add`, data)
      const { status, message } = resp.data
      setSub(false)

      if (status === "failed") return toast.error(errorMessage(message))

      setMode("signIn")
      toast.success(t("auth.createForgotSuccess"))
    } catch (err) {
      setSub(false)
      toast.error(serverError())
    }
  }

  return (
    <form>
      <div className="field">
        <div className="control">
          <label className="label">Email</label>
          <input
            type="text"
            className="input"
            value={userEmail}
            onChange={e => setUserEmail(e.target.value)}
            placeholder="ivanov@mail.com"
          />
        </div>
      </div>

      <div className="field mt-5">
        <div className="control">
          <button
            className="button is-link is-fullwidth"
            onClick={forgotPassword}
            disabled={sub}
          >
            {t("auth.forgotPassword")}
          </button>
        </div>
      </div>
    </form>
  )
}

export default PasswordForgot
