import { useTranslation } from "react-i18next"
import { Switch, Link, Route, Redirect, useRouteMatch } from "react-router-dom"
import SpecialityView from "./SpecialityView"
import WorkplaceView from "./WorkplaceView"
import RegionView from "./RegionView"
import EduOrgView from "./EduOrgView"
import DivisionView from "./DivisionView"
import BranchView from "./BranchView"

const ThesaurusView = () => {
  const { t } = useTranslation()
  const { path, url } = useRouteMatch()

  const menu = (
    <div className="box">
      <aside className="menu">
        <p className="menu-label">{t("nav.thesaurus")}</p>
        <ul className="menu-list">
          <li>
            <Link to={url + "/specialities"}>
              {t("thesaurus.specialities")}
            </Link>
          </li>

          <li>
            <Link to={url + "/eduOrgs"}>{t("thesaurus.eduOrgs")}</Link>
          </li>

          <li>
            <Link to={url + "/divisions"}>{t("thesaurus.divisions")}</Link>
          </li>

          <li>
            <Link to={url + "/branchs"}>{t("thesaurus.branchs")}</Link>
          </li>

          <li>
            <Link to={url + "/workplaces"}>{t("thesaurus.workplaces")}</Link>
          </li>

          <li>
            <Link to={url + "/regions"}>{t("thesaurus.regions")}</Link>
          </li>
        </ul>
      </aside>
    </div>
  )

  return (
    <div className="columns">
      <div className="column is-3">{menu}</div>

      <div className="column is-9">
        <Switch>
          <Route exact path={path + "/"}>
            <Redirect to={url + "/specialities"} />
          </Route>

          <Route path={path + "/specialities"}>
            <SpecialityView />
          </Route>

          <Route path={path + "/eduOrgs"}>
            <EduOrgView />
          </Route>

          <Route path={path + "/divisions"}>
            <DivisionView />
          </Route>

          <Route path={path + "/branchs"}>
            <BranchView />
          </Route>

          <Route path={path + "/workplaces"}>
            <WorkplaceView />
          </Route>

          <Route path={path + "/regions"}>
            <RegionView />
          </Route>
        </Switch>
      </div>
    </div>
  )
}

export default ThesaurusView
