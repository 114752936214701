import axios from "axios"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import { errorMessage } from "../../errors"
import useGetData from "../../hooks/useGetData"
import { API_URL, serverError, useQuery } from "../../util"
import LoadingSpinner from "../../util/LoadingSpinner"
import AuthLayout from "./AuthLayout"

const ForgotView = () => {
  const { t } = useTranslation()
  const query = useQuery()
  const history = useHistory()
  const { loading, passwordReset } = useGetData(
    "passwordReset",
    "/passwordReset",
    { code: query.get("code") }
  )

  const [sub, setSub] = useState(false)

  const [newPassword, setNewPassword] = useState("")
  const [newPasswordRep, setNewPasswordRep] = useState("")

  if (loading)
    return (
      <AuthLayout>
        <h3 className="title is-4 has-text-centered">
          Plan-&#x3e;Hire-&#x3e;Deploy
        </h3>

        <LoadingSpinner />
      </AuthLayout>
    )

  if (!passwordReset?.code?.length)
    return (
      <AuthLayout>
        <h3 className="title is-4 has-text-centered">
          Plan-&#x3e;Hire-&#x3e;Deploy
        </h3>

        <div className="message is-danger">
          <div className="message-body">{errorMessage("user:::025")}</div>
        </div>
      </AuthLayout>
    )

  const usePasswordReset = async event => {
    event.preventDefault()
    setSub(true)

    try {
      const data = { code: passwordReset?.code, newPassword, newPasswordRep }

      const resp = await axios.post(`${API_URL}/passwordReset/use`, data)
      const { status, message } = resp.data
      setSub(false)

      if (status === "failed") return toast.error(errorMessage(message))

      toast.success(t("auth.passwordResetSuccess"))
      history.push("/")
    } catch (err) {
      setSub(false)
      toast.error(serverError())
    }
  }

  return (
    <AuthLayout>
      <h3 className="title is-4 has-text-centered">
        Plan-&#x3e;Hire-&#x3e;Deploy
      </h3>

      <form>
        <div className="field">
          <label className="label">Email</label>
          <div className="control">
            <input
              type="text"
              className="input"
              value={passwordReset?.userEmail}
              disabled
            />
          </div>
        </div>

        <div className="field">
          <div className="control">
            <label className="label">{t("auth.newPassword")}</label>
            <input
              type="password"
              className="input"
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
            />
          </div>
        </div>

        <div className="field">
          <div className="control">
            <label className="label">{t("auth.newPasswordRep")}</label>
            <input
              type="password"
              className="input"
              value={newPasswordRep}
              onChange={e => setNewPasswordRep(e.target.value)}
            />
          </div>
        </div>

        <div className="field mt-5">
          <div className="control">
            <button
              className="button is-link is-fullwidth"
              onClick={usePasswordReset}
              disabled={sub}
            >
              {t("auth.resetPassword")}
            </button>
          </div>
        </div>
      </form>
    </AuthLayout>
  )
}

export default ForgotView
