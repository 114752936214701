import PortfolioList from "../../data/portfolio/PortfolioList"
import PortfolioNew from "../../data/portfolio/PortfolioNew"
import PortfolioStudentNew from "../../data/portfolio/PortfolioStudentNew"
import DataModelContextProvider from "../../hooks/DataModelContext"
import { useUserContext } from "../../hooks/UserContext"

const PortfolioView = () => {
  const {
    user: { userType, extraData },
  } = useUserContext()

  return (
    <DataModelContextProvider
      dataModelName="portfolio"
      url="/portfolio"
      query={{ workplacesID: (extraData?.workplacesID || []).join() }}
    >
      {userType !== "student" || <PortfolioNew />}

      {userType !== "prodLeader" || <PortfolioStudentNew />}

      <PortfolioList />
    </DataModelContextProvider>
  )
}

export default PortfolioView
