import i18next from "i18next"
import { useTranslation } from "react-i18next"
import { Redirect, Route, Switch, useRouteMatch } from "react-router"
import { Link } from "react-router-dom"
import ModelList from "../../data/model/ModelList"
import UserList from "../../data/user/UserList"
import { userTypes } from "../../util"
import PageTitle from "../../util/PageTitle"
import InviteView from "./InviteView"
import ModelEditView from "./ModelEditView"

const SystemView = () => {
  const { url, path } = useRouteMatch()
  const { t } = useTranslation()

  const menu = (
    <>
      <div className="box">
        <aside className="menu">
          <p className="menu-label">{t("system.users")}</p>
          <ul className="menu-list">
            <li>
              <Link to={url + "/invites"}>{t("system.invites")}</Link>
            </li>

            {Object.keys(userTypes[i18next.language]).map(ut => (
              <li key={ut}>
                <Link to={url + "/" + ut + "List"}>{t(`system.${ut}s`)}</Link>
              </li>
            ))}
          </ul>
        </aside>
      </div>

      <div className="box">
        <aside className="menu">
          <p className="menu-label">{t("system.models")}</p>
          <ul className="menu-list">
            <li>
              <Link to={url + "/editModel"}>{t("system.editModel")}</Link>
            </li>

            <li>
              <Link to={url + "/listModel"}>{t("system.listModel")}</Link>
            </li>
          </ul>
        </aside>
      </div>
    </>
  )

  return (
    <div className="columns">
      <div className="column is-3">{menu}</div>

      <div className="column is-9">
        <Switch>
          <Route exact path={path + "/"}>
            <Redirect to={path + "/invites"} />
          </Route>

          <Route path={path + "/invites"}>
            <PageTitle>{t("system.invites")}</PageTitle>
            <InviteView />
          </Route>

          {Object.keys(userTypes[i18next.language]).map(ut => (
            <Route path={path + "/" + ut + "List"} key={ut}>
              <PageTitle>{t(`system.${ut}s`)}</PageTitle>
              <UserList userType={ut} />
            </Route>
          ))}

          <Route path={path + "/editModel"}>
            <PageTitle>{t("system.editModel")}</PageTitle>
            <ModelEditView />
          </Route>

          <Route path={path + "/listModel"}>
            <PageTitle>{t("system.listModel")}</PageTitle>
            <ModelList />
          </Route>
        </Switch>
      </div>
    </div>
  )
}

export default SystemView
