import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import SignInForm from "./SignInForm"
import SignUpForm from "./SignUpForm"
import { userTypes } from "../../util"
import i18next from "i18next"
import PasswordForgot from "./PasswordForgot"

const LoginView = () => {
  const { t } = useTranslation()
  const { userType } = useParams()
  const [mode, setMode] = useState("signIn")

  return (
    <>
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>
          {mode === "signIn" ? (
            <SignInForm userType={userType} />
          ) : mode === "signUp" ? (
            <SignUpForm userType={userType} setMode={setMode} />
          ) : (
            <PasswordForgot userType={userType} setMode={setMode} />
          )}
        </div>

        <div className="divider is-vertical">{t("or")}</div>

        <div style={{ flex: 1 }}>
          {mode !== "signIn" ? (
            <div className="has-text-centered has-text-primary">
              <p className="mt-5">
                <FontAwesomeIcon icon="sign-in-alt" size="6x" />
              </p>

              <br />

              <p className="pointer" onClick={() => setMode("signIn")}>
                {t("auth.alreadyHasAccount")}...
              </p>
            </div>
          ) : (
            <div className="has-text-centered has-text-primary">
              <p className="mt-5">
                <FontAwesomeIcon icon="user-plus" size="6x" />
              </p>

              <br />

              <p className="pointer" onClick={() => setMode("signUp")}>
                {t("auth.createAccount")}...
              </p>
            </div>
          )}
        </div>
      </div>

      <hr className="mb-3" />

      <div className="columns pt-0">
        <div className="column is-half">
          <p className="has-text-grey">
            <Link className="has-text-grey" to="..">
              {t("back")}
            </Link>
            &nbsp;|&nbsp;
            <span
              className="has-text-grey pointer is-lowercase"
              onClick={() => setMode("reset")}
            >
              {t("auth.forgotPassword")}
            </span>
          </p>
        </div>

        <div className="column is-half has-text-right has-text-grey is-lowercase">
          ({userTypes[i18next.language][userType]})
        </div>
      </div>
    </>
  )
}

export default LoginView
