import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useCommonDataContext } from "../../hooks/CommonDataContext"
import Select from "react-select"
import { toast } from "react-toastify"
import { API_URL, serverError } from "../../util"
import axios from "axios"
import { errorMessage } from "../../errors"
import { useUserContext } from "../../hooks/UserContext"

const TaskStaff = ({ task, refetch }) => {
  const { t } = useTranslation()
  const {
    user: { userType },
  } = useUserContext()
  const { fondSeniors, fondJuniors } = useCommonDataContext()

  const [responsiblesMode, setResponsiblesMode] = useState("view")
  const [executorsMode, setExecutorsMode] = useState("view")

  const responsiblesOptions = (fondSeniors || []).map(fs => ({
    value: fs?.id,
    label: fs?.fullName,
  }))

  const executorsOptions = (fondJuniors || []).map(fs => ({
    value: fs?.id,
    label: fs?.fullName,
  }))

  const responsibles = (task?.responsiblesID || []).map(
    respID => fondSeniors.find(fs => fs?.id === respID)?.fullName
  )

  const executors = (task?.executorsID || []).map(
    execID => fondJuniors.find(fs => fs?.id === execID)?.fullName
  )

  const updateResponsibles = async option => {
    try {
      const resp = await axios.post(`${API_URL}/task/updateResponsibles`, {
        taskID: task?.id,
        responsiblesID: option.map(r => r.value),
      })

      const { status, message } = resp.data
      if (status === "failed") return toast.error(errorMessage(message))

      refetch(false)
    } catch (error) {
      toast.error(serverError())
    }
  }

  const updateExecutors = async option => {
    try {
      const resp = await axios.post(`${API_URL}/task/updateExecutors`, {
        taskID: task?.id,
        executorsID: option.map(r => r.value),
      })

      const { status, message } = resp.data
      if (status === "failed") return toast.error(errorMessage(message))

      refetch(false)
    } catch (error) {
      toast.error(serverError())
    }
  }

  return (
    <>
      <div className="box">
        {responsiblesMode === "view" ? (
          <p>
            <strong>{t("task.responsibles")}:</strong>&nbsp;
            {responsibles?.length
              ? responsibles.join(", ")
              : t("task.noResponsibles")}
            {userType !== "fondLeader" || (
              <span
                className="is-pulled-right has-text-link pointer"
                onClick={() => setResponsiblesMode("edit")}
              >
                {t("task.set")}
              </span>
            )}
          </p>
        ) : (
          <div className="columns">
            <div className="column is-half">
              <Select
                options={responsiblesOptions}
                value={(responsiblesOptions || []).filter(opt =>
                  (task?.responsiblesID || []).includes(opt.value)
                )}
                onChange={updateResponsibles}
                placeholder={t("task.selectResponsiblePlh")}
                isMulti
                isClearable
              />
            </div>

            <div className="column is-half">
              <span
                className="is-pulled-right has-text-link pointer pt-2"
                onClick={() => setResponsiblesMode("view")}
              >
                {t("task.close")}
              </span>
            </div>
          </div>
        )}
      </div>

      <div className="box">
        {executorsMode === "view" ? (
          <p>
            <strong>{t("task.executors")}:</strong>&nbsp;
            {executors?.length
              ? executors.join(", ")
              : t("task.noResponsibles")}
            {userType !== "fondSenior" || (
              <span
                className="is-pulled-right has-text-link pointer"
                onClick={() => setExecutorsMode("edit")}
              >
                {t("task.set")}
              </span>
            )}
          </p>
        ) : (
          <div className="columns">
            <div className="column is-half">
              <Select
                options={executorsOptions}
                value={(executorsOptions || []).filter(opt =>
                  (task?.executorsID || []).includes(opt.value)
                )}
                onChange={updateExecutors}
                placeholder={t("task.selectExecutorsPlh")}
                isMulti
                isClearable
              />
            </div>

            <div className="column is-half">
              <span
                className="is-pulled-right has-text-link pointer pt-2"
                onClick={() => setExecutorsMode("view")}
              >
                {t("task.close")}
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default TaskStaff
