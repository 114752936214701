import { Route, Switch, useRouteMatch } from "react-router-dom"
import PracticeList from "../../data/practice/PracticeList"
import PracticeNew from "../../data/practice/PracticeNew"
import DataModelContextProvider from "../../hooks/DataModelContext"
import { useUserContext } from "../../hooks/UserContext"
import PracticePageView from "./PracticePageView"

const PracticeView = () => {
  const { path } = useRouteMatch()
  const {
    user: { userType, extraData },
  } = useUserContext()

  let query = {}
  switch (userType) {
    case "prodLeader":
      query = { workplacesID: (extraData?.workplacesID || []).join() }
      break
    default:
  }

  return (
    <DataModelContextProvider
      dataModelName="practice"
      url="/practice"
      query={query}
    >
      <Switch>
        <Route exact path={path}>
          <PracticeList />
        </Route>

        <Route exact path={path + "/add"}>
          <PracticeNew />
        </Route>

        <Route path={path + "/:practiceID"}>
          <PracticePageView />
        </Route>
      </Switch>
    </DataModelContextProvider>
  )
}

export default PracticeView
