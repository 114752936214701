import dayjs from "dayjs"
import AceEditor from "react-ace"
import "ace-builds/src-noconflict/mode-python"
import "ace-builds/src-noconflict/ext-language_tools"
import "ace-builds/src-noconflict/theme-monokai"
import { useTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { toast } from "react-toastify"
import { API_URL, serverError } from "../../util"
import axios from "axios"
import { errorMessage } from "../../errors"
import { useModelContext } from "../../hooks/ModelContext"

const ModelPreview = ({ model }) => {
  const { t } = useTranslation()
  const { refetch } = useModelContext()

  const [showCode, setShowCode] = useState(false)

  const resetModel = async () => {
    try {
      const resp = await axios.post(`${API_URL}/model/reset`, {
        modelID: model.id,
      })

      const { status, message } = resp.data
      if (status === "failed") return toast.error(errorMessage(message))

      refetch()
    } catch (error) {
      toast.error(serverError())
    }
  }

  return (
    <div className="box">
      <div className="columns pb-0 mb-0">
        <div className="column is-10">
          <p className="title is-5">
            V{model?.version} - {model?.name}{" "}
            {model?.isCurrent ? (
              <span className="has-text-success">({t("model.current")})</span>
            ) : (
              ""
            )}
          </p>
        </div>

        <div className="column is-2 has-text-right">
          {!model?.isCurrent ? (
            <>
              <span
                className="has-text-grey pointer"
                onClick={resetModel}
                title={t("model.resetHint")}
              >
                <FontAwesomeIcon icon="check" />
              </span>
              &nbsp;
            </>
          ) : null}

          <span
            className="has-text-grey pointer"
            onClick={() => setShowCode(!showCode)}
            title={t("model.showSourceHint")}
          >
            <FontAwesomeIcon icon="code" />
          </span>
        </div>
      </div>

      <hr className="mt-0 pt-0" />

      <p>
        <strong>{t("model.changes")}: </strong>
        {model?.changesDescription}
      </p>

      <p>
        <strong>{t("plan.creationDate")}: </strong>
        {dayjs(model?.creationDate).format("DD/MM/YYYY HH:mm")}
      </p>

      {showCode ? (
        <AceEditor
          className="mt-3"
          readOnly
          placeholder="Placeholder Text"
          mode="python"
          theme="monokai"
          name={model?.name}
          fontSize={14}
          showPrintMargin={true}
          showGutter={true}
          highlightActiveLine={true}
          value={model?.sourceCode}
          width="100%"
          maxLines={model?.sourceCode.split("\n").length}
        />
      ) : null}
    </div>
  )
}

export default ModelPreview
