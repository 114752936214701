import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useCommonDataContext } from "../../hooks/CommonDataContext"
import { useDataModelContext } from "../../hooks/DataModelContext"
import { useUserContext } from "../../hooks/UserContext"
import EmptyList from "../../util/EmptyList"
import LoadingSpinner from "../../util/LoadingSpinner"

const DivisionList = () => {
  const { t } = useTranslation()
  const { allUsersMap } = useCommonDataContext()
  const {
    user: { userType },
  } = useUserContext()
  const { divisions, loading } = useDataModelContext()

  if (loading) return <LoadingSpinner />

  if (divisions?.length === 0) return <EmptyList />

  return divisions.map(spec => {
    const author = allUsersMap[spec.authorID]?.fullName

    return (
      <div className="box" key={spec.id}>
        <p className="title is-5">{spec?.title}</p>

        <div className="columns">
          <div className="column is-half">
            <p>
              <strong>{t("thesaurus.date")}: </strong>
              {dayjs(spec?.creationDate).format("DD/MM/YYYY HH:mm")}
            </p>
          </div>

          {userType !== "admin" || (
            <div className="column is-half">
              <p>
                <strong>{t("thesaurus.author")}: </strong>
                {author}
              </p>
            </div>
          )}
        </div>
      </div>
    )
  })
}

export default DivisionList
