import { Route, Switch, useRouteMatch } from "react-router-dom"
import TaskPage from "./TaskPage"
import TaskList from "../../data/task/TaskList"
import { usePlanContext } from "../../hooks/PlanContext"
import LoadingSpinner from "../../util/LoadingSpinner"

const TaskView = () => {
  const { path } = useRouteMatch()
  const { loading } = usePlanContext()

  if (loading) return <LoadingSpinner />

  return (
    <Switch>
      <Route exact path={path}>
        <TaskList />
      </Route>

      <Route path={path + "/view/:taskID"}>
        <TaskPage />
      </Route>
    </Switch>
  )
}

export default TaskView
