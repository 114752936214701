import { useModelContext } from "../../hooks/ModelContext"
import EmptyList from "../../util/EmptyList"
import LoadingSpinner from "../../util/LoadingSpinner"
import Pagination from "../../util/Pagination"
import ModelPreview from "./ModelPreview"

const ModelList = () => {
  const { models, loading, pageCount, currentPage, setCurrentPage } =
    useModelContext()

  if (loading) return <LoadingSpinner />

  if (models.length === 0) return <EmptyList />

  return (
    <>
      {models.map(model => (
        <ModelPreview model={model} key={model.id} />
      ))}

      <Pagination
        pageCount={pageCount}
        page={currentPage}
        setPage={setCurrentPage}
      />
    </>
  )
}

export default ModelList
