import { useTranslation } from "react-i18next"
import Loader from "react-loader-spinner"

const LoadingSpinner = ({ text }) => {
  const { t } = useTranslation()

  return (
    <div className="box">
      <div className="has-text-centered has-text-grey py-2">
        <Loader type="Watch" color="hsl(0, 0%, 48%)" height={100} width={100} />
        <p className="title has-text-grey is-5 mt-4">
          {t("spinnerText")} {text}
        </p>
      </div>
    </div>
  )
}

export default LoadingSpinner
