import { useState } from "react"
import Select from "react-select"
import { useTranslation } from "react-i18next"
import { useEffect } from "react"
import { toast } from "react-toastify"
import { API_URL, serverError } from "../../util"
import axios from "axios"
import { errorMessage } from "../../errors"

const EduInfoEdit = ({ eduInfo, setPageMode, regions, refetch }) => {
  const { t } = useTranslation()

  const [selectedRegion, setSelectedRegion] = useState(null)
  const [city, setCity] = useState(eduInfo?.city)
  const [address, setAddress] = useState(eduInfo?.address)
  const [canChangeStandard, setCanChangeStandard] = useState(
    eduInfo?.canChangeStandard
  )

  const regionsOptions = (regions || []).map(reg => ({
    value: reg?.code,
    label: reg?.title,
  }))

  useEffect(() => {
    if (regionsOptions?.length)
      setSelectedRegion(
        regionsOptions.find(opt => opt?.value === eduInfo?.regionCode)
      )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionsOptions?.length])

  const updateEduInfo = async () => {
    try {
      const data = {
        id: eduInfo?.id,
        regionCode: selectedRegion?.value,
        city,
        address,
        canChangeStandard,
      }
      const resp = await axios.post(`${API_URL}/eduInfo/update`, data)
      const { status, message } = resp.data

      if (status === "failed") return toast.error(errorMessage(message))

      refetch()
      setPageMode("view")
    } catch (error) {
      toast.error(serverError())
    }
  }

  return (
    <>
      <div className="field">
        <div className="control">
          <label className="label">{t("thesaurus.region")}</label>
          <Select
            options={regionsOptions}
            value={selectedRegion}
            onChange={setSelectedRegion}
            placeholder={t("thesaurus.regionPlh")}
            isClearable
          />
        </div>
      </div>

      <div className="field">
        <div className="control">
          <label className="label">{t("thesaurus.city")}</label>
          <input
            type="text"
            className="input"
            value={city}
            onChange={e => setCity(e.target.value)}
          />
        </div>
      </div>

      <div className="field">
        <div className="control">
          <label className="label">{t("thesaurus.address")}</label>
          <input
            type="text"
            className="input"
            value={address}
            onChange={e => setAddress(e.target.value)}
          />
        </div>
      </div>

      <div className="field pt-2">
        <label className="switch is-rounded">
          <input
            type="checkbox"
            value="false"
            checked={canChangeStandard}
            onChange={e => setCanChangeStandard(e.target.checked)}
          />
          <span className="check is-link"></span>
          &nbsp; {t("eduInfo.canChangeStandard")}
        </label>
      </div>

      <div className="field is-grouped is-grouped-right mt-5">
        <div className="control">
          <button
            className="button is-small is-light"
            onClick={() => setPageMode("view")}
          >
            {t("cancel")}
          </button>
        </div>

        <div className="control">
          <button className="button is-small is-link" onClick={updateEduInfo}>
            {t("edit")}
          </button>
        </div>
      </div>
    </>
  )
}

export default EduInfoEdit
