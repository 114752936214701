import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { useUserContext } from "../hooks/UserContext"

const HomeView = () => {
  const { t } = useTranslation()
  const {
    user: { userType },
  } = useUserContext()

  return (
    <>
      <div className="box">{t("homeView.welcome")}</div>

      {userType !== "student" || (
        <div className="box">
          {t("homeView.portfolio")}{" "}
          <Link to="/r/portfolio" className="is-lowercase">
            {t("portfolio.portfolio")}
          </Link>
        </div>
      )}
    </>
  )
}

export default HomeView
