import dayjs from "dayjs"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import DataModelContextProvider from "../../hooks/DataModelContext"
import useGetData from "../../hooks/useGetData"
import useListData from "../../hooks/useListData"
import { useUserContext } from "../../hooks/UserContext"
import LoadingSpinner from "../../util/LoadingSpinner"
import PageTitle from "../../util/PageTitle"
import EduInfoEdit from "./EduInfoEdit"
import EduSpecList from "./EduSpecList"

const EduInfoView = () => {
  const { eduOrgID } = useParams()
  const { t } = useTranslation()
  const { user } = useUserContext()
  const { eduInfo, loading, refetch } = useGetData("eduInfo", "/eduInfo", {
    eduOrgID: eduOrgID || user?.extraData?.eduOrgID,
  })
  const { regions } = useListData("regions", "/thesaurus/region")

  const [pageMode, setPageMode] = useState("view")

  const regionsMap = (regions || []).reduce((acc, el) => {
    acc[el?.code] = el?.title
    return acc
  }, {})

  const notSpecified = (
    <span className="has-text-grey is-lowercase">{t("notSpecified")}</span>
  )

  if (loading || !eduInfo?.id?.length) return <LoadingSpinner />

  return (
    <>
      <PageTitle>{eduInfo?.eduOrg?.title}</PageTitle>

      <div className="columns">
        <div className="column is-5">
          <div className="box">
            <p className="title is-5">{t("eduInfo.info")}</p>

            <hr />

            {pageMode === "view" ? (
              <>
                <p>
                  <strong>{t("thesaurus.region")}:&nbsp;</strong>
                  {eduInfo?.regionCode
                    ? regionsMap[eduInfo?.regionCode]
                    : notSpecified}
                </p>

                <p>
                  <strong>{t("thesaurus.city")}:&nbsp;</strong>
                  {eduInfo?.city ? eduInfo?.city : notSpecified}
                </p>

                <p>
                  <strong>{t("thesaurus.address")}:&nbsp;</strong>
                  {eduInfo?.address ? eduInfo?.address : notSpecified}
                </p>

                <p>
                  <strong>{t("eduInfo.canChangeStandard")}:&nbsp;</strong>
                  {eduInfo?.canChangeStandard ? t("yes") : t("no")}
                </p>

                <p>
                  <strong>{t("creationDate")}:&nbsp;</strong>
                  {dayjs(eduInfo?.creationDate).format("DD/MM/YYYY HH:mm")}
                </p>

                <p>
                  <strong>{t("lastUpdateDate")}:&nbsp;</strong>
                  {dayjs(eduInfo?.lastUpdateDate).format("DD/MM/YYYY HH:mm")}
                </p>

                {!user?.userType?.includes("edu") || (
                  <div className="field is-grouped is-grouped-right mt-3">
                    <div className="control">
                      <button
                        className="button is-link is-small"
                        onClick={() => setPageMode("edit")}
                      >
                        {t("edit")}
                      </button>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <EduInfoEdit
                setPageMode={setPageMode}
                eduInfo={eduInfo}
                regions={regions}
                refetch={refetch}
              />
            )}
          </div>
        </div>

        <div className="column is-7">
          <DataModelContextProvider
            dataModelName={"eduSpec"}
            url={"/eduSpec"}
            query={{ eduInfoID: eduInfo.id }}
          >
            <EduSpecList eduInfoID={eduInfo?.id} />
          </DataModelContextProvider>
        </div>
      </div>
    </>
  )
}

export default EduInfoView
