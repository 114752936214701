import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import NumberFormat from "react-number-format"
import Select from "react-select"
import useListData from "../../hooks/useListData"
import { yosList } from "../../yos"

const ExtraDataForm = ({ userType, extraData, setExtraData }) => {
  const { t } = useTranslation()
  const { eduOrgs, loading: eoLoad } = useListData(
    "eduOrgs",
    "/thesaurus/eduOrgShort"
  )
  const { specialities, loading: specLoad } = useListData(
    "specialities",
    "/thesaurus/specialityShort"
  )
  const { workplaces, loading: wpLoad } = useListData(
    "workplaces",
    "/thesaurus/workplaceShort"
  )

  const [selectedGender, setSelectedGender] = useState(null)
  const [selectedEduOrg, setSelectedEduOrg] = useState(null)
  const [selectedSpeciality, setSelectedSpeciality] = useState(null)
  const [selectedYearOfStudy, setSelectedYearOfStudy] = useState(null)
  const [selectedPreferred, setSelectedPreferred] = useState(null)
  const [selectedWorkplaces, setSelectedWorkplaces] = useState(null)

  useEffect(() => {
    if (!selectedEduOrg?.value || selectedEduOrg?.value === "other")
      setExtraData({ ...extraData, eduOrgID: "" })
    else setExtraData({ ...extraData, eduOrgID: selectedEduOrg?.value })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEduOrg])

  useEffect(() => {
    if (!selectedSpeciality?.value || selectedSpeciality?.value === "other")
      setExtraData({ ...extraData, specialityID: "" })
    else setExtraData({ ...extraData, specialityID: selectedSpeciality?.value })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSpeciality])

  useEffect(() => {
    if (!selectedWorkplaces?.length)
      setExtraData({ ...extraData, workplacesID: [] })
    else
      setExtraData({
        ...extraData,
        workplacesID: (selectedWorkplaces || []).map(wp => wp?.value),
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWorkplaces])

  const currentYear = new Date().getFullYear()

  useEffect(() => {
    if (!selectedYearOfStudy?.value)
      setExtraData({ ...extraData, yearOfStudy: "" })
    else
      setExtraData({
        ...extraData,
        yearOfStudy: `${currentYear}:::${selectedYearOfStudy?.value}`,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYearOfStudy])

  useEffect(() => {
    if (!selectedPreferred?.value)
      setExtraData({ ...extraData, preferredContact: "" })
    else
      setExtraData({ ...extraData, preferredContact: selectedPreferred?.value })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPreferred])

  useEffect(() => {
    if (!selectedGender?.value) setExtraData({ ...extraData, gender: "" })
    else setExtraData({ ...extraData, gender: selectedGender?.value })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGender])

  const otherOption = {
    value: "other",
    label: t("auth.other"),
  }

  const genderOptions = [
    { value: "male", label: "Мужской" },
    { value: "female", label: "Женский" },
  ]

  const eduOrgOptions =
    userType !== "student"
      ? (eduOrgs || []).map(eduOrg => ({
          value: eduOrg?.id,
          label: eduOrg?.title,
        }))
      : [
          otherOption,
          ...(eduOrgs || []).map(eduOrg => ({
            value: eduOrg?.id,
            label: eduOrg?.title,
          })),
        ]

  const specialityOptions =
    userType !== "student"
      ? []
      : [
          otherOption,
          ...(specialities || []).map(spec => ({
            value: spec?.id,
            label: `${spec?.code} ${spec?.title}`,
          })),
        ]

  const workplaceOptions = !userType.includes("prod")
    ? []
    : (workplaces || []).map(wp => ({ value: wp?.id, label: wp?.title }))

  const yosOptions = yosList.map(yos => ({
    value: yos,
    label: t(`auth.yos.${yos}`),
  }))

  const preferredContactOptions = [
    { value: "email", label: "Email" },
    { value: "phone", label: t("auth.phoneContact") },
  ]

  const formField = field => (
    <div className="field">
      <label className="label">{t(`auth.${field}`)}</label>
      <div className="control">
        <input
          className="input"
          type="text"
          placeholder={t(`auth.${field}Plh`)}
          value={extraData?.[field]}
          onChange={e =>
            setExtraData({ ...extraData, [field]: e.target.value })
          }
        />
      </div>
    </div>
  )

  if (userType.includes("hr")) {
    return (
      <>
        {formField("organization")}

        {formField("department")}

        {formField("position")}
      </>
    )
  }

  if (userType.includes("fond")) {
    return <>{formField("position")}</>
  }

  if (userType.includes("edu")) {
    return (
      <>
        <div className="field">
          <div className="control">
            <label className="label">{t("auth.phone")}</label>
          </div>
        </div>

        <div className="field has-addons">
          <div className="control">
            <button className="button is-static">+7</button>
          </div>

          <div className="control is-expanded">
            <NumberFormat
              format="(###) ###-####"
              mask="_"
              placeholder="(985) 999-9999"
              className="input"
              value={extraData?.phone}
              onChange={e =>
                setExtraData({ ...extraData, phone: e.target.value })
              }
            />
          </div>
        </div>

        <div className="field">
          <div className="control">
            <label className="label">{t("auth.eduOrg")}</label>
            <Select
              options={eduOrgOptions}
              isLoading={eoLoad}
              value={selectedEduOrg}
              onChange={setSelectedEduOrg}
              placeholder={t("auth.eduOrgPlh")}
              isClearable
            />
          </div>
        </div>

        {formField("position")}
      </>
    )
  }

  if (userType.includes("prod")) {
    return (
      <>
        <div className="field">
          <div className="control">
            <label className="label">{t("auth.workplace")}</label>
            <Select
              options={workplaceOptions}
              value={selectedWorkplaces}
              onChange={setSelectedWorkplaces}
              placeholder={t("auth.workplacePlh")}
              isLoading={wpLoad}
              isMulti
              isClearable
            />
          </div>
        </div>
      </>
    )
  }

  if (userType === "student") {
    return (
      <>
        <div className="field">
          <div className="control">
            <label className="label">{t("auth.gender")}</label>
            <Select
              options={genderOptions}
              value={selectedGender}
              onChange={setSelectedGender}
              placeholder={t("auth.genderPlh")}
              isClearable
            />
          </div>
        </div>

        <div className="field">
          <div className="control">
            <label className="label">{t("auth.phone")}</label>
          </div>
        </div>

        <div className="field has-addons">
          <div className="control">
            <button className="button is-static">+7</button>
          </div>

          <div className="control is-expanded">
            <NumberFormat
              format="(###) ###-####"
              mask="_"
              placeholder="(985) 999-9999"
              className="input"
              value={extraData?.phone}
              onChange={e =>
                setExtraData({ ...extraData, phone: e.target.value })
              }
            />
          </div>
        </div>

        <div className="field">
          <div className="control">
            <label className="label">{t("auth.eduOrg")}</label>
            <Select
              options={eduOrgOptions}
              isLoading={eoLoad}
              value={selectedEduOrg}
              onChange={setSelectedEduOrg}
              placeholder={t("auth.eduOrgPlh")}
              isClearable
            />
          </div>
        </div>

        {selectedEduOrg?.value !== "other" || formField("otherEduText")}

        <div className="field">
          <div className="control">
            <label className="label">{t("auth.speciality")}</label>
            <Select
              options={specialityOptions}
              isLoading={specLoad}
              value={selectedSpeciality}
              onChange={setSelectedSpeciality}
              placeholder={t("auth.specialityPlh")}
              isClearable
            />
          </div>
        </div>

        {selectedSpeciality?.value !== "other" ||
          formField("otherSpecialityText")}

        <div className="field">
          <div className="control">
            <label className="label">{t("auth.yearOfStudy")}</label>
            <Select
              options={yosOptions}
              value={selectedYearOfStudy}
              onChange={setSelectedYearOfStudy}
              placeholder={t("auth.yearOfStudyPlh")}
              isClearable
            />
          </div>
        </div>

        <div className="field">
          <div className="control">
            <label className="label">{t("auth.preferredContact")}</label>
            <Select
              options={preferredContactOptions}
              value={selectedPreferred}
              onChange={setSelectedPreferred}
              placeholder={t("auth.preferredContactPlh")}
              isClearable
            />
          </div>
        </div>
      </>
    )
  }

  return null
}

export default ExtraDataForm
