import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import PracticeTaskMessageList from "../../data/practice/PracticeTaskMessageList"
import PracticeTaskMessageNew from "../../data/practice/PracticeTaskMessageNew"
import useGetData from "../../hooks/useGetData"
import useListData from "../../hooks/useListData"
import LoadingSpinner from "../../util/LoadingSpinner"

const PracticeTaskPageView = () => {
  const { t } = useTranslation()
  const { taskID } = useParams()
  const {
    task,
    loading,
    refetch: refetchTask,
  } = useGetData("task", "/practiceTask", {
    taskID,
  })
  const {
    messages,
    loading: msgLoad,
    refetch: refetchMsg,
  } = useListData("messages", "/practiceTaskMessage", {
    practiceTaskID: taskID,
  })

  if (loading) return <LoadingSpinner />

  return (
    <>
      <div className="box">
        <p className="title is-5">
          {task?.title}&nbsp;
          {task?.status !== "closed" || (
            <span>[{t("practice.taskStatus.closed")}]</span>
          )}
        </p>
      </div>

      <div className="box">
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: task?.text }}
        />

        {task?.status !== "open" || (
          <>
            <hr />

            <PracticeTaskMessageNew
              refetchTask={refetchTask}
              refetchMsg={refetchMsg}
            />
          </>
        )}
      </div>

      {msgLoad ? (
        <LoadingSpinner />
      ) : (
        <PracticeTaskMessageList messages={messages} />
      )}
    </>
  )
}

export default PracticeTaskPageView
