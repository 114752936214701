import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { useCommonDataContext } from "../../hooks/CommonDataContext"

const PlanPreview = ({ plan }) => {
  const { t } = useTranslation()
  const { allUsersMap } = useCommonDataContext()

  const author = allUsersMap[plan?.authorID]

  return (
    <div className="box">
      <p className="title is-5">
        <Link to={"/r/plans/view/" + plan?.id}>{plan?.title}</Link>
      </p>

      <hr />

      <p>
        <strong>{t("plan.author")}: </strong>
        {author?.fullName}
      </p>

      <p>
        <strong>{t("plan.creationDate")}: </strong>
        {dayjs(plan?.creationDate).format("DD/MM/YYYY HH:mm")}
      </p>
    </div>
  )
}

export default PlanPreview
