import { useTranslation } from "react-i18next"
import useListData from "../../hooks/useListData"
import { nilObjectID } from "../../util"

const StudentExtraData = ({ profile }) => {
  const { t } = useTranslation()
  const { eduOrgsMap } = useListData("eduOrgs", "/thesaurus/eduOrg")
  const { specialitiesMap } = useListData(
    "specialities",
    "/thesaurus/speciality"
  )

  return (
    <>
      <hr />

      <p>
        <strong>{t("auth.phone")}: </strong>
        +7&nbsp;{profile?.extraData?.phone}
      </p>

      <hr />

      <p>
        <strong>{t("auth.eduOrg")}: </strong>
        {profile?.extraData?.eduOrgID !== nilObjectID
          ? eduOrgsMap[profile?.extraData?.eduOrgID]?.title
          : profile?.extraData?.otherEduText}
      </p>

      <hr />

      <p>
        <strong>{t("auth.speciality")}: </strong>
        {profile?.extraData?.specialityID !== nilObjectID
          ? specialitiesMap[profile?.extraData?.specialityID]?.title
          : profile?.extraData?.otherSpecialityText}
      </p>

      <hr />

      <p>
        <strong>{t("auth.yearOfStudy")}: </strong>
        {t(`auth.yos.${profile?.extraData?.yearOfStudy?.split(":::")[1]}`)}
      </p>
    </>
  )
}

const EduExtraData = ({ profile }) => {
  const { t } = useTranslation()
  const { eduOrgsMap } = useListData("eduOrgs", "/thesaurus/eduOrg")

  return (
    <>
      <hr />

      <p>
        <strong>{t("auth.phone")}: </strong>
        +7&nbsp;{profile?.extraData?.phone}
      </p>

      <hr />

      <p>
        <strong>{t("auth.eduOrg")}: </strong>
        {profile?.extraData?.eduOrgID !== nilObjectID
          ? eduOrgsMap[profile?.extraData?.eduOrgID]?.title
          : profile?.extraData?.otherEduText}
      </p>
    </>
  )
}

const ProdExtraData = ({ profile }) => {
  const { t } = useTranslation()
  const { workplacesMap } = useListData("workplaces", "/thesaurus/workplace")

  return (
    <>
      <hr />

      <p>
        <strong>{t("auth.workplace")}: </strong>
        {(profile?.extraData?.workplacesID || []).map(wp => (
          <>{workplacesMap[wp]?.title}&nbsp;</>
        ))}
      </p>
    </>
  )
}

const ExtraData = ({ profile }) => {
  if (profile?.userType === "student")
    return <StudentExtraData profile={profile} />

  if (profile?.userType?.includes("edu"))
    return <EduExtraData profile={profile} />

  if (profile?.userType?.includes("prod"))
    return <ProdExtraData profile={profile} />

  return null
}

export default ExtraData
