import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import Select from "react-select"
import { toast } from "react-toastify"
import { errorMessage } from "../../errors"
import { useDataModelContext } from "../../hooks/DataModelContext"
import useGetData from "../../hooks/useGetData"
import useListData from "../../hooks/useListData"
import { useUserContext } from "../../hooks/UserContext"
import { API_URL, nilObjectID, serverError } from "../../util"
import LoadingSpinner from "../../util/LoadingSpinner"

const PortfolioNew = () => {
  const {
    user: { userID },
  } = useUserContext()
  const { t } = useTranslation()
  const { refetch } = useDataModelContext()
  const { user, loading: uLoad } = useGetData("user", "/user", { userID })
  const {
    workplaces,
    workplacesMap,
    loading: wpLoad,
  } = useListData("workplaces", "/thesaurus/workplace")
  const { eduOrgsMap } = useListData("eduOrgs", "/thesaurus/eduOrg")
  const { regions } = useListData("regions", "/thesaurus/region")
  const { specialitiesMap } = useListData(
    "specialities",
    "/thesaurus/speciality"
  )

  const [submitting, setSubmitting] = useState(false)
  const [mode, setMode] = useState("view")

  const [selectedWorkplace, setSelectedWorkplace] = useState(null)
  const [selectedReason, setSelectedReason] = useState(null)
  const [isReadyToMove, setIsReadyToMove] = useState(false)
  const [hasMilitaryDep, setHasMilitaryDep] = useState(false)
  const [isInMilitaryDep, setIsInMilitaryDep] = useState(false)
  const [letter, setLetter] = useState("")

  const resetForm = () => {
    setMode("view")
    setSelectedWorkplace(null)
    setSelectedReason(null)
    setIsReadyToMove(false)
    setHasMilitaryDep(false)
    setIsInMilitaryDep(false)
    setLetter("")
  }

  const workplaceOptions = (workplaces || []).map(wp => ({
    value: wp?.id,
    label: wp?.title + " (" + wp?.branchTitle + ")",
  }))

  const reasonOptions = [
    { value: "prodInternship", label: t("portfolio.prodInternship") },
    { value: "diplomaInternship", label: t("portfolio.diplomaInternship") },
    { value: "employment", label: t("portfolio.employment") },
    { value: "other", label: t("portfolio.other") },
  ]

  const addPortfolio = async () => {
    setSubmitting(true)

    try {
      const data = {
        workplaceID: selectedWorkplace?.value,
        reason: selectedReason?.value,
        isReadyToMove,
        hasMilitaryDep,
        isInMilitaryDep,
        letter,
      }
      const resp = await axios.post(`${API_URL}/portfolio/add`, data)
      const { status, message } = resp.data

      setSubmitting(false)

      if (status === "failed") return toast.error(errorMessage(message))

      resetForm()
      refetch()
      toast.success(t("portfolio.addSuccess"))
    } catch (error) {
      setSubmitting(false)
      toast.error(serverError())
    }
  }

  if (mode === "view")
    return (
      <div className="box has-text-centered">
        <span
          className="is-size-5 pointer has-text-link"
          onClick={() => setMode("add")}
        >
          <FontAwesomeIcon icon="plus" />
          &nbsp;{t("portfolio.addPortfolio")}
        </span>
      </div>
    )

  if (uLoad) return <LoadingSpinner />

  const eduOrg =
    user?.extraData?.eduOrgID !== nilObjectID
      ? eduOrgsMap[user?.extraData?.eduOrgID]?.title
      : user?.extraData?.otherEduText

  const spec =
    user?.extraData?.specialityID !== nilObjectID
      ? specialitiesMap[user?.extraData?.specialityID]?.title
      : user?.extraData?.otherSpecialityText

  const yos = (user?.extraData?.yearOfStudy || "0000:::udf")?.split(":::")[1]

  const regionsMap = (regions || []).reduce((acc, el) => {
    acc[el?.code] = el?.title
    return acc
  }, {})

  return (
    <div className="box">
      <p className="title is-5">{t("portfolio.addPortfolio")}</p>

      <hr />

      <div className="columns">
        <div className="column is-third">
          <div className="field">
            <div className="control">
              <label className="label">{t("auth.fullName")}</label>
              <input
                type="text"
                className="input"
                value={user?.fullName}
                disabled
              />
            </div>
          </div>
        </div>

        <div className="column is-third">
          <div className="field">
            <div className="control">
              <label className="label">Email</label>
              <input
                type="text"
                className="input"
                value={user?.email}
                disabled
              />
            </div>
          </div>
        </div>

        <div className="column is-third">
          <div className="field">
            <div className="control">
              <label className="label">{t("auth.phone")}</label>
              <input
                type="text"
                className="input"
                value={"+7 " + user?.extraData?.phone}
                disabled
              />
            </div>
          </div>
        </div>
      </div>

      <div className="columns">
        <div className="column is-half">
          <div className="field">
            <div className="control">
              <label className="label">{t("auth.eduOrg")}</label>
              <input type="text" className="input" value={eduOrg} disabled />
            </div>
          </div>
        </div>

        <div className="column is-half">
          <div className="field">
            <div className="control">
              <label className="label">{t("auth.speciality")}</label>
              <input type="text" className="input" value={spec} disabled />
            </div>
          </div>
        </div>
      </div>

      <div className="columns">
        <div className="column is-third">
          <div className="field">
            <div className="control">
              <label className="label">{t("auth.yearOfStudy")}</label>
              <input
                type="text"
                className="input"
                value={t(`auth.yos.${yos}`)}
                disabled
              />
            </div>
          </div>
        </div>

        <div className="column is-third">
          <div className="field">
            <div className="control">
              <label className="label">{t("portfolio.reason")}</label>
              <Select
                placeholder={t("portfolio.reasonPlh")}
                options={reasonOptions}
                value={selectedReason}
                onChange={setSelectedReason}
                isClearable
              />
              {
                <p className="help">
                  {t("req")}
                  {selectedReason?.value !== "other" ||
                    ", " + t("portfolio.otherHelp")}
                </p>
              }
            </div>
          </div>
        </div>

        <div className="column is-third">
          <div className="field">
            <div className="control">
              <label className="label">
                {selectedReason?.value === "employment"
                  ? t("portfolio.workplace")
                  : t("portfolio.practice")}
              </label>
              <Select
                placeholder={
                  selectedReason?.value === "employment"
                    ? t("portfolio.workplacePlh")
                    : t("portfolio.practicePlh")
                }
                options={workplaceOptions}
                value={selectedWorkplace}
                onChange={setSelectedWorkplace}
                isLoading={wpLoad}
                isClearable
              />
              <p className="help">{t("req")}</p>
            </div>
          </div>
        </div>
      </div>

      {!selectedWorkplace?.value || (
        <div className="columns">
          <div className="column is-half">
            <div className="field">
              <div className="control">
                <label className="label">{t("thesaurus.region")}</label>
                <input
                  type="text"
                  className="input"
                  value={
                    regionsMap[
                      workplacesMap[selectedWorkplace?.value]?.regionCode
                    ]
                  }
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="column is-half">
            <div className="field">
              <div className="control">
                <label className="label">{t("thesaurus.city")}</label>
                <input
                  type="text"
                  className="input"
                  value={workplacesMap[selectedWorkplace?.value]?.city}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="columns">
        <div className="column is-4">
          <div className="field pt-2">
            <label className="switch is-rounded">
              <input
                type="checkbox"
                value="false"
                checked={isReadyToMove}
                onChange={e => setIsReadyToMove(e.target.checked)}
              />
              <span className="check is-link"></span>
              &nbsp; {t("portfolio.isReadyToMove")}
            </label>
          </div>
        </div>

        <div className="column is-4 has-text-centered">
          {user?.extraData?.gender !== "male" || (
            <div className="field pt-2">
              <label className="switch is-rounded">
                <input
                  type="checkbox"
                  value="false"
                  checked={hasMilitaryDep}
                  onChange={e => setHasMilitaryDep(e.target.checked)}
                />
                <span className="check is-link"></span>
                &nbsp; {t("portfolio.hasMilitaryDep")}
              </label>
            </div>
          )}
        </div>

        <div className="column is-4 has-text-right">
          {!hasMilitaryDep || (
            <div className="field pt-2">
              <label className="switch is-rounded">
                <input
                  type="checkbox"
                  value="false"
                  checked={isInMilitaryDep}
                  onChange={e => setIsInMilitaryDep(e.target.checked)}
                />
                <span className="check is-link"></span>
                &nbsp; {t("portfolio.isInMilitaryDep")}
              </label>
            </div>
          )}
        </div>
      </div>

      <div className="field">
        <div className="control">
          <label className="label">{t("portfolio.letter")}</label>

          <textarea
            className="textarea"
            value={letter}
            onChange={e => setLetter(e.target.value)}
            placeholder={t("portfolio.letterPlh")}
          />

          <p
            className={
              "help" + (letter?.length > 500 ? " has-text-danger" : "")
            }
          >
            {letter?.length}/500&nbsp;{t("symbolsHelp")}
          </p>
        </div>
      </div>

      <div className="field is-grouped is-grouped-right">
        <p className="control">
          <button
            className="button is-light"
            onClick={resetForm}
            disabled={submitting}
          >
            {t("cancel")}
          </button>
        </p>

        <p className="control">
          <button
            className="button is-primary"
            onClick={addPortfolio}
            disabled={submitting}
          >
            {t("add")}
          </button>
        </p>
      </div>
    </div>
  )
}

export default PortfolioNew
