import { Link, useRouteMatch } from "react-router-dom"

const ChooseUserTypeView = () => {
  const { url } = useRouteMatch()

  return (
    <>
      {/* {Object.entries(userDivisions[i18next.language]).map(([val, name]) => (
        <div key={val}>
          <p className="has-text-centered title is-6">{name}</p>
          <div className="columns is-multiline">
            {userTypeOptions[i18next.language]
              .filter(uto => uto.value.includes(val))
              .map(ut => (
                <div className="column" key={ut.value}>
                  <Link
                    to={url + "/" + ut?.value}
                    className="button is-small is-primary is-outlined is-fullwidth"
                  >
                    {ut?.label}
                  </Link>
                </div>
              ))}
          </div>
          <hr />
        </div>
      ))} */}

      <div className="columns">
        <div className="column is-6 is-offset-3">
          <div className="field">
            <div className="control">
              <Link
                to={url + "/hrLeader"}
                className="button is-small is-primary is-outlined is-fullwidth"
              >
                Рабочее место HR
              </Link>
            </div>
          </div>

          <div className="field">
            <div className="control">
              <Link
                to={url + "/fondLeader"}
                className="button is-small is-primary is-outlined is-fullwidth"
              >
                Рабочее место департамента образовательных проектов
              </Link>
            </div>
          </div>

          <div className="field">
            <div className="control">
              <Link
                to={url + "/prodLeader"}
                className="button is-small is-primary is-outlined is-fullwidth"
              >
                Рабочее место учебного центра
              </Link>
            </div>
          </div>

          <div className="field">
            <div className="control">
              <Link
                to={url + "/prodSenior"}
                className="button is-small is-primary is-outlined is-fullwidth"
              >
                Рабочее место руководителя практики от производства
              </Link>
            </div>
          </div>

          <div className="field">
            <div className="control">
              <Link
                to={url + "/eduLeader"}
                className="button is-small is-primary is-outlined is-fullwidth"
              >
                Рабочее место образовательного учреждения
              </Link>
            </div>
          </div>

          <div className="field">
            <div className="control">
              <Link
                to={url + "/eduCorpUni"}
                className="button is-small is-primary is-outlined is-fullwidth"
              >
                Рабочее место корпоративного университета
              </Link>
            </div>
          </div>

          <div className="field">
            <div className="control">
              <Link
                to={url + "/student"}
                className="button is-small is-primary is-outlined is-fullwidth"
              >
                Рабочее место студента
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/*        <div className="column">
          <Link
          to={url + "/admin"}
          className="button is-small is-primary is-outlined is-fullwidth"
          >
            {userTypes[i18next.language]?.admin}
          </Link>
        </div> */}
    </>
  )
}

export default ChooseUserTypeView
