import axios from "axios"
import { useState } from "react"
import { toast } from "react-toastify"
import { errorMessage } from "../../errors"
import { useDataModelContext } from "../../hooks/DataModelContext"
import { useUserContext } from "../../hooks/UserContext"
import { API_URL, serverError } from "../../util"

const SuccessRateTag = ({
  entryID,
  entryType,
  currentVal,
  forTable = false,
}) => {
  const {
    user: { userType },
  } = useUserContext()
  const { refetch } = useDataModelContext()

  const [newValue, setNewValue] = useState(currentVal)
  const [mode, setMode] = useState("view")

  const updateSR = async () => {
    if (currentVal === Number(newValue)) return setMode("view")

    try {
      const resp = await axios.post(`${API_URL}/thesaurus/updateSuccessRate`, {
        entryID,
        entryType,
        newValue: Number(newValue),
      })

      const { status, message } = resp.data
      if (status === "ok") {
        refetch()
        return setMode("view")
      }

      toast.error(errorMessage(message))
    } catch (error) {
      toast.error(serverError())
    }
  }

  if (mode === "view")
    return (
      <button
        className={
          "button is-small is-light" +
          (userType === "admin" ? "" : " is-static")
        }
        onClick={() => setMode("edit")}
      >
        {currentVal}
      </button>
    )

  return (
    <div className="columns">
      <div className={"column" + (forTable ? " is-12" : " is-2")}>
        <div className="field">
          <p className="control">
            <input
              type="text"
              className="input is-small"
              value={newValue}
              onChange={e => setNewValue(e.target.value)}
              onBlur={updateSR}
              autoFocus
            />
          </p>
        </div>
      </div>
    </div>
  )
}

export default SuccessRateTag
