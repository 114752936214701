import dayjs from "dayjs"
import i18next from "i18next"
import { useTranslation } from "react-i18next"
import { useInviteContext } from "../../hooks/InviteContext"
import { userTypes } from "../../util"
import EmptyList from "../../util/EmptyList"
import LoadingSpinner from "../../util/LoadingSpinner"

const InviteList = () => {
  const { t } = useTranslation()
  const { invites, loading } = useInviteContext()

  if (loading) return <LoadingSpinner />

  if (invites.length === 0) return <EmptyList />

  return (
    <div className="box table-container">
      <table className="table is-fullwidth">
        <thead>
          <tr>
            <th>{t("invite.code")}</th>
            <th>{t("invite.userType")}</th>
            <th>Email</th>
            <th>{t("invite.date")}</th>
            <th className="has-text-centered">{t("invite.used")}</th>
          </tr>
        </thead>

        <tbody>
          {invites.map(invite => (
            <tr key={invite.id}>
              <th>{invite.code}</th>
              <td>{userTypes[i18next.language][invite.userType]}</td>
              <td>
                {invite.email ? (
                  invite.email
                ) : (
                  <span className="has-text-grey">no email</span>
                )}
              </td>
              <td>{dayjs(invite.date).format("DD/MM/YYYY HH:mm")}</td>
              <td className="has-text-centered">
                {!invite.isActive ? "да" : "нет"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default InviteList
