import Select from "react-select"
import { useTranslation } from "react-i18next"
import { useDataModelContext } from "../../hooks/DataModelContext"
import { useState } from "react"

const vars = ["dateInc", "dateDec"]

const gradeStyle = grade => {
  if (grade < 3) {
    return "danger"
  } else if (grade < 4) {
    return "warning"
  } else if (grade < 4.5) {
    return "link"
  } else {
    return "success"
  }
}

const PracticeStatSelect = ({ status, setStatus }) => {
  const {
    fullData: { stats },
  } = useDataModelContext()
  const { t } = useTranslation()

  const sortOptions = vars.map(sortVar => ({
    value: sortVar,
    label: t(sortVar),
  }))
  const [selectedSort, setSelectedSort] = useState(sortOptions[0])

  const meanGrade = Number(stats?.meanGrade)

  return (
    <>
      <div className="box">
        <nav className="level">
          <div className="level-item has-text-centered">
            <div>
              <p className="heading">{t("practice.allCnt")}</p>
              <p className="title">{stats?.all}</p>
            </div>
          </div>

          <div className="level-item has-text-centered">
            <div>
              <p className="heading">{t("practice.openCnt")}</p>
              <p
                className={"title" + (stats?.open > 0 ? " has-text-link" : "")}
              >
                {stats?.open}
              </p>
            </div>
          </div>

          <div className="level-item has-text-centered">
            <div>
              <p className="heading">{t("practice.overdueCnt")}</p>
              <p
                className={
                  "title" + (stats?.overdue > 0 ? " has-text-danger" : "")
                }
              >
                {stats?.overdue}
              </p>
            </div>
          </div>

          <div className="level-item has-text-centered">
            <div>
              <p className="heading">{t("practice.closedCnt")}</p>
              <p className="title has-text-grey">{stats?.closed}</p>
            </div>
          </div>

          <div className="level-item has-text-centered">
            <div>
              <p className="heading">{t("practice.meanGrade")}</p>
              <p className={"title has-text-" + gradeStyle(meanGrade)}>
                {meanGrade === -1 ? "N/A" : meanGrade.toFixed(2)}
              </p>
            </div>
          </div>
        </nav>
      </div>

      <div className="columns">
        <div className="column is-8">
          <div className="field has-addons">
            <div className="control">
              <button
                className={
                  "is-lowercase button " + (status === "open" ? "is-link" : "")
                }
                onClick={() => setStatus("open")}
              >
                {t("practice.open")}
              </button>
            </div>

            <div className="control">
              <button
                className={
                  "is-lowercase button " +
                  (status === "closed" ? "is-link" : "")
                }
                onClick={() => setStatus("closed")}
              >
                {t("practice.closed")}
              </button>
            </div>

            <div className="control">
              <button
                className={
                  "is-lowercase button " + (status === "" ? "is-link" : "")
                }
                onClick={() => setStatus("")}
              >
                {t("practice.all")}
              </button>
            </div>
          </div>
        </div>

        <div className="column is-4 has-text-right">
          <Select
            options={sortOptions}
            value={selectedSort}
            onChange={setSelectedSort}
          />
        </div>
      </div>
    </>
  )
}

export default PracticeStatSelect
