import i18next from "i18next"
import { useLocation } from "react-router-dom"

export function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export const taRows = field =>
  Math.min(5, Math.max(2, field.split("\n").length))

export const nilObjectID = "000000000000000000000000"

export const serverError = () =>
  ({
    ru: "Ошибка сервера. Проверьте наличие подключения к интернету и попробуйте перезагрузить страницу",
  }[i18next.language])

export const API_URL = process.env.REACT_APP_API_URL

export const userDivisions = {
  ru: {
    hr: "HR",
    fond: "Департамент образовательных проектов/Фонд",
    prod: "Производство",
    edu: "Образовательные учреждения",
  },
}

export const userTypes = {
  ru: {
    hrLeader: "HR дивизиона",
    hrSenior: "HR филиала",
    hrJunior: "HR предприятия",
    fondLeader: "Руководство Департамента/Фонда",
    fondSenior: "Специалист Департамента",
    fondJunior: "Специалист Фонда",
    prodLeader: "Специалист учебного центра",
    prodSenior: "Руководитель практики",
    prodJunior: "Руководитель учебного центра",
    eduLeader: "Руководство ОУ",
    eduSenior: "Старший специалист ОУ",
    eduJunior: "Младший специалист ОУ",
    eduCorpUni: "Работник корпоративного университета",
    student: "Студент",
    admin: "Администратор",
  },
}

export const userTypeOptions = Object.keys(userTypes).reduce((acc, lang) => {
  acc[lang] = Object.entries(userTypes[lang]).map(([ut, label]) => ({
    value: ut,
    label,
  }))

  return acc
}, {})

export const defaultSourceCode = `def compute(need, regionRate, wpRate, specRate, specYears):
    need["yearOfEmployment"] = int(need["yearOfEmployment"]) - specYears
    need["numberOfEngaged"] = float(need["numberOfEngaged"]) * (1 / regionRate) * (1 / wpRate) * (1 / specRate)

    return need
`

export const monthOptions = t => [
  { value: 1, label: t("month.1") },
  { value: 2, label: t("month.2") },
  { value: 3, label: t("month.3") },
  { value: 4, label: t("month.4") },
  { value: 5, label: t("month.5") },
  { value: 6, label: t("month.6") },
  { value: 7, label: t("month.7") },
  { value: 8, label: t("month.8") },
  { value: 9, label: t("month.9") },
  { value: 10, label: t("month.10") },
  { value: 11, label: t("month.11") },
  { value: 12, label: t("month.12") },
]

export const yearOptions = () => {
  const curentYear = new Date().getFullYear()

  return [...Array(20).keys()].map(y => ({
    value: y + curentYear + 1,
    label: y + curentYear + 1,
  }))
}
