import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useCommonDataContext } from "../../hooks/CommonDataContext"
import { useDataModelContext } from "../../hooks/DataModelContext"
import { useUserContext } from "../../hooks/UserContext"
import EmptyList from "../../util/EmptyList"
import LoadingSpinner from "../../util/LoadingSpinner"
import SuccessRateTag from "./SuccessRateTag"

const EduOrgList = () => {
  const { t } = useTranslation()
  const { allUsersMap } = useCommonDataContext()
  const {
    user: { userType },
  } = useUserContext()
  const { eduOrgs, loading } = useDataModelContext()

  if (loading) return <LoadingSpinner />

  if (eduOrgs.length === 0) return <EmptyList />

  const taRows = field => Math.min(5, Math.max(2, field.split("\n").length))

  return eduOrgs.map(spec => {
    const author = allUsersMap[spec.authorID]?.fullName

    return (
      <div className="box" key={spec.id}>
        <p className="title is-5">{spec?.title}</p>

        <div className="columns">
          <div className="column is-half">
            <p>
              <strong>{t("thesaurus.address")}: </strong>
              {spec?.address}
            </p>

            <p>
              <strong>{t("thesaurus.date")}: </strong>
              {dayjs(spec?.creationDate).format("DD/MM/YYYY HH:mm")}
            </p>
          </div>

          {userType !== "admin" || (
            <div className="column is-half">
              <p>
                <strong>{t("thesaurus.author")}: </strong>
                {author}
              </p>
            </div>
          )}
        </div>

        {!spec?.contacts || (
          <div className="field">
            <p className="control">
              <textarea
                className="textarea"
                value={spec?.contacts}
                rows={taRows(spec?.contacts)}
                disabled
              />
            </p>
          </div>
        )}

        {!spec?.description || (
          <div className="field">
            <p className="control">
              <textarea
                className="textarea"
                value={spec?.description}
                rows={taRows(spec?.description)}
                disabled
              />
            </p>
          </div>
        )}

        <hr className="mt-0 mb-3" />
        <div className="columns">
          <div className="column is-6">
            <SuccessRateTag
              entryID={spec.id}
              entryType="eduOrg"
              currentVal={spec.successRate}
            />
          </div>

          <div className="column is-6 has-text-right has-text-grey">
            {t(`thesaurus.${spec?.eduOrgType}`)}
          </div>
        </div>
      </div>
    )
  })
}

export default EduOrgList
