import axios from "axios"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { errorMessage } from "../../errors"
import { serverError, API_URL } from "../../util"
import ExtraDataForm from "./ExtraDataForm"

const SignUpForm = ({ userType, setMode }) => {
  const { t } = useTranslation()

  const [submitting, setSubmitting] = useState(false)

  const [inviteCode, setInviteCode] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [passwordRep, setPasswordRep] = useState("")
  const [name, setName] = useState("")
  const [surname, setSurname] = useState("")
  const [secondName, setSecondName] = useState("")
  const [noSecondName, setNoSecondName] = useState(false)
  const [personalDataAccept, setPersonalDataAccept] = useState(false)
  const [extraData, setExtraData] = useState({})

  const resetForm = () => {
    setInviteCode("")
    setEmail("")
    setPassword("")
    setPasswordRep("")
    setName("")
    setSurname("")
    setSecondName("")
  }

  const signUp = async event => {
    event.preventDefault()
    if (!noSecondName && secondName?.length === 0)
      return toast.error(errorMessage("user:::028"))

    setSubmitting(true)

    try {
      const data = {
        inviteCode,
        userType,
        email,
        password,
        passwordRep,
        fullName:
          `${surname?.trim()} ${name?.trim()}` +
          (noSecondName ? "" : ` ${secondName?.trim()}`),
        personalDataAccept,
        ...extraData,
      }

      const resp = await axios.post(`${API_URL}/user/add`, data)
      const { status, message } = resp.data
      setSubmitting(false)

      if (status === "failed") return toast.error(errorMessage(message))

      toast.success(
        t(`auth.signUpSuccess${userType === "student" ? "Student" : ""}`)
      )
      resetForm()
      setMode("signIn")
    } catch (error) {
      setSubmitting(false)
      toast.error(serverError())
    }
  }

  return (
    <>
      <form>
        {userType === "student" || (
          <div className="field">
            <label className="label">{t("auth.invite")}</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="xxxxxxxxxxxxx"
                value={inviteCode}
                onChange={e => setInviteCode(e.target.value)}
              />
            </div>
          </div>
        )}

        <div className="field">
          <label className="label">Email</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="ivanov@mail.com"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">{t("auth.password")}</label>
          <div className="control">
            <input
              className="input"
              type="password"
              placeholder="*******"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">{t("auth.passwordRep")}</label>
          <div className="control">
            <input
              className="input"
              type="password"
              placeholder="*******"
              value={passwordRep}
              onChange={e => setPasswordRep(e.target.value)}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">{t("auth.surname")}</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="Иванов"
              value={surname}
              onChange={e => setSurname(e.target.value)}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">{t("auth.name")}</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="Иван"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">{t("auth.secondName")}</label>
          {noSecondName || (
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Петрович"
                value={secondName}
                onChange={e => setSecondName(e.target.value)}
              />
            </div>
          )}
        </div>

        <div className="field">
          <div className="control">
            <label className="checkbox">
              <input
                type="checkbox"
                checked={noSecondName}
                onChange={e => setNoSecondName(e.target.checked)}
              />
              &nbsp;отчество отсутствует
            </label>
          </div>
        </div>

        <ExtraDataForm
          extraData={extraData}
          setExtraData={setExtraData}
          userType={userType}
        />

        <div className="field mt-5">
          <div className="control">
            <label className="checkbox">
              <input
                type="checkbox"
                value={personalDataAccept}
                onChange={e => setPersonalDataAccept(e.target.checked)}
              />
              &nbsp; {t("auth.fzCheck")}
            </label>
          </div>
        </div>

        <div className="field mt-5">
          <p className="control">
            <button
              className="button is-primary is-fullwidth"
              onClick={signUp}
              disabled={!personalDataAccept || submitting}
            >
              {t("auth.signUp")}
            </button>
          </p>
        </div>
      </form>
    </>
  )
}

export default SignUpForm
