import InviteList from "../../data/invite/InviteList"
import InviteNew from "../../data/invite/InviteNew"
import InviteContextProvider from "../../hooks/InviteContext"

const InviteView = () => {
  return (
    <InviteContextProvider>
      <InviteNew />

      <InviteList />
    </InviteContextProvider>
  )
}

export default InviteView
