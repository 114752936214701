import dayjs from "dayjs"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import ReactMarkdown from "react-markdown"
import { Route, Switch, useParams, useRouteMatch } from "react-router"
import { Link, useHistory } from "react-router-dom"
import NeedList from "../../data/plan/NeedList"
import NeedNew from "../../data/plan/NeedNew"
import { useCommonDataContext } from "../../hooks/CommonDataContext"
import { usePlanContext } from "../../hooks/PlanContext"
import { useUserContext } from "../../hooks/UserContext"
import LoadingSpinner from "../../util/LoadingSpinner"

const InfoView = ({ plan }) => {
  const { t } = useTranslation()
  const { allUsersMap, loading } = useCommonDataContext()

  if (loading) return <LoadingSpinner />

  const author = allUsersMap[plan?.authorID]
  const executors = (plan?.executorsID || []).map(
    execID => allUsersMap[execID]?.fullName
  )

  return (
    <>
      <div className="box">
        <p className="title is-5">{t("plan.info")}</p>

        <hr />

        <p>
          <strong>{t("plan.author")}: </strong>
          {author?.fullName}
        </p>

        <p>
          <strong>{t("plan.executors")}: </strong>
          {(executors || []).join(", ")}
        </p>

        <p>
          <strong>{t("plan.creationDate")}: </strong>
          {dayjs(plan?.creationDate).format("DD/MM/YYYY HH:mm")}
        </p>
      </div>

      {plan?.description ? (
        <div className="box">
          <p className="title is-5">{t("plan.description")}</p>
          <hr />
          <ReactMarkdown className="content">{plan?.description}</ReactMarkdown>
        </div>
      ) : null}
    </>
  )
}

const PlanInfo = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const { planID } = useParams()
  const { url, path } = useRouteMatch()
  const { switchCurrentPlan, currentPlan, loading } = usePlanContext()
  const {
    user: { userType },
  } = useUserContext()

  useEffect(() => {
    if (planID) switchCurrentPlan(planID)
  }, [planID, switchCurrentPlan])

  if (loading || !currentPlan) return <LoadingSpinner />

  return (
    <>
      <div className="box">
        <div className="columns">
          <div className="column is-3">
            <span
              className="pointer has-text-grey"
              onClick={() => {
                history.push("/r/plans")
              }}
            >
              {t("back")}
            </span>
          </div>

          <div className="column is-9 has-text-right">
            <p className="title is-4">{currentPlan?.title}</p>
          </div>
        </div>
      </div>

      <div className="columns">
        <div className="column is-3">
          <div className="box">
            <aside className="menu">
              <p className="menu-label">{t("plan.plan")}</p>
              <ul className="menu-list">
                <li>
                  <Link to={url}>{t("nav.info")}</Link>
                </li>

                {userType !== "hrJunior" || (
                  <li>
                    <Link to={url + "/addNeed"}>{t("nav.addNeed")}</Link>
                  </li>
                )}

                <li>
                  <Link to={url + "/needs"}>{t("nav.needs")}</Link>
                </li>
              </ul>
            </aside>
          </div>
        </div>

        <div className="column is-9">
          <Switch>
            <Route exact path={path}>
              <InfoView plan={currentPlan} />
            </Route>

            {userType !== "hrJunior" || (
              <Route exact path={path + "/addNeed"}>
                <NeedNew />
              </Route>
            )}

            <Route exact path={path + "/needs"}>
              <NeedList />
            </Route>
          </Switch>
        </div>
      </div>
    </>
  )
}

export default PlanInfo
