import { useTranslation } from "react-i18next"
import { useDataModelContext } from "../../hooks/DataModelContext"
import useListData from "../../hooks/useListData"
import { useUserContext } from "../../hooks/UserContext"
import EmptyList from "../../util/EmptyList"
import LoadingSpinner from "../../util/LoadingSpinner"
import Pagination from "../../util/Pagination"
import PortfolioPreview from "./PortfolioPreview"

const PortfolioList = () => {
  const { t } = useTranslation()
  const {
    user: { userType },
  } = useUserContext()
  const { usersMap, loading: uLoad } = useListData("users", "/user", {
    userType: "student",
  })
  const { workplacesMap } = useListData("workplaces", "/thesaurus/workplace")
  const { specialitiesMap } = useListData(
    "specialities",
    "/thesaurus/speciality"
  )
  const { eduOrgsMap } = useListData("eduOrgs", "/thesaurus/eduOrg")
  const {
    portfolios,
    loading: pLoad,
    currentPage,
    pageCount,
    setCurrentPage,
  } = useDataModelContext()

  if (uLoad || pLoad) return <LoadingSpinner />

  if (!portfolios?.length) return <EmptyList />

  return (
    <>
      {userType !== "prodLeader" || (
        <div className="message is-link">
          <div className="message-body">{t("portfolio.prodLeaderHint")}</div>
        </div>
      )}

      {portfolios.map(portfolio => {
        const student = usersMap[portfolio?.studentID]

        return (
          <PortfolioPreview
            className="box"
            key={portfolio?.id}
            portfolio={portfolio}
            student={student}
            workplace={workplacesMap[portfolio?.workplaceID]}
            speciality={specialitiesMap[student?.extraData?.specialityID]}
            eduOrg={eduOrgsMap[student?.extraData?.eduOrgID]}
          />
        )
      })}

      <Pagination
        page={currentPage}
        setPage={setCurrentPage}
        pageCount={pageCount}
      />
    </>
  )
}

export default PortfolioList
