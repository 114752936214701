import { Route, Switch, useRouteMatch } from "react-router"
import AuthLayout from "./AuthLayout"
import ChooseUserTypeView from "./ChooseUserTypeView"
import LoginView from "./LoginView"

const AuthView = () => {
  const { path } = useRouteMatch()

  return (
    <AuthLayout>
      <h3 className="title is-4 has-text-centered">
        Plan-&#x3e;Hire-&#x3e;Deploy
      </h3>

      <hr />

      <Switch>
        <Route exact path={path}>
          <ChooseUserTypeView />
        </Route>

        <Route path={path + "/:userType"}>
          <LoginView />
        </Route>
      </Switch>
    </AuthLayout>
  )
}

export default AuthView
