import i18n from "./i18n"

const ERRORS = {
  ru: {
    "sw:::000": "Ошибка сервера",
    "sw:::001": "Ошибка базы данных",
    "sw:::002": "Неправильный формат запроса",
    "sw:::003": "Недостаточно прав для данного действия",

    "model:::001":
      "Запрещено изменять сигнатуру функции и возвращаемое ей значение",
    "model:::002": "Слишком короткое (меньше 5 символов) описание изменений",

    "thesaurus:::001":
      "Невозможно утвердить запись. Она не существует, либо уже утверждена",
    "thesaurus:::002":
      "Невозможно отменить запись. Она не существует, либо уже утверждена",
    "thesaurus:::003": "Неверный формат csv файла с регионами",
    "thesaurus:::004": "Поле Наименование не может быть пустым",
    "thesaurus:::005":
      "Продолжительность обучения не может быть отрицательной или больше 25 лет",
    "thesaurus:::006": "Доля успеха должна быть от 0 до 1",
    "thesaurus:::007": "Необходимо выбрать регион",
    "thesaurus:::008": "Поле Код специальности не может быть пустым",
    "thesaurus:::009": "Поле Описание не может превышать 500 символов",
    "thesaurus:::010": "Необходимо выбрать тип специальности",
    "thesaurus:::011":
      "Необходимо выбрать степень взаимодействия с образовательным учреждением ",
    "thesaurus:::012": "Поле Адрес не может быть пустым",
    "thesaurus:::013": "Поле Контакты не может превышать 500 символов",
    "thesaurus:::014": "Необходимо выбрать хотя бы одну специальность",
    "thesaurus:::015":
      "Файл Excel должен содержать данные на листе с названием Лист1",
    "thesaurus:::016": "Поле Город не может быть пустым",
    "thesaurus:::017": "Специальность с таким кодом уже существует",
    "thesaurus:::018": "Необходимо выбрать дивизион",
    "thesaurus:::019": "Необходимо выбрать филиал",

    "user:::001":
      "Пользователь с таким email и типом пользователя уже существует",
    "user:::002": "Пароли не совпадают",
    "user:::003": "Пароль меньше 8 символов",
    "user:::004": "Несуществующий или использованный код приглашения",
    "user:::005": "Неправильный логин/пароль",
    "user:::006":
      "Поле email не может быть пустым или иметь неправильный формат",
    "user:::007": "Необходимо выбрать тип пользователя",
    "user:::008": "Поле Полное имя не может быть пустым",
    "user:::009": "Студенты регистрируются в системе без приглашений",
    "user:::010": "Поле Организация не может быть пустым",
    "user:::011": "Поле Подразделение не может быть пустым",
    "user:::012": "Поле Должность не может быть пустым",
    "user:::013":
      "Поле Телефон не может быть пустым или иметь неправильный формат",
    "user:::014": "Поле Курс обучения не может быть пустым",
    "user:::015": "Поле Предпочитаемый способ связи не может быть пустым",
    "user:::016":
      "Необходимо выбрать образовательное учреждение из списка, либо выбрать Нет в списке и указать название вручную",
    "user:::017":
      'Необходимо выбрать специальность из списка, либо выбрать Нет в списке и указать название вручную в формате "%КОД% %Наименование%" (формат как у пунктов в списке)',
    "user:::018": "Необходимо выбрать пол",
    "user:::019":
      "Εmail не подтверждён. Проверьте свою почту (включая папку спам)",
    "user:::020": "Недействительный код или учетная запись уже подтверждена",
    "user:::021":
      "Специальность должна быть в формате 00.00.00 Наименование специальности",
    "user:::022": "Неправильный старый пароль",
    "user:::023": "Необходимо выбрать образовательное учреждение из списка",
    "user:::024": "Пользователь не найден. Проверьте email и тип пользователя",
    "user:::025": "Неверный или уже использованный код сброса пароля",
    "user:::026":
      "Необходимо дать согласие на обработку, использование и распространение персональных данных",
    "user:::027": "Необходимо выбрать место работы (хотя бы одно)",
    "user:::028": "Необходимо указать отчество",

    "portfolio:::001": "Необходимо указать причину подачи портфолио",
    "portfolio:::002": "Необходимо выбрать желаемое место работы",
    "portfolio:::003": "Поле Комментарии должно быть не более 500 символов",
    "portfolio:::004":
      "Портфолио на данное место работы с такой причиной подачи уже создано",

    "plan:::001": "Название не может быть пустым",
    "plan:::002": "Необходимо добавить хотя бы одного исполнителя",

    "need:::001":
      "Нельзя создать потребность на 0 работников или с годом ранее текущего",
    "need:::002": "Необходимо выбрать специальность",
    "need:::003": "Необходимо выбрать место работы",
    "need:::004": "Необходимо выбрать дату выхода специалистов на работу",

    "group:::001": "Нельзя создать группу из 0 человек",
    "group:::002": "Нельзя создать группу без куратора от ОУ",

    "eduInfo:::001":
      "Год начала обучения не может быть меньше 2000 или больше 2100",
    "eduInfo:::002": "Продолжительность обучения не может быть меньше одного",
    "eduInfo:::003": "Необходимо выбрать формат обучения",
    "eduInfo:::004": "Число студентов должно быть неотрицательной величиной",
    "eduInfo:::005":
      "Поле Получаемые компетенции не может быть пустым или превышать 20000 символов",
    "eduInfo:::006":
      "Поле Получаемые дополнительные навыки не может превышать 20000 символов",
    "eduInfo:::007":
      "Необходимо выбрать специальность или выбрать пункт Прочее и указать новую в формате 00.00.00 Название специальности",

    "eduProgram:::001":
      "Поле наименование не может быть пустым или превышать 300 символов",
    "eduProgram:::002": "Количество часов не может быть отрицательным",

    "practice:::001":
      "Данному студенту уже назначена незавершенная практика на данном месте работы",
    "practice:::002": "Необходимо выбрать студента",
    "practice:::003": "Необходимо выбрать место работы",
    "practice:::004": "Необходимо выбрать руководителя практики",
    "practice:::005": "Необходимо указать цех, где будет проходить практика",
    "practice:::006": "Дата завершения практики должна быть позже даты начала",
    "practice:::007": "Необходимо указать дату начала и конца практики",
    "practice:::008": "Текст не может быть пустым",
    "practice:::009": "Необходимо указать срок выполенения задачи",
    "practice:::010":
      "Название задачи не может быть пустым или превышать 200 символов",
    "practice:::011":
      "Текст сообщения не может быть пустым или превышать 500 символов",
    "practice:::012": "Оценка должна быть в пределах от 0 до 10",
    "practice:::013": "Необходимо указать причину ограничений по здоровью",
    "practice:::014": "Необходимо указать причину отказа",
    "practice:::015": "Необходимо указать размер месячной оплаты",
  },
}

export const errorMessage = code => ERRORS[i18n.language][code]
