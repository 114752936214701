import { useTranslation } from "react-i18next"
import {
  Link,
  Switch,
  Route,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom"
import GroupList from "../../data/group/GroupList"
import GroupNew from "../../data/group/GroupNew"
import TaskComputations from "../../data/task/TaskComputations"
import TaskInfo from "../../data/task/TaskInfo"
import TaskStaff from "../../data/task/TaskStaff"
import { usePlanContext } from "../../hooks/PlanContext"
import { useUserContext } from "../../hooks/UserContext"
import useTask from "../../hooks/useTask"
import LoadingSpinner from "../../util/LoadingSpinner"

const TaskPage = () => {
  const { taskID } = useParams()
  const { t } = useTranslation()
  const { url } = useRouteMatch()
  const history = useHistory()
  const { plans } = usePlanContext()
  const {
    user: { userType },
  } = useUserContext()
  const { task, loading, refetch } = useTask(taskID)

  if (loading) return <LoadingSpinner />

  return (
    <>
      <div className="box">
        <div className="columns">
          <div className="column is-3">
            <span
              className="pointer has-text-grey"
              onClick={() => {
                history.push("/r/tasks")
              }}
            >
              {t("back")}
            </span>
          </div>

          <div className="column is-9 has-text-right">
            <p className="title is-5">
              {plans.find(plan => plan.id === task.planID)?.title}
            </p>
          </div>
        </div>
      </div>

      <div className="columns">
        <div className="column is-3">
          <aside className="menu box">
            <p className="menu-label">{t("plan.plan")}</p>
            <ul className="menu-list">
              <li>
                <Link to={url}>{t("nav.info")}</Link>
              </li>

              {userType !== "fondJunior" || (
                <li>
                  <Link to={url + "/addGroup"}>{t("nav.addGroup")}</Link>
                </li>
              )}

              <li>
                <Link to={url + "/groups"}>{t("nav.groups")}</Link>
              </li>
            </ul>
          </aside>
        </div>

        <div className="column is-9">
          <Switch>
            <Route exact path={url}>
              <TaskComputations computations={task?.computations || []} />

              <TaskStaff task={task} refetch={refetch} />

              <TaskInfo task={task} />
            </Route>

            {userType !== "fondJunior" || (
              <Route path={url + "/addGroup"}>
                <GroupNew task={task} />
              </Route>
            )}

            <Route path={url + "/groups"}>
              <GroupList task={task} />
            </Route>
          </Switch>
        </div>
      </div>
    </>
  )
}

export default TaskPage
