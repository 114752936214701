import axios from "axios"
import Select from "react-select"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { errorMessage } from "../../errors"
import { API_URL, serverError } from "../../util"
import { useDataModelContext } from "../../hooks/DataModelContext"
import useListData from "../../hooks/useListData"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const WorkplaceNew = () => {
  const { t } = useTranslation()
  const { refetch } = useDataModelContext()
  const { regions, loading } = useListData("regions", "/thesaurus/region")
  const { branchs, loading: brLoad } = useListData(
    "branchs",
    "/thesaurus/branch"
  )

  const [submitting, setSubmitting] = useState(false)
  const [mode, setMode] = useState("view")

  const [title, setTitle] = useState("")
  const [city, setCity] = useState("")
  const [selectedRegion, setSelectdRegion] = useState(null)
  const [selectedBranch, setSelectdBranch] = useState(null)
  const [successRate, setSuccessRate] = useState(0.85)

  const resetForm = () => {
    setMode("view")
    setTitle("")
    setSelectdRegion(null)
    setSuccessRate(0.85)
  }

  const addWorkplace = async () => {
    setSubmitting(true)

    try {
      const resp = await axios.post(`${API_URL}/thesaurus/workplace/add`, {
        title,
        city,
        regionCode: selectedRegion?.value,
        branchID: selectedBranch?.value,
        successRate: Number(successRate),
      })

      setSubmitting(false)
      const { status, message } = resp.data

      if (status === "failed") return toast.error(errorMessage(message))

      refetch()
      resetForm()
      toast.success(t("thesaurus.addWorkplaceSuccess"))
    } catch (error) {
      setSubmitting(false)
      toast.error(serverError())
    }
  }

  const regionsOptions = (regions || []).map(reg => ({
    value: reg.code,
    label: reg.title,
  }))

  const branchOptions = (branchs || []).map(br => ({
    value: br.id,
    label: br.title,
  }))

  if (mode === "view")
    return (
      <div className="box has-text-centered">
        <span
          className="is-size-5 pointer has-text-link"
          onClick={() => setMode("add")}
        >
          <FontAwesomeIcon icon="plus" />
          &nbsp;{t("thesaurus.addWorkplace")}
        </span>
      </div>
    )

  return (
    <div className="box">
      <p className="title is-5">{t("thesaurus.addWorkplace")}</p>

      <hr />

      <div className="columns">
        <div className="column is-9">
          <div className="field">
            <label className="label">{t("thesaurus.title")}</label>
            <input
              type="email"
              className="input"
              value={title}
              placeholder="Наименование места работы"
              onChange={e => setTitle(e.target.value)}
            />
          </div>
        </div>

        <div className="column is-3">
          <div className="field">
            <label className="label">{t("thesaurus.successRate")}</label>
            <input
              type="text"
              className="input"
              value={successRate}
              placeholder="Наименование специальности"
              onChange={e => setSuccessRate(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="columns">
        <div className="column is-4">
          <div className="field">
            <label className="label">{t("thesaurus.branch")}</label>
            <Select
              placeholder={t("thesaurus.branchPlh")}
              options={branchOptions}
              value={selectedBranch}
              onChange={setSelectdBranch}
              isLoading={brLoad}
              isClearable
            />
          </div>
        </div>

        <div className="column is-4">
          <div className="field">
            <label className="label">{t("thesaurus.region")}</label>
            <Select
              placeholder={t("thesaurus.regionPlh")}
              options={regionsOptions}
              value={selectedRegion}
              onChange={setSelectdRegion}
              isLoading={loading}
              isClearable
            />
          </div>
        </div>

        <div className="column is-4">
          <div className="field">
            <label className="label">{t("thesaurus.city")}</label>
            <input
              type="text"
              className="input"
              value={city}
              placeholder="Наименование города/населенного пункта"
              onChange={e => setCity(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="field is-grouped is-grouped-right">
        <p className="control">
          <button
            className="button is-light"
            onClick={resetForm}
            disabled={submitting}
          >
            {t("cancel")}
          </button>
        </p>

        <p className="control">
          <button
            className="button is-primary is-fullwidth"
            onClick={addWorkplace}
            disabled={submitting}
          >
            {t("add")}
          </button>
        </p>
      </div>
    </div>
  )
}

export default WorkplaceNew
