/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react"
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom"
import PracticeStatSelect from "../../data/practice/PracticeStatSelect"
import PracticeTaskList from "../../data/practice/PracticeTaskList"
import PracticeTaskNew from "../../data/practice/PracticeTaskNew"
import DataModelContextProvider from "../../hooks/DataModelContext"
import { useUserContext } from "../../hooks/UserContext"
import PracticeTaskPageView from "./PracticeTaskPageView"

const PracticeTasksView = () => {
  const {
    user: { userType },
  } = useUserContext()
  const { path } = useRouteMatch()
  const { practiceID } = useParams()

  const [status, setStatus] = useState("open")

  return (
    <DataModelContextProvider
      dataModelName="task"
      url="/practiceTask"
      query={{ practiceID, status }}
    >
      <Switch>
        <Route exact path={path}>
          {userType !== "prodSenior" || <PracticeTaskNew />}

          <PracticeStatSelect status={status} setStatus={setStatus} />

          <PracticeTaskList />
        </Route>

        <Route path={path + "/:taskID"}>
          <PracticeTaskPageView />
        </Route>
      </Switch>
    </DataModelContextProvider>
  )
}

export default PracticeTasksView
