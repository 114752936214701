import axios from "axios"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { errorMessage } from "../errors"
import { API_URL, serverError } from "../util"

const useWorkplaces = () => {
  const [workplaces, setWorkplaces] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState("")
  const [needRefetch, setNeedRefetch] = useState(true)

  useEffect(() => {
    const fetchWorkplaces = async () => {
      setLoading(true)

      try {
        const resp = await axios.get(`${API_URL}/thesaurus/workplace/list`)
        const { status, message, workplaces } = resp.data

        setLoading(false)
        if (status === "failed") return setError(errorMessage(message))

        setWorkplaces(workplaces)
      } catch (error) {
        setLoading(false)
        toast.error(serverError())
      }
    }

    if (needRefetch) {
      fetchWorkplaces()
      setNeedRefetch(false)
    }
  }, [needRefetch])

  const refetch = () => setNeedRefetch(true)

  return { workplaces, loading, error, refetch }
}

export default useWorkplaces
