import { usePlanContext } from "../../hooks/PlanContext"
import EmptyList from "../../util/EmptyList"
import LoadingSpinner from "../../util/LoadingSpinner"
import PlanPreview from "./PlanPreview"

const PlanList = () => {
  const { plans, loading } = usePlanContext()

  if (loading) return <LoadingSpinner />

  if (plans.length === 0) return <EmptyList />

  return plans.map(plan => <PlanPreview plan={plan} key={plan.id} />)
}

export default PlanList
