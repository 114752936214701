import { Suspense } from "react"
import { Redirect, Route, Switch, useLocation } from "react-router"
import CommonDataContextProvider from "./hooks/CommonDataContext"
import { useUserContext } from "./hooks/UserContext"
import Footer from "./util/Footer"
import AuthView from "./views/auth/AuthView"
import ForgotView from "./views/auth/ForgotView"
import VerifyView from "./views/auth/VerifyView"
import AuthorizedArea from "./views/AuthorizedArea"

const App = () => {
  const { isSigned } = useUserContext()
  const { pathname } = useLocation()

  return (
    <Suspense fallback="loading...">
      <div id="wrapper">
        <Switch>
          <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />

          <Route exact path="/">
            {!isSigned ? <Redirect to="/auth" /> : <Redirect to="/r" />}
          </Route>

          <Route path="/auth">
            {isSigned ? <Redirect to="/" /> : <AuthView />}
          </Route>

          <Route path="/verify">
            {isSigned ? <Redirect to="/" /> : <VerifyView />}
          </Route>

          <Route path="/reset">
            {isSigned ? <Redirect to="/" /> : <ForgotView />}
          </Route>

          <Route path="/r">
            {!isSigned ? (
              <Redirect to="/" />
            ) : (
              <CommonDataContextProvider>
                <AuthorizedArea />
              </CommonDataContextProvider>
            )}
          </Route>
        </Switch>
      </div>
      <Footer />
    </Suspense>
  )
}

export default App
