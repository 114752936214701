export const yosList = [
  "ba1",
  "ba2",
  "ba3",
  "ba4",
  "spec1",
  "spec2",
  "spec3",
  "spec4",
  "spec5",
  "ma1",
  "ma2",
  "col1",
  "col2",
  "col3",
  "col4",
]
