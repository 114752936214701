import axios from "axios"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import Select from "react-select"
import { toast } from "react-toastify"
import { errorMessage } from "../../errors"
import useGetData from "../../hooks/useGetData"
import useListData from "../../hooks/useListData"
import { API_URL, nilObjectID, serverError, useQuery } from "../../util"
import LoadingSpinner from "../../util/LoadingSpinner"
import PageTitle from "../../util/PageTitle"

const PracticeNew = () => {
  const { t } = useTranslation()
  const query = useQuery()
  const history = useHistory()
  const { users, loading: uLoad } = useListData("users", "/user", {
    userType: "prodSenior",
  })
  const { user: student, loading: stLoading } = useGetData("user", "/user", {
    userID: query.get("studentID"),
  })
  const { eduOrgsMap } = useListData("eduOrgs", "/thesaurus/eduOrg")
  const { workplacesMap } = useListData("workplaces", "/thesaurus/workplace")
  const { specialitiesMap } = useListData(
    "specialities",
    "/thesaurus/speciality"
  )
  const { portfolio, loading } = useGetData("portfolio", "/portfolio", {
    portfolioID: query.get("portfolioID"),
  })

  const [guild, setGuild] = useState("")
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [selectedLeader, setSelectedLeader] = useState(null)
  const [medStatus, setMedStatus] = useState("none")
  const [medReason, setMedReason] = useState("")
  const [payType, setPayType] = useState("nonPaid")
  const [paySize, setPaySize] = useState(0)
  const [paySizeHour, setPaySizeHour] = useState(0)

  if (loading) return <LoadingSpinner />

  const diffDays = Math.ceil(
    (new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24)
  )

  const prodSeniorsOptions = (users || [])
    .filter(user =>
      (user?.extraData?.workplacesID || []).includes(portfolio?.workplaceID)
    )
    .map(user => ({ value: user?.id, label: user?.fullName }))

  const addPractice = async () => {
    const parsedStart = new Date(startDate)
    const parsedEnd = new Date(endDate)

    if (
      // eslint-disable-next-line eqeqeq
      parsedStart == "Invalid Date" ||
      // eslint-disable-next-line eqeqeq
      parsedEnd == "Invalid Date" ||
      isNaN(parsedStart) ||
      isNaN(parsedEnd)
    )
      return toast.error(errorMessage("practice:::007"))

    try {
      const data = {
        portfolioID: portfolio?.id,
        leaderID: selectedLeader?.value,
        guild,
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        medReason,
        medStatus,
        payType,
        paySize: Number(paySize),
        paySizeHour: Number(paySizeHour),
      }

      const resp = await axios.post(`${API_URL}/practice/add`, data)
      const { status, message } = resp.data

      if (status === "failed") return toast.error(errorMessage(message))

      toast.success(t("practice.addSuccess"))
      history.push("/r/practice")
    } catch (err) {
      toast.error(serverError())
    }
  }

  return (
    <>
      <PageTitle>{t("practice.addNew")}</PageTitle>

      {!isNaN(diffDays) && diffDays < 7 ? (
        <div className="message is-warning">
          <div className="message-body">{t("practice.newShortWarning")}</div>
        </div>
      ) : null}

      <div className="box">
        <div className="columns">
          <div className="column is-half">
            <div className="field">
              <div className="control">
                <label className="label">{t("practice.studentName")}</label>
                <input
                  type="text"
                  className="input"
                  value={!stLoading ? student?.fullName : t("loading")}
                  disabled
                />
              </div>
            </div>

            <div className="field">
              <div className="control">
                <label className="label">Email</label>
                <input
                  type="email"
                  className="input"
                  value={!stLoading ? student?.email : t("loading")}
                  disabled
                />
              </div>
            </div>

            <div className="field">
              <div className="control">
                <label className="label">{t("auth.phone")}</label>
                <input
                  type="text"
                  className="input"
                  value={
                    !stLoading
                      ? "+7 " + student?.extraData?.phone
                      : t("loading")
                  }
                  disabled
                />
              </div>
            </div>

            <div className="field">
              <div className="control">
                <label className="label">{t("auth.eduOrg")}</label>
                <input
                  className="input"
                  value={
                    student?.extraData?.eduOrgID !== nilObjectID
                      ? eduOrgsMap[student?.extraData?.eduOrgID]?.title
                      : student?.extraData?.otherEduText
                  }
                  disabled
                />
              </div>
            </div>

            <div className="field">
              <div className="control">
                <label className="label">{t("auth.speciality")}</label>
                <input
                  className="input"
                  value={
                    student?.extraData?.specialityID !== nilObjectID
                      ? specialitiesMap[student?.extraData?.specialityID]?.title
                      : student?.extraData?.otherSpecialityText
                  }
                  disabled
                />
              </div>
            </div>

            <div className="field">
              <div className="control">
                <label className="label">{t("auth.yearOfStudy")}</label>
                <input
                  className="input"
                  value={t(
                    `auth.yos.${
                      student?.extraData?.yearOfStudy?.split(":::")[1]
                    }`
                  )}
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="column is-half">
            <div className="field">
              <div className="control">
                <label className="label">{t("practice.workplace")}</label>
                <input
                  type="text"
                  className="input"
                  value={
                    workplacesMap[portfolio?.workplaceID]?.title || t("loading")
                  }
                  disabled
                />
              </div>
            </div>

            <div className="field">
              <div className="control">
                <label className="label">{t("portfolio.reasonPreview")}</label>
                <input
                  type="text"
                  className="input"
                  value={t(`portfolio.${portfolio?.reason}`)}
                  disabled
                />
              </div>
            </div>

            <div className="field">
              <div className="control">
                <label className="label">{t("practice.leader")}</label>
                <Select
                  value={selectedLeader}
                  onChange={setSelectedLeader}
                  placeholder={t("practice.leaderPlh")}
                  options={prodSeniorsOptions}
                  isLoading={uLoad}
                  isClearable
                />
              </div>
            </div>

            <div className="field">
              <div className="control">
                <label className="label">{t("practice.guild")}</label>
                <input
                  value={guild}
                  onChange={e => setGuild(e.target.value)}
                  className="input"
                  type="text"
                  placeholder={t("practice.guildPlh")}
                />
              </div>
            </div>

            <div className="field">
              <div className="control">
                <label className="label">{t("practice.startDate")}</label>
                <input
                  value={startDate}
                  onChange={e => setStartDate(e.target.value)}
                  className="input"
                  type="date"
                />
              </div>
            </div>

            <div className="field">
              <div className="control">
                <label className="label">{t("practice.endDate")}</label>
                <input
                  value={endDate}
                  onChange={e => setEndDate(e.target.value)}
                  className="input"
                  type="date"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column is-half">
            <div className="field">
              <div className="control">
                <label className="label">{t("practice.medStatus")}</label>
              </div>
            </div>

            <div className="field has-addons">
              {["none", "fit", "partFit", "nonFit"].map(medVal => (
                <div className="control">
                  <button
                    className={
                      "button " +
                      (medStatus === medVal ? "is-link" : "is-light")
                    }
                    onClick={() => setMedStatus(medVal)}
                  >
                    {t(`practice.medStatuses.${medVal}`)}
                  </button>
                </div>
              ))}
            </div>

            {!["partFit", "nonFit"].includes(medStatus) || (
              <div className="field">
                <div className="control">
                  <label className="label">{t("practice.medReason")}</label>
                  <input
                    type="text"
                    className="input"
                    placeholder={t("practice.medReasonPlh")}
                    value={medReason}
                    onChange={e => setMedReason(e.target.value)}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="column is-half">
            <div className="field">
              <div className="control">
                <label className="label">{t("practice.payType")}</label>
              </div>
            </div>

            <div className="field has-addons">
              {["nonPaid", "paid"].map(payVal => (
                <div className="control">
                  <button
                    className={
                      "button " + (payType === payVal ? "is-link" : "is-light")
                    }
                    onClick={() => setPayType(payVal)}
                  >
                    {t(`practice.payTypes.${payVal}`)}
                  </button>
                </div>
              ))}
            </div>

            {payType !== "paid" || (
              <div style={{ display: "flex" }}>
                <div style={{ flex: 1 }}>
                  <div className="field">
                    <div className="control">
                      <label className="label">{t("practice.paySize")}</label>
                      <input
                        type="number"
                        className="input"
                        value={paySize}
                        onChange={e => setPaySize(e.target.value)}
                        disabled={paySizeHour !== 0}
                      />
                    </div>
                  </div>
                </div>

                <div className="divider is-vertical">{t("or")}</div>

                <div style={{ flex: 1 }}>
                  <div className="field">
                    <div className="control">
                      <label className="label">
                        {t("practice.paySizeHour")}
                      </label>
                      <input
                        type="number"
                        className="input"
                        value={paySizeHour}
                        onChange={e => setPaySizeHour(e.target.value)}
                        disabled={paySize !== 0}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="field is-grouped is-grouped-right">
          <div className="control">
            <button className="button is-link" onClick={addPractice}>
              {t("add")}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default PracticeNew
