import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDataModelContext } from "../../hooks/DataModelContext"
import { educationFormatOptions } from "./common"
import Select from "react-select"
import { toast } from "react-toastify"
import { API_URL, serverError } from "../../util"
import axios from "axios"
import { errorMessage } from "../../errors"

const EduSpecEdit = ({ es, specTitle, setMode }) => {
  const { t } = useTranslation()
  const { refetch } = useDataModelContext()

  const [startYear, setStartYear] = useState(es?.startYear)
  const [educationLength, setEducationLength] = useState(es?.educationLength)
  const [selectedEducationFormat, setSelectedEducationFormat] = useState(
    educationFormatOptions(t).find(opt => opt?.value === es?.educationFormat)
  )
  const [totalStudentsCount, setTotalStudentsCount] = useState(
    es?.totalStudentsCount
  )
  const [targetStudentsCount, setTargetStudentsCount] = useState(
    es?.targetStudentsCount
  )
  const [holdingStudentsCount, setHoldingStudentsCount] = useState(
    es?.holdingStudentsCount
  )
  const [competences, setCompetences] = useState(es?.competences)
  const [extraSkills, setExtraSkills] = useState(es?.extraSkills)

  const [sub, setSub] = useState(false)

  const updateEduSpec = async () => {
    setSub(true)

    try {
      const data = {
        id: es?.id,
        educationLength: Number(educationLength),
        educationFormat: selectedEducationFormat?.value,
        totalStudentsCount: Number(totalStudentsCount),
        targetStudentsCount: Number(targetStudentsCount),
        holdingStudentsCount: Number(holdingStudentsCount),
        competences,
        extraSkills,
      }
      const resp = await axios.post(`${API_URL}/eduSpec/update`, data)
      const { status, message } = resp.data
      setSub(false)

      if (status === "failed") return toast.error(errorMessage(message))

      refetch()
      setMode("view")
    } catch (error) {
      setSub(false)
      toast.error(serverError())
    }
  }

  return (
    <div className="box">
      <p className="title is-6">{specTitle}</p>

      <hr />

      <div className="columns">
        <div className="column is-third">
          <div className="field">
            <div className="control">
              <label className="label">{t("eduInfo.startYear")}</label>
              <input
                type="number"
                className="input"
                value={startYear}
                onChange={e => setStartYear(e.target.value)}
                disabled
              />
            </div>
          </div>
        </div>

        <div className="column is-third">
          <div className="field">
            <div className="control">
              <label className="label">{t("eduInfo.educationLength")}</label>
              <input
                type="number"
                className="input"
                value={educationLength}
                onChange={e => setEducationLength(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="column is-third">
          <div className="field">
            <div className="control">
              <label className="label">{t("eduInfo.educationFormat")}</label>
              <Select
                options={educationFormatOptions(t)}
                placeholder={t("eduInfo.educationFormatPlh")}
                value={selectedEducationFormat}
                onChange={setSelectedEducationFormat}
                isClearable
              />
            </div>
          </div>
        </div>
      </div>

      {/* Students Count */}

      <div className="columns">
        <div className="column is-third">
          <div className="field">
            <div className="control">
              <label className="label">{t("eduInfo.totalStudentsCount")}</label>
              <input
                type="number"
                className="input"
                value={totalStudentsCount}
                onChange={e => setTotalStudentsCount(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="column is-third">
          <div className="field">
            <div className="control">
              <label className="label">
                {t("eduInfo.targetStudentsCount")}
              </label>
              <input
                type="number"
                className="input"
                value={targetStudentsCount}
                onChange={e => setTargetStudentsCount(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="column is-third">
          <div className="field">
            <div className="control">
              <label className="label">
                {t("eduInfo.holdingStudentsCount")}
              </label>
              <input
                type="number"
                className="input"
                value={holdingStudentsCount}
                onChange={e => setHoldingStudentsCount(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Text fields */}
      <div className="field">
        <div className="control">
          <label className="label">{t("eduInfo.competences")}</label>
          <textarea
            className="textarea"
            placeholder={t("eduInfo.competencesPlh")}
            value={competences}
            onChange={e => setCompetences(e.target.value)}
          />
        </div>
      </div>

      <div className="field">
        <div className="control">
          <label className="label">{t("eduInfo.extraSkills")}</label>
          <textarea
            className="textarea"
            placeholder={t("eduInfo.extraSkillsPlh")}
            value={extraSkills}
            onChange={e => setExtraSkills(e.target.value)}
          />
        </div>
      </div>

      {/* Buttons */}

      <div className="field is-grouped is-grouped-right">
        <div className="control">
          <button
            className="button is-small is-light"
            onClick={() => setMode("view")}
            disabled={sub}
          >
            {t("cancel")}
          </button>
        </div>

        <div className="control">
          <button
            className="button is-small is-link"
            onClick={updateEduSpec}
            disabled={sub}
          >
            {t("edit")}
          </button>
        </div>
      </div>
    </div>
  )
}

export default EduSpecEdit
