import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useCommonDataContext } from "../../hooks/CommonDataContext"
import EmptyList from "../../util/EmptyList"

const PracticeTaskMessageList = ({ messages }) => {
  const { t } = useTranslation()
  const { allUsersMap } = useCommonDataContext()

  if (!messages?.length) return <EmptyList text={t("emptyMessageList")} />

  return (
    <>
      {(messages || []).map(msg => (
        <div className="message is-light" key={msg?.id}>
          <div className="message-header">
            <p>{allUsersMap[msg?.authorID]?.fullName}</p>

            <span>{dayjs(msg?.creationDate).format("DD/MM/YYYY HH:mm")}</span>
          </div>

          <div className="message-body" style={{ whiteSpace: "pre-wrap" }}>
            {msg?.text}
          </div>
        </div>
      ))}
    </>
  )
}

export default PracticeTaskMessageList
