import { createContext, useContext } from "react"
import useRegions from "./useRegions"
import useSpecialities from "./useSpecialities"
import useUsers from "./useUsers"
import useWorkplaces from "./useWorkplaces"

export const CommonDataContext = createContext()

const CommonDataContextProvider = ({ children }) => {
  const { users: admins, loadind: aLoading } = useUsers("admin")

  const { users: hrLeaders, loading: hrlLoading } = useUsers("hrLeader")
  const { users: hrSeniors, loading: hrsLoading } = useUsers("hrSenior")
  const { users: hrJuniors, loadind: hrjLoading } = useUsers("hrJunior")

  const { users: fondLeaders, loading: fondlLoading } = useUsers("fondLeader")
  const { users: fondSeniors, loading: fondsLoading } = useUsers("fondSenior")
  const { users: fondJuniors, loadind: fondjLoading } = useUsers("fondJunior")

  const { users: prodLeaders } = useUsers("prodLeader")
  const { users: prodSeniors } = useUsers("prodSenior")
  const { users: prodJuniors } = useUsers("fondJunior")

  const { users: eduLeaders, loading: edulLoading } = useUsers("eduLeader")

  const {
    specialities,
    loading: specLoading,
    refetch: specRefetch,
  } = useSpecialities()

  const {
    regions,
    loading: regionLoading,
    refetch: regionRefetch,
  } = useRegions()

  const {
    workplaces,
    loading: workplaceLoadind,
    refetch: workplaceRefetch,
  } = useWorkplaces()

  const allUsersMap = [
    ...admins,
    ...hrLeaders,
    ...hrSeniors,
    ...hrJuniors,
    ...fondLeaders,
    ...fondSeniors,
    ...fondJuniors,
    ...prodLeaders,
    ...prodSeniors,
    ...prodJuniors,
    ...eduLeaders,
  ].reduce((acc, el) => {
    acc[el.id] = el
    return acc
  }, {})

  const value = {
    admins,
    hrLeaders,
    hrJuniors,
    fondLeaders,
    fondSeniors,
    fondJuniors,
    eduLeaders,
    allUsersMap,
    specialities,
    regions,
    workplaces,
    loadind:
      aLoading ||
      hrlLoading ||
      hrsLoading ||
      hrjLoading ||
      fondlLoading ||
      fondsLoading ||
      fondjLoading ||
      edulLoading ||
      specLoading ||
      regionLoading ||
      workplaceLoadind,
    refetch: {
      specialities: specRefetch,
      regions: regionRefetch,
      workplaces: workplaceRefetch,
    },
  }

  return (
    <CommonDataContext.Provider value={value}>
      {children}
    </CommonDataContext.Provider>
  )
}

export const useCommonDataContext = () => {
  const context = useContext(CommonDataContext)

  return context
}

export default CommonDataContextProvider
