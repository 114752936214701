import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import { errorMessage } from "../../errors"
import { useDataModelContext } from "../../hooks/DataModelContext"
import { useUserContext } from "../../hooks/UserContext"
import { API_URL, serverError } from "../../util"
import PortfolioEdit from "./PortfolioEdit"

const preferredContact = preferred => (
  <span className="has-text-link">
    &nbsp;{!preferred || <FontAwesomeIcon icon="dot-circle" />}
  </span>
)

const PortfolioPreview = ({
  portfolio,
  student,
  workplace,
  speciality,
  eduOrg,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const {
    user: { userType },
  } = useUserContext()
  const { refetch } = useDataModelContext()

  const [pageMode, setPageMode] = useState("view")
  const [sub, setSub] = useState(false)

  const removePortfolio = async () => {
    setSub(true)

    try {
      const data = { portfolioID: portfolio?.id }
      const resp = await axios.post(`${API_URL}/portfolio/archive`, data)
      const { status, message } = resp.data
      setSub(false)

      if (status === "failed") return toast.error(errorMessage(message))

      refetch()
    } catch (error) {
      setSub(false)
      toast.error(serverError())
    }
  }

  if (pageMode === "remove")
    return (
      <div className="box">
        <p className="mb-3">
          {t("portfolio.removeAccept")}&nbsp;<strong>{portfolio?.id}</strong>
          &nbsp;
          {`(${workplace?.title}, ${t("portfolio." + portfolio?.reason)})?`}
        </p>

        <div className="field is-grouped is-grouped-right">
          <div className="control">
            <button
              className="button is-small is-light"
              onClick={() => setPageMode("view")}
              disabled={sub}
            >
              {t("cancel")}
            </button>
          </div>

          <div className="control">
            <button
              className="button is-small is-danger"
              onClick={removePortfolio}
              disabled={sub}
            >
              {t("remove")}
            </button>
          </div>
        </div>
      </div>
    )

  if (pageMode === "edit")
    return (
      <PortfolioEdit
        portfolio={portfolio}
        student={student}
        speciality={speciality}
        eduOrg={eduOrg}
        workplace={workplace}
        setPageMode={setPageMode}
      />
    )

  return (
    <div className="box">
      <p className="title is-6">
        {t("portfolio.portfolio")}&nbsp;{portfolio?.id}
      </p>

      <hr />

      <div className="columns">
        <div className="column is-half">
          <p>
            <strong>{t("auth.fullName")}:&nbsp;</strong>
            {student?.fullName}
          </p>

          <p>
            <strong>Email:&nbsp;</strong>
            <a href={"mailto:" + student?.email}>{student?.email}</a>
            {preferredContact(student?.extraData?.preferredContact === "email")}
          </p>

          <p>
            <strong>{t("auth.phone")}:&nbsp;</strong>
            +7&nbsp;{student?.extraData?.phone}
            {preferredContact(student?.extraData?.preferredContact === "phone")}
          </p>

          <p>
            <strong>{t("auth.speciality")}:&nbsp;</strong>
            {speciality?.code
              ? `${speciality?.code} ${speciality?.title}`
              : student?.extraData?.otherSpecialityText}
          </p>

          <p>
            <strong>{t("auth.eduOrg")}:&nbsp;</strong>
            {eduOrg?.title ? eduOrg?.title : student?.extraData?.otherEduText}
          </p>

          <p>
            <strong>{t("auth.yearOfStudy")}:&nbsp;</strong>
            {t(`auth.yos.${student?.extraData?.yearOfStudy?.split(":::")[1]}`)}
          </p>
        </div>

        <div className="column is-half">
          <p>
            <strong>{t("portfolio.workplace")}:&nbsp;</strong>
            {workplace?.title}&nbsp;({workplace?.branchTitle})
          </p>

          <p>
            <strong>{t("portfolio.reasonPreview")}:&nbsp;</strong>
            {t(`portfolio.${portfolio?.reason}`)}
          </p>

          <div className="field pt-2">
            <label className="switch is-rounded">
              <input
                type="checkbox"
                value="false"
                checked={portfolio?.isReadyToMove}
                onChange={() => null}
              />
              <span className="check is-link"></span>
              &nbsp; {t("portfolio.isReadyToMove")}
            </label>
          </div>

          {student?.extraData?.gender !== "male" || (
            <div className="field pt-2">
              <label className="switch is-rounded">
                <input
                  type="checkbox"
                  value="false"
                  checked={portfolio?.hasMilitaryDep}
                  onChange={() => null}
                />
                <span className="check is-link"></span>
                &nbsp; {t("portfolio.hasMilitaryDep")}
              </label>
            </div>
          )}

          {!portfolio?.hasMilitaryDep || (
            <div className="field pt-2">
              <label className="switch is-rounded">
                <input
                  type="checkbox"
                  value="false"
                  checked={portfolio?.isInMilitaryDep}
                  onChange={() => null}
                />
                <span className="check is-link"></span>
                &nbsp; {t("portfolio.isInMilitaryDep")}
              </label>
            </div>
          )}
        </div>
      </div>

      {!portfolio?.letter || (
        <div className="field">
          <div className="control">
            <textarea
              className="textarea"
              value={portfolio?.letter}
              rows={portfolio?.letter?.split("\n")?.length}
              disabled
            />
          </div>
        </div>
      )}

      {!(
        ["student", "admin"].includes(userType) &&
        portfolio?.status !== "hasPractice"
      ) || (
        <>
          <hr />
          <div className="columns">
            <div className="column is-half">
              {portfolio?.status !== "awaiting" || (
                <p>
                  <strong className="has-text-warning">
                    на рассмотрении предприятия
                  </strong>
                </p>
              )}
            </div>

            <div className="column is-half">
              <div className="field is-grouped is-grouped-right">
                {userType === "student" || (
                  <div className="control">
                    <button
                      onClick={() => setPageMode("edit")}
                      className="button is-small is-link"
                    >
                      {t("edit")}
                    </button>
                  </div>
                )}

                <div className="control">
                  <button
                    onClick={() => setPageMode("remove")}
                    className="button is-small is-danger"
                  >
                    {userType === "student" ? t("removeStudent") : t("remove")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {!(userType === "prodLeader" && portfolio?.status === "awaiting") || (
        <>
          <hr />
          <div className="field is-grouped is-grouped-right">
            <div className="control">
              <button
                className="button is-link"
                onClick={() =>
                  history.push(
                    `/r/practice/add?studentID=${portfolio?.studentID}&portfolioID=${portfolio?.id}`
                  )
                }
              >
                {t("practice.addOnPortfolio")}
              </button>
            </div>
          </div>
        </>
      )}

      {portfolio?.status !== "hasPractice" || (
        <>
          <hr />
          <p className="has-text-right">
            <strong className="has-text-link">назначена практика</strong>
          </p>
        </>
      )}
    </div>
  )
}

export default PortfolioPreview
