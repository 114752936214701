import { useState } from "react"
import { useTranslation } from "react-i18next"
import Select from "react-select"
import ReactMde from "react-mde"
import ReactMarkdown from "react-markdown"
import useUsers from "../../hooks/useUsers"
import { usePlanContext } from "../../hooks/PlanContext"
import { toast } from "react-toastify"
import { serverError } from "../../util"
import { useHistory } from "react-router"
import { useUserContext } from "../../hooks/UserContext"
import { errorMessage } from "../../errors"

const PlanNew = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const { users, loading } = useUsers("hrJunior")
  const { create } = usePlanContext()
  const {
    user: { userID },
  } = useUserContext()

  const [title, setTitle] = useState("")
  const [selectedExecutors, setSelectedExecutors] = useState(null)
  const [selectedTab, setSelectedTab] = useState("write")
  const [description, setDescription] = useState("")

  const executorsOptions = loading
    ? []
    : users.map(user => ({ value: user.id, label: user.fullName }))

  const resetForm = () => {
    setTitle("")
    setSelectedExecutors(null)
    setDescription("")
  }

  const addPlan = async e => {
    e.preventDefault()

    if (title === "") return toast.error(errorMessage("plan:::001"))

    if (!selectedExecutors) return toast.error(errorMessage("plan:::002"))

    try {
      const data = {
        title,
        description,
        executorsID: selectedExecutors.map(exec => exec.value),
        authorID: userID,
      }

      await create(data)
      history.push("/r/plans")
    } catch (error) {
      toast.error(serverError())
    }
  }

  return (
    <div className="box">
      <p className="title is-5">{t("plan.addNew")}</p>

      <hr />

      <form>
        <div className="columns">
          <div className="column is-half">
            <div className="field">
              <label className="label">{t("plan.title")}</label>
              <div className="control">
                <input
                  type="text"
                  className="input"
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                  placeholder={t("plan.titlePlaceholder")}
                />
              </div>
            </div>

            <div className="field">
              <label className="label">{t("plan.executors")}</label>
              <div className="control">
                <Select
                  placeholder={t("plan.executorsPlaceholder")}
                  options={executorsOptions}
                  value={selectedExecutors}
                  onChange={setSelectedExecutors}
                  isLoading={loading}
                  isMulti
                  isClearable
                />
              </div>
            </div>
          </div>

          <div className="column is-half">
            <div className="field">
              <label className="label">{t("plan.description")}</label>
              <div className="control">
                <ReactMde
                  value={description}
                  onChange={setDescription}
                  selectedTab={selectedTab}
                  onTabChange={setSelectedTab}
                  generateMarkdownPreview={markdown =>
                    Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <div className="field is-grouped is-grouped-right">
          <div className="control">
            <button className="button is-link is-outlined" onClick={resetForm}>
              {t("reset")}
            </button>
          </div>
          <div className="control">
            <button className="button is-link" onClick={addPlan}>
              {t("add")}
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default PlanNew
