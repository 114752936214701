import axios from "axios"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { errorMessage } from "../errors"
import { API_URL, serverError } from "../util"

const useTask = taskID => {
  const [task, setTask] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState("")
  const [needRefetch, setNeedRefetch] = useState(true)

  useEffect(() => {
    const fetchTask = async () => {
      try {
        const resp = await axios.get(`${API_URL}/task/get?taskID=${taskID}`)
        const { status, message, task } = resp.data

        setLoading(false)
        if (status === "failed") return setError(errorMessage(message))

        setTask(task)
      } catch (error) {
        setLoading(false)
        toast.error(serverError())
      }
    }

    fetchTask()
    setNeedRefetch(false)
  }, [needRefetch, taskID])

  const refetch = (needsReload = true) => {
    if (needsReload) setLoading(true)

    setNeedRefetch(true)
  }

  return { task, loading, error, refetch }
}

export default useTask
