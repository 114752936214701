import { createContext, useContext, useState } from "react"
import useModels from "./useModels"

export const ModelContext = createContext()

const ModelContextProvider = ({ children }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const { models, currentModel, loading, error, refetch, pageCount } =
    useModels(currentPage)

  const value = {
    models,
    currentModel,
    loading,
    error,
    refetch,
    currentPage,
    setCurrentPage,
    pageCount,
  }

  return <ModelContext.Provider value={value}>{children}</ModelContext.Provider>
}

export const useModelContext = () => {
  const context = useContext(ModelContext)

  return context
}

export default ModelContextProvider
