import { useTranslation } from "react-i18next"
import DivisionList from "../../data/thesaurus/DivisionList"
import DivisionNew from "../../data/thesaurus/DivisionNew"
import DataModelContextProvider from "../../hooks/DataModelContext"
import { useUserContext } from "../../hooks/UserContext"
import PageTitle from "../../util/PageTitle"

const DivisionView = () => {
  const { t } = useTranslation()
  const {
    user: { userType },
  } = useUserContext()

  return (
    <DataModelContextProvider
      dataModelName="division"
      url="/thesaurus/division"
    >
      <PageTitle>{t("thesaurus.divisions")}</PageTitle>

      {userType !== "admin" || <DivisionNew />}

      <DivisionList />
    </DataModelContextProvider>
  )
}

export default DivisionView
