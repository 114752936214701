import axios from "axios"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { errorMessage } from "../../errors"
import { useDataModelContext } from "../../hooks/DataModelContext"
import { API_URL, serverError } from "../../util"

const EduSpecRemove = ({ eduSpecTitle, eduSpecID, setMode }) => {
  const { t } = useTranslation()
  const { refetch } = useDataModelContext()

  const [sub, setSub] = useState(false)

  const removeEduSpec = async () => {
    setSub(true)

    try {
      const resp = await axios.post(`${API_URL}/eduSpec/remove`, { eduSpecID })
      const { status, message } = resp.data
      setSub(false)

      if (status === "failed") return toast.error(errorMessage(message))

      refetch()
    } catch (error) {
      setSub(false)
      toast.error(serverError())
    }
  }

  return (
    <div className="box">
      <p className="title is-6">{eduSpecTitle}</p>

      <hr />

      <p>{t("eduInfo.removeHint")}</p>

      <div className="field is-grouped is-grouped-right">
        <div className="control">
          <button
            className="button is-small is-light"
            onClick={() => setMode("view")}
            disabled={sub}
          >
            {t("cancel")}
          </button>
        </div>

        <div className="control">
          <button
            className="button is-small is-danger"
            onClick={removeEduSpec}
            disabled={sub}
          >
            {t("remove")}
          </button>
        </div>
      </div>
    </div>
  )
}

export default EduSpecRemove
