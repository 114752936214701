import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import NumberFormat from "react-number-format"
import Select from "react-select"
import { toast } from "react-toastify"
import { errorMessage } from "../../errors"
import { useDataModelContext } from "../../hooks/DataModelContext"
import useListData from "../../hooks/useListData"
import { useUserContext } from "../../hooks/UserContext"
import { API_URL, serverError } from "../../util"
import { yosList } from "../../yos"

const PortfolioStudentNew = () => {
  const { user } = useUserContext()
  const { t } = useTranslation()
  const { refetch } = useDataModelContext()
  const {
    workplaces,
    workplacesMap,
    loading: wpLoad,
  } = useListData("workplaces", "/thesaurus/workplace")
  const { eduOrgs } = useListData("eduOrgs", "/thesaurus/eduOrg")
  const { regions } = useListData("regions", "/thesaurus/region")
  const { specialities } = useListData("specialities", "/thesaurus/speciality")

  const [submitting, setSubmitting] = useState(false)
  const [mode, setMode] = useState("view")

  const [fullName, setFullName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [selectedGender, setSelectedGender] = useState(null)
  const [selectedEduOrg, setSelectedEduOrg] = useState(null)
  const [selectedSpeciality, setSelectedSpeciality] = useState(null)
  const [selectedYearOfStudy, setSelectedYearOfStudy] = useState(null)
  const [otherEduText, setOtherEduText] = useState("")
  const [otherSpecialityText, setOtherSpecialityText] = useState("")
  const [selectedWorkplace, setSelectedWorkplace] = useState(null)
  const [selectedReason, setSelectedReason] = useState(null)
  const [isReadyToMove, setIsReadyToMove] = useState(false)
  const [hasMilitaryDep, setHasMilitaryDep] = useState(false)
  const [isInMilitaryDep, setIsInMilitaryDep] = useState(false)
  const [letter, setLetter] = useState("")

  const resetForm = () => {
    setMode("view")
    setSelectedWorkplace(null)
    setSelectedReason(null)
    setIsReadyToMove(false)
    setHasMilitaryDep(false)
    setIsInMilitaryDep(false)
    setLetter("")
  }

  const otherOption = {
    value: "other",
    label: t("auth.other"),
  }
  const eduOrgOptions = [
    otherOption,
    ...(eduOrgs || []).map(eduOrg => ({
      value: eduOrg?.id,
      label: eduOrg?.title,
    })),
  ]

  const specialityOptions = [
    otherOption,
    ...(specialities || []).map(spec => ({
      value: spec?.id,
      label: `${spec?.code} ${spec?.title}`,
    })),
  ]

  const workplaceOptions = (workplaces || [])
    .filter(wp => (user?.extraData?.workplacesID || []).includes(wp?.id))
    .map(wp => ({
      value: wp?.id,
      label: wp?.title + " (" + wp?.branchTitle + ")",
    }))

  const yosOptions = yosList.map(yos => ({
    value: yos,
    label: t(`auth.yos.${yos}`),
  }))

  const reasonOptions = [
    { value: "prodInternship", label: t("portfolio.prodInternship") },
    { value: "diplomaInternship", label: t("portfolio.diplomaInternship") },
    { value: "employment", label: t("portfolio.employment") },
    { value: "other", label: t("portfolio.other") },
  ]

  const genderOptions = [
    { value: "male", label: "Мужской" },
    { value: "female", label: "Женский" },
  ]

  const currentYear = new Date().getFullYear()

  const addPortfolio = async () => {
    setSubmitting(true)

    try {
      const data = {
        fullName,
        email,
        phone,
        preferredContact: "phone",
        gender: selectedGender?.value,
        eduOrgID:
          selectedEduOrg?.value === "other" ? "" : selectedEduOrg?.value,
        specialityID:
          selectedSpeciality?.value === "other"
            ? ""
            : selectedSpeciality?.value,
        yearOfStudy: !selectedYearOfStudy?.value
          ? ""
          : `${currentYear}:::${selectedYearOfStudy?.value}`,
        otherEduText,
        otherSpecialityText,
        workplaceID: selectedWorkplace?.value,
        reason: selectedReason?.value,
        isReadyToMove,
        hasMilitaryDep,
        isInMilitaryDep,
        letter,
      }
      console.log(data)
      const resp = await axios.post(`${API_URL}/portfolio/addWithStudent`, data)
      const { status, message } = resp.data

      setSubmitting(false)

      if (status === "failed") return toast.error(errorMessage(message))

      resetForm()
      refetch()
      toast.success(t("portfolio.addSuccess"))
    } catch (error) {
      setSubmitting(false)
      toast.error(serverError())
    }
  }

  if (mode === "view")
    return (
      <div className="box has-text-centered">
        <span
          className="is-size-5 pointer has-text-link"
          onClick={() => setMode("add")}
        >
          <FontAwesomeIcon icon="plus" />
          &nbsp;{t("portfolio.addPortfolio")}
        </span>
      </div>
    )

  const regionsMap = (regions || []).reduce((acc, el) => {
    acc[el?.code] = el?.title
    return acc
  }, {})

  return (
    <div className="box">
      <p className="title is-5">{t("portfolio.addPortfolio")}</p>

      <hr />

      <div className="columns">
        <div className="column is-third">
          <div className="field">
            <div className="control">
              <label className="label">{t("auth.fullName")}</label>
              <input
                type="text"
                className="input"
                value={fullName}
                onChange={e => setFullName(e.target.value)}
                placeholder="Иванов Иван Петрович"
              />
            </div>
          </div>
        </div>

        <div className="column is-third">
          <div className="field">
            <div className="control">
              <label className="label">Email</label>
              <input
                type="text"
                className="input"
                value={email}
                onChange={e => setEmail(e.target.value)}
                placeholder="ivanov@mail.ru"
              />
            </div>
          </div>
        </div>

        <div className="column is-third">
          <div className="field">
            <div className="control">
              <label className="label">{t("auth.phone")}</label>
            </div>
          </div>

          <div className="field has-addons">
            <div className="control">
              <button className="button is-static">+7</button>
            </div>

            <div className="control is-expanded">
              <NumberFormat
                format="(###) ###-####"
                mask="_"
                placeholder="(985) 999-9999"
                className="input"
                value={phone}
                onChange={e => setPhone(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="columns">
        <div className="column is-half">
          <div className="field">
            <div className="control">
              <label className="label">{t("auth.eduOrg")}</label>
              <Select
                placeholder={t("auth.eduOrgPlh")}
                options={eduOrgOptions}
                value={selectedEduOrg}
                onChange={setSelectedEduOrg}
                isLoading={wpLoad}
                isClearable
              />
            </div>
          </div>
        </div>

        <div className="column is-half">
          <div className="field">
            <div className="control">
              <label className="label">{t("auth.speciality")}</label>
              <Select
                placeholder={t("auth.specialityPlh")}
                options={specialityOptions}
                value={selectedSpeciality}
                onChange={setSelectedSpeciality}
                isLoading={wpLoad}
                isClearable
              />
            </div>
          </div>
        </div>
      </div>

      <div className="columns">
        <div className="column is-half">
          {selectedEduOrg?.value !== "other" || (
            <div className="field">
              <label className="label">{t(`auth.otherEduText`)}</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder={t(`auth.otherEduTextPlh`)}
                  value={otherEduText}
                  onChange={e => setOtherEduText(e.target.value)}
                />
              </div>
            </div>
          )}
        </div>

        <div className="column is-half">
          {selectedSpeciality?.value !== "other" || (
            <div className="field">
              <label className="label">{t(`auth.otherSpecialityText`)}</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder={t(`auth.otherSpecialityTextPlh`)}
                  value={otherSpecialityText}
                  onChange={e => setOtherSpecialityText(e.target.value)}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="columns">
        <div className="column is-third">
          <div className="field">
            <div className="control">
              <label className="label">{t("auth.yearOfStudy")}</label>
              <Select
                placeholder={t("auth.yearOfStudyPlh")}
                options={yosOptions}
                value={selectedYearOfStudy}
                onChange={setSelectedYearOfStudy}
                isLoading={wpLoad}
                isClearable
              />
            </div>
          </div>
        </div>

        <div className="column is-third">
          <div className="field">
            <div className="control">
              <label className="label">{t("portfolio.workplace")}</label>
              <Select
                placeholder={t("portfolio.workplacePlh")}
                options={workplaceOptions}
                value={selectedWorkplace}
                onChange={setSelectedWorkplace}
                isLoading={wpLoad}
                isClearable
              />
              <p className="help">{t("req")}</p>
            </div>
          </div>
        </div>

        <div className="column is-third">
          <div className="field">
            <div className="control">
              <label className="label">{t("portfolio.reason")}</label>
              <Select
                placeholder={t("portfolio.reasonPlh")}
                options={reasonOptions}
                value={selectedReason}
                onChange={setSelectedReason}
                isClearable
              />
              {
                <p className="help">
                  {t("req")}
                  {selectedReason?.value !== "other" ||
                    ", " + t("portfolio.otherHelp")}
                </p>
              }
            </div>
          </div>
        </div>
      </div>

      {!selectedWorkplace?.value || (
        <div className="columns">
          <div className="column is-half">
            <div className="field">
              <div className="control">
                <label className="label">{t("thesaurus.region")}</label>
                <input
                  type="text"
                  className="input"
                  value={
                    regionsMap[
                      workplacesMap[selectedWorkplace?.value]?.regionCode
                    ]
                  }
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="column is-half">
            <div className="field">
              <div className="control">
                <label className="label">{t("thesaurus.city")}</label>
                <input
                  type="text"
                  className="input"
                  value={workplacesMap[selectedWorkplace?.value]?.city}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="columns">
        <div className="column is-3">
          <div className="field">
            <div className="control">
              <Select
                options={genderOptions}
                value={selectedGender}
                onChange={setSelectedGender}
                placeholder={t("auth.genderPlh")}
                isClearable
              />
            </div>
          </div>
        </div>

        <div className="column is-3">
          <div className="field pt-2">
            <label className="switch is-rounded">
              <input
                type="checkbox"
                value="false"
                checked={isReadyToMove}
                onChange={e => setIsReadyToMove(e.target.checked)}
              />
              <span className="check is-link"></span>
              &nbsp; {t("portfolio.isReadyToMove")}
            </label>
          </div>
        </div>

        <div className="column is-3 has-text-centered">
          {selectedGender?.value !== "male" || (
            <div className="field pt-2">
              <label className="switch is-rounded">
                <input
                  type="checkbox"
                  value="false"
                  checked={hasMilitaryDep}
                  onChange={e => setHasMilitaryDep(e.target.checked)}
                />
                <span className="check is-link"></span>
                &nbsp; {t("portfolio.hasMilitaryDep")}
              </label>
            </div>
          )}
        </div>

        <div className="column is-3 has-text-right">
          {!hasMilitaryDep || (
            <div className="field pt-2">
              <label className="switch is-rounded">
                <input
                  type="checkbox"
                  value="false"
                  checked={isInMilitaryDep}
                  onChange={e => setIsInMilitaryDep(e.target.checked)}
                />
                <span className="check is-link"></span>
                &nbsp; {t("portfolio.isInMilitaryDepHe")}
              </label>
            </div>
          )}
        </div>
      </div>

      <div className="field">
        <div className="control">
          <label className="label">{t("portfolio.letter")}</label>

          <textarea
            className="textarea"
            value={letter}
            onChange={e => setLetter(e.target.value)}
            placeholder={t("portfolio.letterPlh")}
          />

          <p
            className={
              "help" + (letter?.length > 500 ? " has-text-danger" : "")
            }
          >
            {letter?.length}/500&nbsp;{t("symbolsHelp")}
          </p>
        </div>
      </div>

      <div className="field is-grouped is-grouped-right">
        <p className="control">
          <button
            className="button is-light"
            onClick={resetForm}
            disabled={submitting}
          >
            {t("cancel")}
          </button>
        </p>

        <p className="control">
          <button
            className="button is-primary"
            onClick={addPortfolio}
            disabled={submitting}
          >
            {t("add")}
          </button>
        </p>
      </div>
    </div>
  )
}

export default PortfolioStudentNew
