import { useTranslation } from "react-i18next"
import RegionList from "../../data/thesaurus/RegionList"
import DataModelContextProvider from "../../hooks/DataModelContext"
import PageTitle from "../../util/PageTitle"

const RegionView = () => {
  const { t } = useTranslation()

  return (
    <DataModelContextProvider dataModelName="region" url="/thesaurus/region">
      <PageTitle>{t("thesaurus.regions")}</PageTitle>

      <RegionList />
    </DataModelContextProvider>
  )
}

export default RegionView
