import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { useDataModelContext } from "../../hooks/DataModelContext"
import useGetData from "../../hooks/useGetData"
import useListData from "../../hooks/useListData"
import { useUserContext } from "../../hooks/UserContext"
import { nilObjectID } from "../../util"
import PracticeDecision from "./PracticeDecision"
import PracticeStatus from "./PracticeStatus"

const makeShortName = fullName => {
  const splittedName = (fullName || "").split(" ")
  const surname = splittedName?.length > 0 ? splittedName[0] : ""
  const firstName =
    splittedName?.length > 1 ? ` ${splittedName[1].charAt(0)}.` : ""
  const middleName =
    splittedName?.length > 2 ? `${splittedName[2].charAt(0)}.` : ""

  return surname + firstName + middleName
}

const PracticePreview = ({ practice, infoMode = false }) => {
  const { t } = useTranslation()
  const {
    user: { userType },
  } = useUserContext()
  const { refetch } = useDataModelContext()
  const { user: student, loading: stLoading } = useGetData("user", "/user", {
    userID: practice?.studentID,
  })
  const { usersMap } = useListData("users", "/user", {
    userType: "prodSenior",
  })
  const { eduOrgsMap } = useListData("eduOrgs", "/thesaurus/eduOrg")
  const { workplacesMap } = useListData("workplaces", "/thesaurus/workplace")
  const { specialitiesMap } = useListData(
    "specialities",
    "/thesaurus/speciality"
  )

  const title = (
    <>
      {t("practice.practiceFor")}&nbsp;
      {makeShortName(student?.fullName)}&nbsp;
      {t("from")}&nbsp;
      {dayjs(practice?.creationDate).format("DD.MM.YYYY")}
    </>
  )

  return (
    <div className="box">
      {infoMode || (
        <>
          <div className="columns mb-0">
            <div className="column is-8">
              <span className="title is-5">
                {!["awaiting", "declined"].includes(practice?.status) ? (
                  <Link to={`/r/practice/${practice?.id}`}>{title}</Link>
                ) : (
                  <>{title}</>
                )}
              </span>
            </div>

            <div className="column is-4 has-text-right">
              <PracticeStatus status={practice?.status} />
            </div>
          </div>

          <hr className="my-1" />
        </>
      )}

      {practice?.status !== "declined" || (
        <div className="message is-danger">
          <div className="message-body">
            <p>
              <strong>{t("practice.declineReason")}:&nbsp;</strong>
              {practice?.declineReason}
            </p>
          </div>
        </div>
      )}

      {!["partFit", "nonFit"].includes(practice?.medStatus) || (
        <div
          className={
            "message " +
            (practice?.medStatus === "partFit" ? "is-warning" : "is-danger")
          }
        >
          <div className="message-body">
            <p>
              <strong>{t(`practice.medStatus`)}:&nbsp;</strong>
              {t(`practice.medStatuses.${practice?.medStatus}`)}
            </p>

            <p>
              <strong>{t(`practice.medReason`)}:&nbsp;</strong>
              {practice?.medReason}
            </p>
          </div>
        </div>
      )}

      <div className="columns">
        <div className="column is-half">
          <div className="field">
            <div className="control">
              <label className="label">{t("practice.studentName")}</label>
              {!stLoading ? student?.fullName : t("loading")}
            </div>
          </div>

          <div className="field">
            <div className="control">
              <label className="label">Email</label>
              {!stLoading ? student?.email : t("loading")}
            </div>
          </div>

          <div className="field">
            <div className="control">
              <label className="label">{t("auth.phone")}</label>
              {!stLoading ? "+7 " + student?.extraData?.phone : t("loading")}
            </div>
          </div>

          <div className="field">
            <div className="control">
              <label className="label">{t("auth.eduOrg")}</label>
              {student?.extraData?.eduOrgID !== nilObjectID
                ? eduOrgsMap[student?.extraData?.eduOrgID]?.title
                : student?.extraData?.otherEduText}
            </div>
          </div>

          <div className="field">
            <div className="control">
              <label className="label">{t("auth.speciality")}</label>
              {student?.extraData?.specialityID !== nilObjectID
                ? specialitiesMap[student?.extraData?.specialityID]?.title
                : student?.extraData?.otherSpecialityText}
            </div>
          </div>

          <div className="field">
            <div className="control">
              <label className="label">{t("auth.yearOfStudy")}</label>
              {t(
                `auth.yos.${student?.extraData?.yearOfStudy?.split(":::")[1]}`
              )}
            </div>
          </div>

          {!practice?.payType?.length || (
            <div className="field">
              <div className="control">
                <label className="label">{t("practice.payType")}</label>
                {t(`practice.payTypes.${practice?.payType}`)}
                {!(practice?.payType === "paid" && practice?.paySize > 0) ||
                  ` (оклад ${practice?.paySize} руб)`}
                {!(practice?.payType === "paid" && practice?.paySizeHour > 0) ||
                  ` (почасовая ставка ${practice?.paySizeHour} руб)`}
              </div>
            </div>
          )}
        </div>

        <div className="column is-half">
          <div className="field">
            <div className="control">
              <label className="label">{t("practice.workplace")}</label>
              {workplacesMap[practice?.workplaceID]?.title || t("loading")}
            </div>
          </div>

          <div className="field">
            <div className="control">
              <label className="label">{t("portfolio.reasonPreview")}</label>
              {t(`portfolio.${practice?.reason}`)}
            </div>
          </div>

          <div className="field">
            <div className="control">
              <label className="label">{t("practice.leader")}</label>
              {usersMap[practice?.leaderID]?.fullName || t("loading")}
            </div>
          </div>

          <div className="field">
            <div className="control">
              <label className="label">{t("practice.guild")}</label>
              {practice?.guild}
            </div>
          </div>

          <div className="field">
            <div className="control">
              <label className="label">{t("practice.startDate")}</label>
              {dayjs(practice?.startDate).format("DD/MM/YYYY")}
            </div>
          </div>

          <div className="field">
            <div className="control">
              <label className="label">{t("practice.endDate")}</label>
              {dayjs(practice?.endDate).format("DD/MM/YYYY")}
            </div>
          </div>
        </div>
      </div>

      {!(userType === "prodSenior" && practice?.status === "awaiting") || (
        <PracticeDecision practiceID={practice?.id} refetch={refetch} />
      )}

      {!infoMode || (
        <>
          <hr />

          <p className="has-text-centered">
            <PracticeStatus status={practice?.status} />
          </p>
        </>
      )}
    </div>
  )
}

export default PracticePreview
