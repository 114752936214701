import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faCode,
  faCheck,
  faInfoCircle,
  faPen,
  faDotCircle,
  faUserPlus,
  faSignInAlt,
  faPlus,
  faUpload,
  faUserCircle,
  faSignOutAlt,
  faFileExcel,
} from "@fortawesome/free-solid-svg-icons"

library.add(
  faCode,
  faCheck,
  faInfoCircle,
  faPen,
  faDotCircle,
  faUserPlus,
  faSignInAlt,
  faPlus,
  faUpload,
  faUserCircle,
  faSignOutAlt,
  faFileExcel
)
