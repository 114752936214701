import axios from "axios"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { errorMessage } from "../../errors"
import { useUserContext } from "../../hooks/UserContext"
import { API_URL, serverError } from "../../util"

const SignInForm = ({ userType }) => {
  const { t } = useTranslation()
  const { login } = useUserContext()

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const signIn = async event => {
    event.preventDefault()

    try {
      const data = { email, password, userType }

      const resp = await axios.post(`${API_URL}/user/auth`, data)
      const { status, message, token } = resp.data

      if (status === "failed") return toast.error(errorMessage(message))

      login(token)
    } catch (error) {
      toast.error(serverError())
    }
  }

  return (
    <form>
      <div className="field">
        <label className="label">Email</label>
        <div className="control">
          <input
            className="input"
            type="text"
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="ivanov@mail.com"
          />
        </div>
      </div>

      <div className="field">
        <label className="label">{t("auth.password")}</label>
        <div className="control">
          <input
            className="input"
            type="password"
            placeholder="*******"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </div>
      </div>

      <div className="field mt-5">
        <p className="control">
          <button className="button is-primary is-fullwidth" onClick={signIn}>
            {t("auth.signIn")}
          </button>
        </p>
      </div>
    </form>
  )
}

export default SignInForm
