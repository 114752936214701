import axios from "axios"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { errorMessage } from "../errors"
import { API_URL, serverError } from "../util"

const useRegions = () => {
  const [regions, setRegions] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState("")
  const [needRefetch, setNeedRefetch] = useState(true)

  useEffect(() => {
    const fetchRegions = async () => {
      setLoading(true)

      try {
        const resp = await axios.get(`${API_URL}/thesaurus/region/list`)
        const { status, message, regions } = resp.data

        setLoading(false)
        if (status === "failed") return setError(errorMessage(message))

        setRegions(regions)
      } catch (error) {
        setLoading(false)
        toast.error(serverError())
      }
    }

    if (needRefetch) {
      fetchRegions()
      setNeedRefetch(false)
    }
  }, [needRefetch])

  const refetch = () => setNeedRefetch(true)

  return { regions, loading, error, refetch }
}

export default useRegions
