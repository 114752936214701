import axios from "axios"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Select from "react-select"
import { toast } from "react-toastify"
import { errorMessage } from "../../errors"
import { useCommonDataContext } from "../../hooks/CommonDataContext"
import { usePlanContext } from "../../hooks/PlanContext"
import { API_URL, monthOptions, serverError, yearOptions } from "../../util"

const NeedNew = ({ editMode = false, need = {}, setEditMode }) => {
  const { t } = useTranslation()
  const { currentPlan } = usePlanContext()
  const { specialities, workplaces, regions } = useCommonDataContext()

  const [submitting, setSubmitting] = useState(false)

  const [selectedSpeciality, setSelectedSpeciality] = useState(null)
  const [selectedWorkplace, setSelectedWorkplace] = useState(null)
  const [selectedRegion, setSelectedRegion] = useState("")
  const [selectedMonth, setSelectedMonth] = useState(null)
  const [selectedYear, setSelectedYear] = useState(null)
  const [numberOfEngaged, setNumberOfEngaged] = useState(1)
  const [competence, setCompetence] = useState("")
  const [healthRestrictions, setHealthRestrictions] = useState("")

  const specialitiesOptions = specialities
    .filter(spec => spec.isApproved)
    .map(spec => ({
      value: spec.id,
      label: spec.title,
    }))

  const workplacesOptions = workplaces
    .filter(wp => wp.isApproved)
    .map(wp => ({
      value: wp.id,
      label: wp.title,
    }))

  useEffect(
    () => {
      if (editMode) {
        setSelectedSpeciality(
          specialitiesOptions.find(op => op.value === need.specialityID)
        )
        setSelectedWorkplace(
          workplacesOptions.find(op => op.value === need.workplaceID)
        )
        setSelectedMonth(
          monthOptions(t).find(op => op.value === need.monthOfEmployment)
        )
        setSelectedYear(
          yearOptions(t).find(op => op.value === need.yearOfEmployment)
        )
        setNumberOfEngaged(need.numberOfEngaged)
        setCompetence(need.competence)
        setHealthRestrictions(need.healthRestrictions)
      }
    },
    // eslint-disable-next-line
    []
  )

  useEffect(() => {
    if (selectedWorkplace) {
      const wp = workplaces.find(wp => wp.id === selectedWorkplace.value)
      setSelectedRegion(regions.find(reg => reg.code === wp?.regionCode).title)
    } else {
      setSelectedRegion("")
    }
  }, [selectedWorkplace, workplaces, regions])

  const resetForm = () => {
    setSelectedSpeciality(null)
    setSelectedWorkplace(null)
    setSelectedYear(null)
    setSelectedMonth(null)
    setNumberOfEngaged(1)
    setCompetence("")
    setHealthRestrictions("")
  }

  const addNeed = async () => {
    setSubmitting(true)

    try {
      const data = {
        planID: currentPlan.id,
        specialityID: selectedSpeciality?.value,
        workplaceID: selectedWorkplace?.value,
        monthOfEmployment: selectedMonth?.value,
        yearOfEmployment: selectedYear?.value,
        numberOfEngaged: Number(numberOfEngaged),
        competence,
        healthRestrictions,
      }
      const resp = await axios.post(`${API_URL}/need/add`, data)
      setSubmitting(false)

      const { status, message } = resp.data
      if (status === "failed") return toast.error(errorMessage(message))

      resetForm()
      toast.success(t("need.addSuccess"))
    } catch (error) {
      setSubmitting(false)
      toast.error(serverError())
    }
  }

  const updateNeed = async () => {
    setSubmitting(true)

    try {
      const data = {
        id: need.id,
        authorID: need.authorID,
        planID: need.planID,
        specialityID: selectedSpeciality?.value,
        workplaceID: selectedWorkplace?.value,
        monthOfEmployment: selectedMonth?.value,
        yearOfEmployment: selectedYear?.value,
        numberOfEngaged: Number(numberOfEngaged),
        competence,
        healthRestrictions,
        creationDate: need.creationDate,
      }
      const resp = await axios.post(`${API_URL}/need/update`, data)
      setSubmitting(false)

      const { status, message } = resp.data
      if (status === "failed") return toast.error(errorMessage(message))

      window.location.reload()
    } catch (error) {
      setSubmitting(false)
      toast.error(serverError())
    }
  }

  return (
    <div className="box">
      <p className="title is-5">
        {editMode ? t("need.edit") + " " + need?.id : t("need.addNew")}
      </p>

      <hr />

      <div className="columns">
        <div className="column is-half">
          <div className="field">
            <label className="label">{t("need.currentPlan")}</label>
            <input
              className="input"
              type="text"
              disabled
              value={currentPlan?.title}
            />
          </div>

          <div className="field">
            <label className="label">{t("need.workplace")}</label>
            <Select
              placeholder={t("need.workplacePlaceholder")}
              options={workplacesOptions}
              value={selectedWorkplace}
              onChange={setSelectedWorkplace}
              isClearable
              isDisabled={editMode}
            />
          </div>

          <div className="field">
            <label className="label">{t("need.region")}</label>
            <input
              className="input"
              disabled
              placeholder={t("need.workplacePlaceholder")}
              value={selectedRegion}
            />
          </div>
        </div>

        <div className="column is-half">
          <div className="field">
            <label className="label">{t("need.speciality")}</label>
            <Select
              placeholder={t("need.specialityPlaceholder")}
              options={specialitiesOptions}
              value={selectedSpeciality}
              onChange={setSelectedSpeciality}
              isClearable
              isDisabled={editMode}
            />
          </div>

          <div className="field">
            <label className="label">{t("need.numberOfEngaged")}</label>
            <input
              className="input"
              type="number"
              value={numberOfEngaged}
              onChange={e => setNumberOfEngaged(e.target.value)}
            />
          </div>

          <div className="field">
            <label className="label">{t("need.yearOfEmployment")}</label>
            <div className="columns">
              <div className="column is-half">
                <Select
                  placeholder={t("need.monthPlaceholder")}
                  options={monthOptions(t)}
                  value={selectedMonth}
                  onChange={setSelectedMonth}
                  isClearable
                />
              </div>

              <div className="column is-half">
                <Select
                  placeholder={t("need.yearPlaceholder")}
                  options={yearOptions()}
                  value={selectedYear}
                  onChange={setSelectedYear}
                  isClearable
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="field">
        <label className="label">{t("need.competence")}</label>
        <textarea
          className="textarea"
          placeholder={t("need.competencePlaceholder")}
          value={competence}
          onChange={e => setCompetence(e.target.value)}
        />
      </div>

      <div className="field">
        <label className="label">{t("need.health")}</label>
        <textarea
          className="textarea"
          placeholder={t("need.healthPlaceholder")}
          value={healthRestrictions}
          onChange={e => setHealthRestrictions(e.target.value)}
        />
      </div>

      {editMode ? (
        <div className="field is-grouped is-grouped-right">
          <div className="control">
            <button
              className="button is-link is-outlined px-5"
              onClick={() => setEditMode(false)}
            >
              {t("cancel")}
            </button>
          </div>

          <div className="control">
            <button
              className="button is-link px-5 ml-3"
              onClick={updateNeed}
              disabled={submitting}
            >
              {t("edit")}
            </button>
          </div>
        </div>
      ) : (
        <div className="field is-grouped is-grouped-right">
          <div className="control">
            <button
              className="button is-link is-outlined px-5"
              onClick={resetForm}
            >
              {t("reset")}
            </button>
          </div>

          <div className="control">
            <button
              className="button is-link px-5 ml-3"
              onClick={addNeed}
              disabled={submitting}
            >
              {t("add")}
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default NeedNew
