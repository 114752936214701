import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import useListData from "../../hooks/useListData"
import useUsers from "../../hooks/useUsers"
import { nilObjectID } from "../../util"
import EmptyList from "../../util/EmptyList"
import LoadingSpinner from "../../util/LoadingSpinner"
import StudentInfoExport from "./StudentInfoExport"

const UserList = ({ userType }) => {
  const { t } = useTranslation()
  const { users, loading } = useUsers(userType)
  const { eduOrgsMap } = useListData("eduOrgs", "/thesaurus/eduOrg")
  const { specialitiesMap } = useListData(
    "specialities",
    "/thesaurus/speciality"
  )

  if (loading) return <LoadingSpinner />

  if (users.length === 0) return <EmptyList />

  return (
    <>
      <div className="box">
        <div className="columns">
          <div className="column is-half">
            <p className="is-size-5">
              {" "}
              <strong>{t("total")}:&nbsp;</strong>
              {users?.length}
            </p>
          </div>

          <div className="column is-half has-text-right">
            {userType !== "student" || <StudentInfoExport />}
          </div>
        </div>
      </div>

      {users.map(user => (
        <div className="box" key={user.id}>
          <p className="title is-5">
            {user.fullName}
            <span className="is-pulled-right has-text-grey is-size-7">
              {user.id}
            </span>
          </p>

          <hr />

          <p>
            <strong>Email: </strong>
            <a href={"mailto:" + user.email}>{user.email}</a>
          </p>

          {!user?.extraData?.phone || (
            <p>
              <strong>{t("auth.phone")}: </strong>
              +7&nbsp;{user?.extraData?.phone}
            </p>
          )}

          {user.userType !== "student" || (
            <p>
              <strong>{t("auth.eduOrg")}: </strong>
              {user?.extraData?.eduOrgID
                ? eduOrgsMap[user?.extraData?.eduOrgID]?.title
                : user?.extraData?.otherEduText}
            </p>
          )}

          {user.userType !== "student" || (
            <p>
              <strong>{t("auth.speciality")}: </strong>
              {user?.extraData?.specialityID !== nilObjectID
                ? specialitiesMap[user?.extraData?.specialityID]?.title
                : user?.extraData?.otherSpecialityText}
            </p>
          )}

          {user.userType !== "student" || (
            <p>
              <strong>{t("auth.yearOfStudy")}: </strong>
              {t(
                "auth.yos." +
                  (user?.extraData?.yearOfStudy || "0000:::udf")?.split(
                    ":::"
                  )[1]
              )}
            </p>
          )}

          {!user.department || (
            <p>
              <strong>{t("auth.department")}: </strong>
              {user.department}
            </p>
          )}

          {!user.position || (
            <p>
              <strong>{t("auth.position")}: </strong>
              {user.position}
            </p>
          )}

          <p>
            <strong>{t("auth.signUpDate")}: </strong>
            {dayjs(user?.creationDate).format("DD/MM/YYYY HH:mm:ss")}
          </p>
        </div>
      ))}
    </>
  )
}

export default UserList
