import { useTranslation } from "react-i18next"
import { useDataModelContext } from "../../hooks/DataModelContext"
import useListData from "../../hooks/useListData"
import { useUserContext } from "../../hooks/UserContext"
import LoadingSpinner from "../../util/LoadingSpinner"
import EduSpecNew from "./EduSpecNew"
import EduSpecPreview from "./EduSpecPreview"

const EduSpecList = ({ eduInfoID }) => {
  const { t } = useTranslation()
  const {
    user: { userType },
  } = useUserContext()
  const { specialities } = useListData("specialities", "/thesaurus/speciality")
  const { eduSpecs, loading } = useDataModelContext()

  if (loading) return <LoadingSpinner />

  const specialitiesMap = (specialities || []).reduce(
    (acc, el) => ({
      ...acc,
      [el?.code]: el,
    }),
    {}
  )

  return (
    <>
      <div className="box">
        <p className="title is-5">{t("eduInfo.specs")}</p>
      </div>

      {!userType.includes("edu") || <EduSpecNew eduInfoID={eduInfoID} />}

      {eduSpecs?.length ? (
        <>
          {eduSpecs.map(es => (
            <EduSpecPreview
              key={es?.id}
              es={es}
              specialitiesMap={specialitiesMap}
            />
          ))}
        </>
      ) : (
        <div className="box">
          <span className="has-text-grey">{t("emptyList")}</span>
        </div>
      )}
    </>
  )
}

export default EduSpecList
