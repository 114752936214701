import { useTranslation } from "react-i18next"
import useListData from "../../hooks/useListData"
import { useUserContext } from "../../hooks/UserContext"
import LoadingSpinner from "../../util/LoadingSpinner"
import EduProgramNew from "./EduProgramNew"

const EduProgramView = () => {
  const { t } = useTranslation()
  const {
    user: { userType },
  } = useUserContext()
  const { eduPrograms, refetch, loading } = useListData(
    "eduPrograms",
    "/eduProgram"
  )

  if (loading) return <LoadingSpinner />

  return (
    <>
      {userType !== "eduCorpUni" || <EduProgramNew refetch={refetch} />}

      <div className="box">
        <div className="table-container">
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th>{t("eduProgram.title")}</th>
                <th>{t("eduProgram.totalHours")}</th>
                <th>
                  <abbr title={t("eduProgram.theoryHours")}>ЧТ</abbr>
                </th>
                <th>
                  <abbr title={t("eduProgram.practiceHours")}>ЧП</abbr>
                </th>
                <th>
                  <abbr title={t("eduProgram.generalHours")}>ЧОП</abbr>
                </th>
                <th>
                  <abbr title={t("eduProgram.techHours")}>ЧТП</abbr>
                </th>
                <th>
                  <abbr title={t("eduProgram.examHours")}>ЧЭБ</abbr>
                </th>
              </tr>
            </thead>

            <tbody>
              {(eduPrograms || []).map(prog => (
                <tr key={prog?.id}>
                  <th>{prog?.title}</th>
                  <td>{prog?.theoryHours + prog?.practiceHours}</td>
                  <td>{prog?.theoryHours}</td>
                  <td>{prog?.practiceHours}</td>
                  <td>{prog?.generalHours}</td>
                  <td>{prog?.techHours}</td>
                  <td>{prog?.examHours}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default EduProgramView
