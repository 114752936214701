import { useTranslation } from "react-i18next"
import BranchList from "../../data/thesaurus/BranchList"
import BranchNew from "../../data/thesaurus/BranchNew"
import DataModelContextProvider from "../../hooks/DataModelContext"
import { useUserContext } from "../../hooks/UserContext"
import PageTitle from "../../util/PageTitle"

const BranchView = () => {
  const { t } = useTranslation()
  const {
    user: { userType },
  } = useUserContext()

  return (
    <DataModelContextProvider dataModelName="branch" url="/thesaurus/branch">
      <PageTitle>{t("thesaurus.branchs")}</PageTitle>

      {userType !== "admin" || <BranchNew />}

      <BranchList />
    </DataModelContextProvider>
  )
}

export default BranchView
