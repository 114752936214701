import axios from "axios"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { errorMessage } from "../errors"
import { API_URL, serverError } from "../util"

const useInvites = () => {
  const [invites, setInvites] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState("")
  const [needRefetch, setNeedRefetch] = useState(true)

  useEffect(() => {
    const fetchInvites = async () => {
      setLoading(true)

      try {
        const resp = await axios.get(`${API_URL}/user/invite/list`)
        const { status, message, invites } = resp.data

        setLoading(false)
        if (status === "failed") return setError(errorMessage(message))

        setInvites(invites)
      } catch (error) {
        setLoading(false)
        toast.error(serverError())
      }
    }

    if (needRefetch) {
      fetchInvites()
      setNeedRefetch(false)
    }
  }, [needRefetch])

  const refetch = () => setNeedRefetch(true)

  return { invites, loading, error, refetch }
}

export default useInvites
