import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import { toast } from "react-toastify"
import { API_URL, serverError } from "../../util"
import axios from "axios"
import { errorMessage } from "../../errors"
import { useDataModelContext } from "../../hooks/DataModelContext"

const PracticeTaskNew = () => {
  const { t } = useTranslation()
  const { refetch } = useDataModelContext()
  const { practiceID } = useParams()
  const [mode, setMode] = useState("view")
  const [taskType, setTaskType] = useState("general")
  const [title, setTitle] = useState("")
  const [text, setText] = useState(null)
  const [deadline, setDeadline] = useState("")

  const [sub, setSub] = useState(false)

  const resetForm = () => {
    setText(null)
    setDeadline("")
    setMode("view")
  }

  const addPracticeTask = async () => {
    const parsedDeadline = new Date(deadline)
    if (
      // eslint-disable-next-line eqeqeq
      parsedDeadline == "Invalid Date" ||
      isNaN(parsedDeadline)
    )
      return toast.error(errorMessage("practice:::009"))

    setSub(true)
    try {
      const data = {
        practiceID,
        title,
        text,
        deadline: parsedDeadline,
        taskType,
      }
      const resp = await axios.post(`${API_URL}/practiceTask/add`, data)
      const { status, message } = resp.data
      setSub(false)

      if (status === "failed") return toast.error(errorMessage(message))

      refetch()
      resetForm()
    } catch (err) {
      setSub(false)
      toast.error(serverError())
    }
  }

  if (mode === "view")
    return (
      <div className="box has-text-centered">
        <span
          className="is-size-5 pointer has-text-link"
          onClick={() => setMode("add")}
        >
          <FontAwesomeIcon icon="plus" />
          &nbsp;{t("practice.addTask")}
        </span>
      </div>
    )

  return (
    <div className="box">
      <p className="title is-5">{t("practice.addTask")}</p>

      <hr />

      <div className="field">
        <div className="control">
          <label className="label">{t("practice.taskTitle")}</label>
          <input
            type="text"
            className="input"
            placeholder={t("practice.taskTitlePlh")}
            value={title}
            onChange={e => setTitle(e.target.value)}
          />
        </div>
      </div>

      <label className="label">{t("practice.taskType")}</label>
      <div className="field has-addons">
        <div className="control">
          <button
            className={
              "button " + (taskType === "general" ? "is-link" : "is-light")
            }
            onClick={() => setTaskType("general")}
          >
            {t("practice.taskTypes.general")}
          </button>
        </div>
        <div className="control">
          <button
            className={
              "button " + (taskType === "theory" ? "is-link" : "is-light")
            }
            onClick={() => setTaskType("theory")}
          >
            {t("practice.taskTypes.theory")}
          </button>
        </div>
        <div className="control">
          <button
            className={
              "button " + (taskType === "practice" ? "is-link" : "is-light")
            }
            onClick={() => setTaskType("practice")}
          >
            {t("practice.taskTypes.practice")}
          </button>
        </div>
      </div>

      <div className="field">
        <div className="control">
          <label className="label">{t("practice.taskText")}</label>

          <ReactQuill
            value={text}
            onChange={setText}
            placeholder={t("practice.taskTextPlh")}
            theme="snow"
          />
        </div>
      </div>

      <div className="field">
        <div className="control">
          <label className="label">{t("practice.deadline")}</label>

          <input
            type="date"
            className="input"
            value={deadline}
            onChange={e => setDeadline(e.target.value)}
          />
        </div>
      </div>

      <div className="field is-grouped is-grouped-right">
        <div className="control">
          <button
            className="button is-small is-light"
            onClick={() => setMode("view")}
            disabled={sub}
          >
            {t("cancel")}
          </button>
        </div>
        <div className="control">
          <button
            className="button is-small is-link"
            onClick={addPracticeTask}
            disabled={sub}
          >
            {t("add")}
          </button>
        </div>
      </div>
    </div>
  )
}

export default PracticeTaskNew
