import { useState } from "react"
import { useTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const TaskComputations = ({ computations }) => {
  const { t } = useTranslation()
  const [showFull, setShowFull] = useState(false)

  if (computations.length === 0) return <EmptyComputation t={t} />

  const currentComputation = computations[computations.length - 1]

  const shortInfo = (
    <div className="box has-background-black has-text-white">
      <nav className="level">
        <div className="level-item has-text-centered">
          <div>
            <p className="heading">{t("task.yearOfSupervision")}</p>
            <p className="title has-text-white">
              {currentComputation?.yearOfSupervision}
            </p>
          </div>
        </div>

        <div className="level-item has-text-centered">
          <div>
            <p className="heading">{t("task.numberOfEngaged")}</p>
            <p className="title has-text-white">
              {currentComputation?.numberOfEngaged}
            </p>
          </div>
        </div>

        <div className="level-item has-text-centered">
          <div>
            <p className="heading">{t("task.modelVersion")}</p>
            <p className="title has-text-white">
              V{currentComputation?.modelVersion}
            </p>
          </div>
        </div>
      </nav>

      {computations?.length <= 1 || (
        <>
          <hr className="my-1" />

          <p className="has-text-centered">
            <span
              className="pointer is-size-7"
              onClick={() => setShowFull(true)}
            >
              {t("showMore")}
            </span>
          </p>
        </>
      )}
    </div>
  )

  const fullInfo = (
    <div className="box has-background-black has-text-white">
      <div className="table-container">
        <table className="table is-fullwidth has-background-black has-text-white">
          <thead>
            <tr>
              <th></th>
              <th className="has-text-white">{t("task.yearOfSupervision")}</th>
              <th className="has-text-white">{t("task.numberOfEngaged")}</th>
              <th className="has-text-white">{t("task.modelVersion")}</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {computations.map(comp => (
              <tr key={comp}>
                <td>
                  {comp === computations[computations.length - 1] ? (
                    <FontAwesomeIcon icon="dot-circle" />
                  ) : null}
                </td>
                <td>{comp.yearOfSupervision}</td>
                <td>{comp.numberOfEngaged}</td>
                <td>V{comp.modelVersion}</td>
                <td>{t(`reason.${comp.reason}`)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <p className="has-text-centered">
        <span className="pointer is-size-7" onClick={() => setShowFull(false)}>
          {t("showLess")}
        </span>
      </p>
    </div>
  )

  return showFull ? fullInfo : shortInfo
}

const EmptyComputation = ({ t }) => (
  <div className="box has-text-centered has-text-white has-background-black">
    {t("task.emptyComputationsText")}
  </div>
)

export default TaskComputations
