import { useTranslation } from "react-i18next"
import WorkplaceNew from "../../data/thesaurus/WorkplaceNew"
import PageTitle from "../../util/PageTitle"
import { useUserContext } from "../../hooks/UserContext"
import WorkplaceList from "../../data/thesaurus/WorkplaceList"
import DataModelContextProvider from "../../hooks/DataModelContext"

const Workplace = () => {
  const { t } = useTranslation()
  const {
    user: { userType },
  } = useUserContext()

  return (
    <DataModelContextProvider
      dataModelName="workplace"
      url="/thesaurus/workplace"
    >
      <PageTitle>{t("thesaurus.workplaces")}</PageTitle>

      {userType !== "admin" || <WorkplaceNew />}

      <WorkplaceList />
    </DataModelContextProvider>
  )
}

export default Workplace
