import axios from "axios"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { errorMessage } from "../errors"
import { API_URL, serverError } from "../util"

const useTasks = currentPage => {
  const [tasks, setTasks] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState("")
  const [needRefetch, setNeedRefetch] = useState(true)
  const [pageCount, setPageCount] = useState(0)

  useEffect(() => {
    const fetchTasks = async () => {
      setLoading(true)

      try {
        const resp = await axios.get(`${API_URL}/task/list?page=${currentPage}`)
        const { status, message, tasks, pageCount } = resp.data

        setLoading(false)
        if (status === "failed") return setError(errorMessage(message))

        setTasks(tasks)
        setPageCount(pageCount)
      } catch (error) {
        setLoading(false)
        toast.error(serverError())
      }
    }

    fetchTasks()
    setNeedRefetch(false)
  }, [needRefetch, currentPage])

  const refetch = () => setNeedRefetch(true)

  return { tasks, loading, error, refetch, pageCount }
}

export default useTasks
